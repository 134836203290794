import React, {ReactNode, useEffect, useState} from 'react'
import {UpgradeContextV2, useUpgradeContext} from './Upgrade-Context'
import {UpgradeAccountSwitcher} from './Upgrade-Account-Switcher'
import {UpgradeOrderSummary, UpgradeSummaryFooter} from './Upgrade-Order-Summary'
import {UpgradeTermsAndConditions} from './Upgrade-Terms-And-Conditions'
import BlockedUpgradeRoutes from './Blocked-Upgrade/Blocked-Upgrade-Routes'
import {UpgradeReviewConfirm, UpgradeReviewConfirmMobile} from './Upgrade-Review-Confirm'
import {ReactComponent as PluralSightHeader} from '../images/pluralsight-logo-horizontal-white-outline.svg'
import UpgradeDataLoader from './Upgrade-Data-Loader'
import {UpgradePayment} from './Upgrade-Payment'
import UpgradeThankYouPage from './Upgrade-Thank-You-Page'
import {ProcessTransactionDecorator} from '../common/Transaction/process-transaction-decorator'
import styles from './Upgrade.module.scss'
import {BackToPlanLink} from '../Plan-Link/BackToPlanLink'
import GenericErrorPage from '../Renew-V2/generic-error-page/generic-error-page'

const mobileWidth = 769

export default function UpgradeV2() {
    return (
        <UpgradeContextV2>
            <UpgradeProcessing />
        </UpgradeContextV2>
    )
}

function UpgradeStandardLayout() {
    let {state} = useUpgradeContext()
    return (
        <div className={styles.upgradeContainer}>
            <div className={styles.backToPlanMessage}>
                <BackToPlanLink planId={state.planId} />
            </div>
            <div className={styles.upgrade}>
                <main className={styles.mainLayout}>
                    <UpgradeMain />
                </main>
                <aside className={styles.aside}>
                    <div className={styles.accountSwitcher}>
                        <UpgradeAccountSwitcher />
                    </div>
                    <UpgradeOrderSummary />
                    <UpgradeTermsAndConditions lowOnDark={true} />
                </aside>
            </div>
        </div>
    )
}

function UpgradeMobileLayout() {
    let {state} = useUpgradeContext()
    return (
        <div>
            <div>
                <BackToPlanLink planId={state.planId} />
            </div>
            <main>
                <div>
                    <UpgradeAccountSwitcher />
                </div>
                <div>
                    <UpgradeMainMobile />
                </div>
            </main>
            <UpgradeSummaryFooter />
        </div>
    )
}

function UpgradeMainLayout(props: {reviewConfirm: ReactNode}) {
    return (
        <div>
            <h2 className={styles.header}>Upgrade</h2>
            <div className={styles.rows}>
                <UpgradePayment />
                {props.reviewConfirm}
            </div>
        </div>
    )
}

function UpgradeMain() {
    return <UpgradeMainLayout reviewConfirm={<UpgradeReviewConfirm />} />
}

function UpgradeMainMobile() {
    return <UpgradeMainLayout reviewConfirm={<UpgradeReviewConfirmMobile />} />
}

function UpgradeStandardorMobile() {
    const [mobileLayout, setMobileLayout] = useState(false)
    useEffect(() => {
        if (window.innerWidth < mobileWidth) {
            setMobileLayout(true)
        }
    }, [])
    return (
        <UpgradeDataLoader>
            <div className={styles.pageContainer}>
                <PluralSightHeader />
                {mobileLayout ? <UpgradeMobileLayout /> : <UpgradeStandardLayout />}
            </div>
        </UpgradeDataLoader>
    )
}

function UpgradeProcessing() {
    let {state} = useUpgradeContext()
    if (state.hasError) {
        return (
            <BlockedUpgradeRoutes
                errorMessage={state.errorMessage}
                planId={state.planId}
                guardData={state.guardData}
            />
        )
    } else if (state.getActivityError) {
        return <GenericErrorPage />
    } else if (state.upgrade.pending) {
        return <ProcessTransactionDecorator />
    } else if (!state.upgrade.loaded) {
        return <UpgradeStandardorMobile />
    } else {
        return <UpgradeThankYouPage />
    }
}
