import React from 'react'
import styles from './number-icon.module.scss'

interface Props {
    active: boolean
    complete: boolean
    value: number
}

const NumberIcon = (props: Props) => {
    return (
        <div className={`${styles.icon} ${props.active ? styles.iconActive : ''}`}>
            <div className={`${styles.value} ${props.complete ? styles.completed : ''}`}>
                {props.value}
            </div>
            <div
                className={`${styles.checkmark}
             ${props.active ? styles.activeCheckmark : ''}
             ${props.complete ? styles.completeCheckmark : ''}
             `}
            />
        </div>
    )
}

export default NumberIcon
