import React from 'react'
import SavedPaymentMethods from './Saved-Payment-Methods/Saved-Payment-Methods'
import {Invoices} from '../Invoice/Invoice'
import InvoiceData from '../Invoice/Invoice-Data'
import styles from './Billing.module.scss'

export default function Billing() {
    return (
        <div className={styles.billingPage}>
            <InvoiceData>
                <Invoices />
            </InvoiceData>
            <SavedPaymentMethods />
        </div>
    )
}
