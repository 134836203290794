import * as React from 'react'
import GenericErrorPage from './generic-error-page/generic-error-page'
interface state {
    hasError: boolean
    error?: any
}

export default class RenewErrorBoundary extends React.Component<any, state> {
    constructor(props: any) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error: any) {
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            return <GenericErrorPage />
        }
        return this.props.children
    }
}
