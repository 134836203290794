import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import Link from '@pluralsight/ps-design-system-link'
import {config} from '../environment-config'
import {Heading} from '@pluralsight/ps-design-system-text'
import styles from './B2b-static.module.scss'

export default function B2bStatic() {
    const businessSkills = `${config.www.url}/pricing/skills?type=business`
    const appLibrary = `${config.people.url}/library`
    return (
        <div className={styles.b2bStaticPageLayout}>
            <div className={styles.messageLayout}>
                <Heading size={Heading.sizes.small} color={Heading.colors.primary}>
                    <h2>
                        You are a member of a company plan. Please contact your plan administrator
                        to request an upgrade.
                    </h2>
                </Heading>
                <div>
                    <Link>
                        <a href={businessSkills} className={styles.textStyles}>
                            Learn more about plans and pricing
                        </a>
                    </Link>
                </div>
                <div>
                    <Button href={appLibrary}>
                        <span className={styles.textStyles}>Browse library</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}
