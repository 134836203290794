import {api} from '../../../../api/api'

export function createPaypalMethod(
    useMulesoftPaypalCreationFlag: boolean,
    planId: string,
    payPalBody: any
): Promise<any> {
    let url = `plans/${planId}/payment-methods/create-paypal-method`

    if (useMulesoftPaypalCreationFlag) {
        url = `payment-methods/create-paypal-method`
    }

    return new Promise((resolve: any, reject: any) => {
        api.post(url, payPalBody).then((res) => {
            if (res) {
                resolve(res)
            } else {
                reject({success: false})
            }
        })
    })
}
