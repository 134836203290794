import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {NotFoundErrorPage} from '@pluralsight/ps-design-system-errors'
import {AnalyticsPageView} from './Analytics-page-view'
import B2bRoutes from '../Router/B2B-Routes'
import {PlanAdminAuth} from '../Router/Plan-Admin-Auth/PlanAdminAuth'
import {SubscriptionProvisioningAuth} from '../Router/Subscription-Provisioning-Auth/SubscriptionProvisioningAuth'
import {CacheProvisioningAuth} from '../Router/Cache-Provisioning-Auth/CacheProvisioningAuth'
import {useAppContext} from '../useAppState'
import {Subscription} from '../Subscription/Subscription'
import {AdminPlanList} from '../Plan/Admin-Plan-List'
import AccountNavBar from '../Account-Nav-Bar/Account-Nav-Bar'
import {IndividualAdmin} from '../Individual-Admin/Individual-Admin'
import {SetDefaultPlan} from '../Router/Set-Default-Plan/SetDefaultPlan'
import CpqExpand from '../Cpq-Expand/Cpq-Expand'
import Prism from '../Prism/Prism'
import TeamSupport from '../Team-Support/team-support'
import B2bUpgradeLanding from '../Upgrade-Landing/B2b-Upgrade-Landing'
import ContactSalesForm from '../Contact-Sales-Form/Contact-Sales-Form'
import styles from '../main-content.module.scss'
import Profitwell from '../Profitwell/Profitwell'
import B2bRoutesV2 from '../Router/B2B-RoutesV2'

export function MainContent() {
    const {state} = useAppContext()
    return (
        <AnalyticsPageView>
            <Profitwell>
                <Prism>
                    <Switch>
                        <Route exact path='/subscription'>
                            <InterstitialLandingPage />
                        </Route>
                        <Route
                            exact
                            path='/subscription/plans/:planId/product/:productId/expand/cpq-expand'
                        >
                            <PlanAdminAuth>
                                <CpqExpand />
                            </PlanAdminAuth>
                        </Route>
                        <Route exact path='/subscription/plans/:planId/contact-sales/renew'>
                            <PlanAdminAuth>
                                <ContactSalesForm requestType={'renew'} />
                            </PlanAdminAuth>
                        </Route>
                        <Route path='/subscription/plans/:planId'>
                            <PlanAdminAuth>
                                {state.applicationData.featureConfig.useDocmationCheckout ? (
                                    <B2bRoutesV2 />
                                ) : (
                                    <B2bRoutes />
                                )}
                            </PlanAdminAuth>
                        </Route>
                        <Route path='/subscription/plans'>
                            <SetDefaultPlan />
                        </Route>
                        <Route path='/subscription/admin/individual/:handle'>
                            <SubscriptionProvisioningAuth>
                                <IndividualAdmin />
                            </SubscriptionProvisioningAuth>
                        </Route>
                        <Route path='/subscription/team'>
                            <CacheProvisioningAuth>
                                <TeamSupport />
                            </CacheProvisioningAuth>
                        </Route>
                        <Route path='/subscription/business/upgrade'>
                            <B2bUpgradeLanding />
                        </Route>
                        <Route path='*'>
                            <NotFoundErrorPage size={NotFoundErrorPage.sizes.large} />
                        </Route>
                    </Switch>
                </Prism>
            </Profitwell>
        </AnalyticsPageView>
    )
}

function InterstitialLandingPage() {
    const {state} = useAppContext()
    const showPlans = state.applicationData.planInfo.admin.length !== 0
    return (
        <div>
            <AccountNavBar />
            <div className={styles.appContent}>
                <div className={styles.sectionWrap}>
                    <Subscription />
                </div>
                {showPlans && <AdminPlanList />}
            </div>
        </div>
    )
}
