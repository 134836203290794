import React, {useEffect, useState} from 'react'
import PaymentBox from '../../common/Transaction/Payment-Box/payment-box'
import {useModifyState} from '../Modify-Context/modify-context'
import {ModifySetters} from '../modify-setters'
export default function ModifyPayment(props: {planId: string; currency: string}) {
    const {actions, state, setter} = useModifyState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!state.payment.paymentMethodsInitialized) {
            setLoading(true)
            actions.loadPaymentMethods(props.planId).then((data: any) => {
                setter({type: ModifySetters.loadPaymentMethods, data})

                setLoading(false)
            })
        }
    }, [
        actions,
        setter,
        props.planId,
        state.payment.paymentMethodsInitialized,
        state.preview.data.lineItems,
        state.preview.data.amount,
        state.payment?.savedPayments,
    ])
    function resetPayment() {
        setter({type: ModifySetters.resetPaymentMethods, data: null})
    }
    function handlePaymentMethodId(id: string) {
        actions.loadPaymentMethods(props.planId).then((data) => {
            setter({type: ModifySetters.loadPaymentMethods, data})
            setter({type: ModifySetters.setPaymentMethodId, data: id})
        })
    }
    function selectPayment(id: string) {
        setter({type: ModifySetters.setPaymentMethodId, data: id})

        if (state?.payment?.savedPayments.length > 0) {
            const taxRequest = {
                userCurrency: state.preview.data.currency,
                cartItems: [
                    {
                        sku: state?.preview.data.lineItems[0].productName,
                        quantity: state?.preview.data.lineItems[0].quantity, // should this be total?
                        subTotal: state.preview.data.amount,
                    },
                ],
                shipTo: {
                    streetAddress:
                        state?.payment?.savedPayments[0]?.details?.cardHolderInfo?.addressLine1,
                    city: state?.payment?.savedPayments[0]?.details?.cardHolderInfo?.city,
                    stateProvince: state?.payment?.savedPayments[0]?.details?.cardHolderInfo?.state,
                    country: state?.payment?.savedPayments[0].details?.cardHolderInfo.country,
                    postalCode: state?.payment?.savedPayments[0]?.details?.cardHolderInfo?.zipCode,
                },
            }
            actions.getTax(taxRequest).then((data) => {
                setter({type: ModifySetters.taxModify, data})
            })
        }
    }
    return (
        <PaymentBox
            handlePaymentMethodId={handlePaymentMethodId}
            resetPayment={resetPayment}
            paymentMethodSelected={!!state.payment.paymentMethodId}
            currency={props.currency}
            existingPayments={state.payment.savedPayments}
            selectPayment={selectPayment}
            planId={props.planId}
            loading={loading}
        />
    )
}
