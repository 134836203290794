import * as React from 'react'
import '../../common/Transaction/styles/transaction-order-summary.scss'
import {useState, useEffect} from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
import {useModifyState} from '../Modify-Context/modify-context'
import {AmountPicker} from '../../Amount-Picker/Amount-Picker'
import {ModifySetters} from '../modify-setters'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'
import {debounce} from '../../utils/debounce'
import SmallLoadingDecorator from '../../common/Small-Loading-Decorator/small-loading-decorator'
export default function ModifyOrderSummary() {
    const {state, actions, setter} = useModifyState()
    const [maxWidth] = useState(760)
    const [collapsed, setCollapsed] = useState(window.innerWidth < maxWidth ? true : false)
    const [mobileView, setMobileView] = useState(window.innerWidth < maxWidth ? true : false)
    const currency = state.preview.data.currency
    const lineItems = state.preview.data.lineItems
    const [updatingData, setUpdatingData] = useState(false)
    let {productOptionId} = useParams<ParamTypes>()
    const toggleColllapse = (e: any) => {
        setCollapsed(!collapsed)
    }
    useEffect(() => {
        function handleResize() {
            setMobileView(window.innerWidth < maxWidth ? true : false)
            setCollapsed(window.innerWidth < maxWidth ? true : false)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })
    useEffect(() => {
        if (updatingData) {
            let debounced = debounce(actions.modify, 3000)
            debounced(state.modifyGuard.planId, {
                subscriptionNumber: !!state.modifyGuard.subscriptionNumber
                    ? state.modifyGuard.subscriptionNumber
                    : '',
                preview: true,
                modifications: [
                    {
                        type: 'UPSELL',
                        productOptionId,
                        quantity: state.subscriptionsToAdd,
                    },
                ],
            }).then((data) => {
                setter({type: ModifySetters.initializeModify, data})
                setUpdatingData(false)
            })
        }
    }, [
        state.subscriptionsToAdd,
        actions,
        productOptionId,
        setter,
        state.modifyGuard.planId,
        state.modifyGuard.subscriptionNumber,
        updatingData,
    ])
    function increaseCount() {
        if (state.subscriptionsToAdd >= state.modifyGuard.maxQuantity) return
        setUpdatingData(true)
        setter({type: ModifySetters.updateSubscriptionCount, data: state.subscriptionsToAdd + 1})
    }
    function decreaseCount() {
        setUpdatingData(true)
        setter({type: ModifySetters.updateSubscriptionCount, data: state.subscriptionsToAdd - 1})
    }
    function setCount(quantity: number) {
        if (quantity > state.modifyGuard.maxQuantity) return
        setUpdatingData(true)
        quantity = quantity > 0 ? quantity : 1
        setter({type: ModifySetters.updateSubscriptionCount, data: quantity})
    }

    return (
        <div className='transaction-order-summary'>
            {mobileView ? (
                <h4 className='transaction-mobile-header' onClick={toggleColllapse}>
                    Order Summary <span className={`arrow-${collapsed ? 'up' : 'down'}`}></span>
                    <span className='amount'>
                        {formatPrice(state.preview.data.amount)} {currency}
                    </span>
                    <hr className={`${collapsed ? 'hide-line' : ''}`}></hr>
                </h4>
            ) : (
                <h4 className='transaction-header'>Order summary</h4>
            )}
            {!collapsed || !mobileView ? (
                <>
                    <div className='transaction-summary-header'>
                        <div>Add-on</div>
                        <div>Subscriptions</div>
                        <div style={{textAlign: 'right'}}>
                            Price<sup>✝</sup>
                        </div>
                    </div>
                    {lineItems.map((lineItem) => {
                        return (
                            <div
                                className='transaction-summary-body'
                                key={lineItem.productOptionId}
                            >
                                <div style={{marginTop: '4px'}}>{lineItem.productName}</div>
                                <div className='account-picker'>
                                    {
                                        <AmountPicker
                                            numberOfLicences={state.subscriptionsToAdd}
                                            increase={increaseCount}
                                            decrease={decreaseCount}
                                            setAmount={setCount}
                                        />
                                    }
                                </div>
                                <div style={{textAlign: 'right', marginTop: '4px'}}>
                                    {updatingData ? (
                                        <SmallLoadingDecorator />
                                    ) : (
                                        `${formatPrice(
                                            parseFloat(
                                                (lineItem.amount / lineItem.quantity).toFixed(2)
                                            )
                                        )} ${currency}`
                                    )}
                                </div>
                            </div>
                        )
                    })}
                    <hr />
                    <div className='transaction-subTotal'>
                        <div className='row spacer'>
                            <div className='label'>Subtotal</div>
                            <div className='values'>
                                {updatingData ? (
                                    <SmallLoadingDecorator />
                                ) : (
                                    `${formatPrice(
                                        parseFloat(
                                            (
                                                state.preview.data.amount -
                                                state.preview.data.taxAmount
                                            ).toFixed(2)
                                        )
                                    )} ${currency}`
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='label'>
                                Estimated Tax<sup> ✝✝</sup>
                            </div>
                            <div className='values'>
                                {updatingData ? (
                                    <SmallLoadingDecorator />
                                ) : (
                                    `${formatPrice(state.preview.data.taxAmount)} ${currency}`
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='transaction-total'>
                        <div className='amountLabel'>Total due today</div>
                        <div className='amount'>
                            {updatingData ? (
                                <SmallLoadingDecorator />
                            ) : (
                                `${formatPrice(state.preview.data.amount)} ${currency}`
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    )
}
