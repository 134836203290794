var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Axios from 'axios';
import { MessageType } from './enums/message-type.enum';
export default {
    validateProps: function (token, authAmount, iframeUrl, theme, handle, planId, cta, netNewEmail, preselectedPaymentOption) {
        if (!iframeUrl || (typeof iframeUrl !== 'string')) {
            throw new Error('Iframe url required');
        }
        if (!token || (typeof token !== 'string')) {
            throw new Error('Token required');
        }
        if (typeof authAmount === 'undefined' || authAmount === null) {
            throw new Error('Authorization amount required');
        }
        if (typeof authAmount !== 'number') {
            throw new Error('Authorization amount must be a number');
        }
        if (authAmount < 0) {
            throw new Error(('Authorization amount cannot be a negative amount'));
        }
        if (netNewEmail && (typeof netNewEmail !== 'string')) {
            throw new Error('Net New Email must be a string');
        }
        if (handle && (typeof handle !== 'string')) {
            throw new Error('Handle must be a string');
        }
        if (planId && (typeof planId !== 'string')) {
            throw new Error('Plan Id must be a string');
        }
        if (cta && (typeof cta !== 'string')) {
            throw new Error('Custom "Call to Action" text must be a string');
        }
        if (!theme) {
            throw new Error('Theme is a required value');
        }
        if (theme !== 'app' && theme !== 'www' && theme !== 'legacy') {
            throw new Error("Invalid theme " + theme);
        }
        if (preselectedPaymentOption && typeof preselectedPaymentOption !== 'string') {
            throw new Error('Preselected Payment Option must be a string');
        }
    },
    validateCurrency: function (currencyProp, baseUrl, token, onError) {
        return __awaiter(this, void 0, void 0, function () {
            var paymentMethodOptions, err_1, messageType;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!currencyProp) {
                            onError(MessageType.VALIDATION_ERROR, true, { errorMessage: 'Currency required' });
                            return [2 /*return*/, false];
                        }
                        if (typeof currencyProp !== 'string') {
                            onError(MessageType.VALIDATION_ERROR, true, { errorMessage: 'Currency must be a string' });
                            return [2 /*return*/, false];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getPaymentMethodOptions(currencyProp, baseUrl, token)];
                    case 2:
                        paymentMethodOptions = _a.sent();
                        if (paymentMethodOptions.length < 1) {
                            onError(MessageType.VALIDATION_ERROR, true, { errorMessage: "UPMC is not configured for currency " + currencyProp });
                            return [2 /*return*/, false];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        messageType = this.determineErrorType(err_1);
                        onError(messageType, true, { errorMessage: err_1.message });
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/, true];
                }
            });
        });
    },
    getPaymentMethodOptions: function (currency, baseUrl, token) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Axios.get(baseUrl + "/unified-payment-method/currency/" + currency, {
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })];
                    case 1:
                        result = _a.sent();
                        if (result.data && result.data.status) {
                            if (result.data.status.success) {
                                return [2 /*return*/, result.data.data];
                            }
                            else {
                                throw new Error(result.data.status.errorMessage);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    formatIframeSrc: function (token, authAmount, currency, iframeUrl, version, theme, netNewEmail, handle, planId, cta, preselectedPaymentOption) {
        if (!version) {
            return;
        }
        var encodedOrigin = encodeURIComponent(window.top.location.origin);
        var encodedToken = encodeURIComponent(token);
        var encodedAuthAmount = encodeURIComponent(authAmount);
        var encodedCurrency = encodeURIComponent(currency);
        var encodedTheme = encodeURIComponent(theme);
        var src = iframeUrl + "/unified-payment-method/upmc-react-" + version + "?consumerOrigin=" + encodedOrigin + "&token=" + encodedToken + "&amount=" + encodedAuthAmount + "&currency=" + encodedCurrency + "&theme=" + encodedTheme;
        if (netNewEmail) {
            src += "&netNewEmail=" + encodeURIComponent(netNewEmail);
        }
        if (handle) {
            src += "&handle=" + encodeURIComponent(handle);
        }
        if (planId) {
            src += "&planId=" + encodeURIComponent(planId);
        }
        if (cta) {
            src += "&cta=" + encodeURIComponent(cta);
        }
        if (preselectedPaymentOption) {
            src += "&preselectedPaymentOption=" + encodeURIComponent(preselectedPaymentOption);
        }
        return src;
    },
    attemptPreflight: function (iframeSrc) {
        return __awaiter(this, void 0, void 0, function () {
            var preflightURL;
            return __generator(this, function (_a) {
                preflightURL = iframeSrc + '&preflight=true';
                return [2 /*return*/, Axios.get(preflightURL)];
            });
        });
    },
    determineErrorType: function (err) {
        if (err.response && err.response.status) {
            if (err.response.status === 401) {
                return MessageType.UNAUTHORIZED;
            }
            else if (err.response.status === 403) {
                return MessageType.BLOCKED;
            }
        }
        return MessageType.UNKNOWN_ERROR;
    },
    validateMessage: function (origin, allowedOrigin, data) {
        if (origin !== allowedOrigin) {
            return false;
        }
        return data.hasOwnProperty('messageType');
    },
    logConsumerMessage: function (token, messageType, payload, baseUrl) {
        return Axios.post(baseUrl + "/unified-payment-method/consumer-message", {
            token: token,
            messageType: messageType,
            payload: payload
        }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }
};
