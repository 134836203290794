import {Subscription} from '../../../definitions/subscription-account'
import {isPastDate} from '../utils/date-utils/is-past-date'
import {isFutureDate} from '../utils/date-utils/is-future-date'

export function getInTermSubscription(subscriptions: Subscription[]): Subscription {
    let currentSubscription = subscriptions.filter(
        (s) => isPastDate(s.startDate) && isFutureDate(s.termEndDate)
    )
    if (currentSubscription.length > 0 && subscriptions.length > 1) {
        return subscriptions.reduce(function(prev, current) {
            return new Date(prev.termEndDate).getTime() > new Date(current.termEndDate).getTime()
                ? prev
                : current
        })
    } else {
        return currentSubscription[0]
    }
}
