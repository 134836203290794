import {api} from '../../../../api/api'

export function deletePaymentMethod(paymentMethodId: string, planId: any) {
    return new Promise((resolve: any, reject: any) => {
        api.del(`plans/${planId}/payment-methods/${paymentMethodId}`).then((res) => {
            if (res) {
                resolve({success: true})
            } else {
                reject({success: false})
            }
        })
    })
}
