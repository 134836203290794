import {ExpandOrderSummary, ExpandSummaryFooter} from './Expand-Order-Summary/Expand-Order-Summary'
import React, {ReactNode, useEffect, useState} from 'react'
import {ExpandAccountSwitcher} from './Expand-Account-Switcher'
import {ReactComponent as PluralSightHeader} from '../images/pluralsight-logo-horizontal-white-outline.svg'
import {ExpandQuantitySelector} from './Expand-Quantity-Selector/Expand-Quantity-Selector'
import {ExpandPayment} from './Expand-Payment/Expand-Payment'
import {ExpandReviewConfirm, ExpandReviewConfirmMobile} from './Expand-Review-Confirm'
import {ExpandTermsAndConditions} from './Expand-Terms-And-Conditions'
import {ExpandStateV2, useExpandContext} from './Expand-Context'
import ExpandDataLoader from './Expand-Data-Loader'
import ThankYouPage from './Thank-You-Page'
import BlockedExpandRoutes from './Blocked-Expand/Blocked-Expand-Routes'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import styles from './Expand.module.scss'
import {BackToPlanLink} from '../Plan-Link/BackToPlanLink'
import {ProcessTransactionDecorator} from '../common/Transaction/process-transaction-decorator'
import GenericErrorPage from '../Renew-V2/generic-error-page/generic-error-page'

const mobileWidth = 769

export function ExpandV2() {
    return (
        <ExpandStateV2>
            <ExpandProcessing />
        </ExpandStateV2>
    )
}

function StandardOrMobile() {
    const [mobileLayout, setMobileLayout] = useState(false)
    const {state} = useExpandContext()
    const guardChecked = !!state.guardResponse
    useEffect(() => {
        if (window.innerWidth < mobileWidth) {
            setMobileLayout(true)
        }
    }, [])
    return (
        <ExpandDataLoader>
            {!guardChecked ? (
                <CenteredLoadingDecorator centerVertically={true} />
            ) : (
                <div className={styles.page}>
                    <PluralSightHeader />
                    {mobileLayout ? <MobileLayout /> : <StandardLayout />}
                </div>
            )}
        </ExpandDataLoader>
    )
}

function StandardLayout() {
    let {state} = useExpandContext()
    return (
        <div className={styles.expandContainer}>
            <div className={styles.backToPlan}>
                <BackToPlanLink planId={state.planId} />
            </div>
            <div className={styles.expand}>
                <main className={styles.mainDisplay}>
                    <ExpandMain />
                </main>
                <aside className={styles.aside}>
                    <div className={styles.accountSwitcher}>
                        <ExpandAccountSwitcher />
                    </div>
                    <ExpandOrderSummary />
                    <ExpandTermsAndConditions lowOnDark={true} />
                </aside>
            </div>
        </div>
    )
}

function MobileLayout() {
    let {state} = useExpandContext()
    return (
        <div className={styles.expandContainer}>
            <div className={styles.backToPlan}>
                <BackToPlanLink planId={state.planId} />
            </div>
            <div>
                <main className={styles.expand}>
                    <div className={styles.accountSwitcher}>
                        <ExpandAccountSwitcher />
                    </div>
                    <div className={styles.mainDisplay}>
                        <ExpandMobileMain />
                    </div>
                </main>
                <div className={styles.spacer}></div>
                <ExpandSummaryFooter />
            </div>
        </div>
    )
}

function ExpandProcessing() {
    let {state} = useExpandContext()
    if (state.hasError) {
        return (
            <BlockedExpandRoutes
                errorMessage={state.errorMessage}
                planId={state.planId}
                guardData={state.guardData}
            />
        )
    } else if (state.expandActivityIdError) {
        return <GenericErrorPage />
    } else if (state.expand?.pending) {
        return <ProcessTransactionDecorator />
    } else if (!state.expand?.loaded) {
        return <StandardOrMobile />
    } else {
        return <ThankYouPage />
    }
}

function ExpandMainLayout(props: {reviewConfirm: ReactNode}) {
    return (
        <div>
            <h2 className={styles.header}>Add Subscriptions</h2>
            <div className={styles.rows}>
                <ExpandQuantitySelector />
                <ExpandPayment />
                {props.reviewConfirm}
            </div>
        </div>
    )
}

function ExpandMain() {
    return <ExpandMainLayout reviewConfirm={<ExpandReviewConfirm />} />
}

function ExpandMobileMain() {
    return <ExpandMainLayout reviewConfirm={<ExpandReviewConfirmMobile />} />
}
