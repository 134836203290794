import {useAppContext} from '../useAppState'
import {useEffect, useState} from 'react'

export default function Profitwell(props: any) {
    const {state} = useAppContext()
    const [isInitialized, setIsInitialized] = useState(false)
    useEffect(initProfitwell, [state, isInitialized])

    function initProfitwell() {
        if (
            state.applicationData.featureConfig.profitwellFeatureFlag &&
            !isInitialized &&
            !!state.applicationData.user.email
        ) {
            window.profitwell('start', {user_email: state.applicationData.user.email})
            setIsInitialized(true)
        }
    }

    return props.children
}
