import React from 'react'
import Link from '@pluralsight/ps-design-system-link'
import {CopyToClipboardButton} from '../Copy-To-Clipboard-Button/CopyToClipboardButton'
import styles from './User-Info.module.scss'

export function UserInfo(props: UserProps) {
    const href = `/id/admin/users/details/${props.handle}`
    const name = `${props.firstName} ${props.lastName}`
    return (
        <div className={styles.contentMain}>
            <div>
                <div className={styles.userName}>{name}</div>
                <div className={styles.row}>
                    <div className={styles.label}>Handle:</div>
                    <div className={styles.contentInfo}>
                        <Link>
                            <a href={href}>{props.handle}</a>
                        </Link>{' '}
                        <CopyToClipboardButton textToCopy={props.handle} />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Email:</div>
                    <div className={styles.contentInfo}>
                        {props.email} <CopyToClipboardButton textToCopy={props.email} />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface UserProps {
    handle: string
    email: string
    firstName: string
    lastName: string
}
