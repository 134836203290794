import Button from '@pluralsight/ps-design-system-button'
import React from 'react'
import styles from './CallToAction.module.scss'

export function CallToAction(props: CtaProps) {
    return 'onClick' in props ? (
        <Button
            className={styles.ctaButton}
            appearance={Button.appearances.stroke}
            onClick={props.onClick}
        >
            {props.buttonText}
        </Button>
    ) : (
        <Button
            className={styles.ctaButton}
            appearance={Button.appearances.stroke}
            href={props.href}
        >
            {props.buttonText}
        </Button>
    )
}

type CtaProps = OnClickCTA | HrefCTA

interface OnClickCTA {
    buttonText: string
    onClick: () => any
}

interface HrefCTA {
    buttonText: string
    href: string
}
