import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import * as React from 'react'
import styles from './styles/process-transaction.module.scss'

export function ProcessTransactionDecorator() {
    return (
        <div className={styles.processingTransaction}>
            <CenteredLoadingDecorator />
            We're processing your order. Please do not refresh.
        </div>
    )
}
