import React, {ReactNode, useEffect, useState} from 'react'
import {useAppContext} from '../../useAppState'
import {api} from '../../api/api'
import {ForbiddenErrorPage} from '@pluralsight/ps-design-system-errors'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import {identify} from '../../analytics'
import {errorStatuses} from '../../../../definitions/sessionAuth'

export function SessionAuth(props: {children: ReactNode}) {
    const {setters} = useAppContext()
    const [hasSession, setHasSession] = useState(false)
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    useEffect(authenticate, [setters])

    const errorStatus: errorStatuses['userNotLoggedIn'] = 'User not logged in'

    function authenticate() {
        api.get('user/authenticated')
            .then((res) => {
                if (res.status.success) {
                    setShowLoadingDecorator(false)
                    identify(res.data.handle)
                    setHasSession(true)
                } else if (res.status.errorMessage === errorStatus) {
                    const currentLocation = window.location.href
                    window.location.assign(`/id?redirectTo=${encodeURIComponent(currentLocation)}`)
                } else {
                    setters.setAppHasError(true)
                }
            })
            .catch((e) => {
                setters.setAppHasError(true)
                console.error(e.stack)
            })
    }

    function mainContent() {
        return hasSession ? (
            props.children
        ) : (
            <ForbiddenErrorPage size={ForbiddenErrorPage.sizes.large} />
        )
    }

    return <>{showLoadingDecorator ? <CenteredLoadingDecorator /> : mainContent()}</>
}
