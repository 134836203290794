import React, {useEffect, useState} from 'react'
import {useDayzed} from 'dayzed'
import {
    Calendar,
    CalendarDates,
    onRangeDateSelected,
    useIsInRange,
} from '@pluralsight/ps-design-system-datepicker'
import TextInput from '@pluralsight/ps-design-system-textinput'

export function DateRangeCalendar({
    handleSelection,
}: {
    handleSelection: (dateRange: DateRange) => void
}) {
    const [startTime, setStartTime] = useState<string>('00:00')
    const [endTime, setEndTime] = useState<string>('23:59')
    const [selectedDates, setSelectedDates] = useState<Date[]>([])
    const {getDateProps, ...dayzedData} = useDayzed({
        selected: selectedDates,
        onDateSelected: onRangeDateSelected({
            selected: selectedDates,
            setSelected: setSelectedDates,
        }),
    })
    const {onMouseLeave, onMouseEnter, isInRange} = useIsInRange(selectedDates)

    useEffect(() => {
        const startTimeValid = isValid24HrTime(startTime)
        const endTimeValid = isValid24HrTime(endTime)
        const rangeSelected = selectedDates.length === 2

        if (rangeSelected && startTimeValid && endTimeValid) {
            const [startDate, endDate] = selectedDates.sort((a, b) => {
                if (a > b) return 1
                if (a < b) return -1
                return 0
            })

            const start = createDateWithTime(startDate, startTime).toISOString()
            const end = createDateWithTime(endDate, endTime).toISOString()
            handleSelection({start, end})
        }
    }, [selectedDates, startTime, endTime, handleSelection])

    return (
        <>
            <Calendar {...dayzedData} onMouseLeave={onMouseLeave}>
                <CalendarDates getDateProps={getDateProps}>
                    {(renderProps, dateObj) => {
                        return (
                            <button
                                {...renderProps}
                                {...isInRange(dateObj.date)}
                                onMouseEnter={() => onMouseEnter(dateObj.date)}
                            />
                        )
                    }}
                </CalendarDates>
            </Calendar>
            <TextInput
                onChange={(e) => setStartTime(e.currentTarget.value)}
                label='Start time'
                value={startTime}
                subLabel={'00:00 - 23:59'}
                error={!isValid24HrTime(startTime)}
            />
            <TextInput
                onChange={(e) => setEndTime(e.currentTarget.value)}
                label='End time'
                value={endTime}
                subLabel={'00:00 - 23:59'}
                error={!isValid24HrTime(endTime)}
            />
        </>
    )

    function isValid24HrTime(time: string) {
        return /^[0-1][0-9]:[0-5][0-9]$/.test(time) || /^2[0-3]:[0-5][0-9]$/.test(time)
    }
    function createDateWithTime(date: Date, time: string) {
        const [hours, minutes] = time.split(':').map((p) => Number(p))
        const dateWithTime = new Date(date)
        dateWithTime.setHours(hours)
        dateWithTime.setMinutes(minutes)
        return dateWithTime
    }
}

export interface DateRange {
    start: string
    end: string
}
