import * as React from 'react'
import {createContext, useContext, useReducer} from 'react'
import {SavedPaymentMethods} from '../../../../definitions/payment-methods'
import {
    ModifyGuardResponse,
    ModifyPayload,
    Modify,
    ModifyErrorMessage,
} from '../../../../definitions/modify'
import {api} from '../../api/api'
import {ModifySetters} from '../modify-setters'
import TransactionErrorBoundary from '../../common/Transaction-Error-Boundary/transaction-error-boundary'

const ModifyContext = createContext({})

export default function ModifyStateProvider(props: any) {
    const initialState: ModifyState = {
        preview: {
            data: {} as Modify,
            isInitialized: false,
        },
        tax: {
            taxAmount: 0,
            totalWithTax: 0,
        },
        payment: {
            paymentMethodId: null,
            savedPayments: [],
            paymentMethodsInitialized: false,
        },
        currentSubscription: {
            isLoaded: false,
        } as modifyBusinessSubscriptionDataV2,
        processingModify: false,
        modifyGuard: {
            canModify: null,
            reason: '' as ModifyErrorMessage,
            loadingGuard: true,
            data: null,
            subscriptionNumber: null,
            planId: '',
            maxQuantity: 1,
        },
        result: {
            processed: false,
            data: {} as Modify,
        },
        modifyError: false,
        subscriptionsToAdd: 1,
        loadBusinessAccountQuantity: 1,
    }
    const ModifyStateReducer = (state: ModifyState, action: {type: string; data: any}) => {
        const {
            initializeModify,
            setProcessing,
            setResult,
            loadPaymentMethods,
            loadBusinessAccountQuantity,
            setPaymentMethodId,
            resetPaymentMethods,
            setModifyGuard,
            setModifyError,
            resetModify,
            updateSubscriptionCount,
            taxModify,
            currentSubscription,
        } = ModifySetters
        switch (action.type) {
            case resetModify:
                return {
                    ...state,
                    ...initialState,
                }
            case initializeModify:
                return {
                    ...state,
                    preview: {
                        data: action.data,
                        isInitialized: true,
                    },
                } as any
            case currentSubscription:
                return {
                    ...state,
                    currentSubscription: {
                        data: action.data,
                        isLoaded: true,
                    },
                } as any
            case taxModify:
                return {
                    ...state,
                    tax: {
                        taxAmount: action.data.tax,
                        totalWithTax: action.data.totalWithTax,
                    },
                }
            case setProcessing:
                return {
                    ...state,
                    processingModify: true,
                }
            case setResult:
                return {
                    ...state,
                    result: {
                        processed: true,
                        data: action.data,
                    },
                }
            case loadPaymentMethods:
                return {
                    ...state,
                    payment: {
                        savedPayments: action?.data?.data?.paymentMethods?.savedPayments,
                        paymentMethodsInitialized: true,
                    },
                }
            case loadBusinessAccountQuantity:
                return {
                    ...state,
                    loadBusinessAccountQuantity: action?.data,
                }
            case setPaymentMethodId:
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentMethodId: action.data,
                    },
                }
            case resetPaymentMethods:
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentMethodId: null,
                    },
                }
            case setModifyGuard:
                return {
                    ...state,
                    modifyGuard: {
                        canModify: action.data.canModify,
                        planId: action.data.planId,
                        reason: action.data.message,
                        loadingGuard: false,
                        data: action.data.data,
                        subscriptionNumber: action.data.subscriptionNumber,
                        maxQuantity: action.data.maxQuantity,
                    },
                }
            case setModifyError:
                return {
                    ...state,
                    modifyError: true,
                    result: {
                        processed: true,
                    },
                }
            case updateSubscriptionCount:
                return {
                    ...state,
                    subscriptionsToAdd: action.data,
                }
        }
    }

    const getModifyActions: any = React.useMemo(() => {
        return {
            loadPaymentMethods: (planId: string) => {
                return api.get(`plans/${planId}/payment-methods`).then((res) => {
                    return res
                })
            },
            getSubscription(subscriptionId: string) {
                return api
                    .get(`subscription/${subscriptionId}`)
                    .then((res) => {
                        if (res.status.success) {
                            let currentSubscription: any = res.data?.subscriptions?.[0]
                            return currentSubscription
                        } else {
                            return res.data
                        }
                    })
                    .catch((error) => error)
            },
            loadBusinessAccountQuantity(planId: string) {
                return api.get(`plans/${planId}/account`).then((res) => {
                    if (res.status.success) {
                        return res.data.productState.products[0].productInfo.quantity
                    }
                })
            },
            getTax(taxRequest: any) {
                return api.post('calculateTax', taxRequest).then((res) => {
                    if (res.status.success) {
                        return res.data
                    }
                })
            },
            checkout: (payload: ModifyPlayLoad) => {
                return api.post(`checkout`, payload).then(validateResponse)
            },

            isModifyAllowed: async (planId: string, productOptionId: string) => {
                return api
                    .get(`plans/${planId}/product/${productOptionId}/modify-guard`)
                    .then((res) => {
                        if (res?.status?.success) {
                            return res?.data
                        } else {
                            throw new Error(res?.status?.errorMessage)
                        }
                    })
                    .catch((e) => {
                        return e
                    })
            },
            postAmend(planId: string, amendRequest: ModifyRequestV2) {
                return api
                    .post(`plans/${planId}/amend`, amendRequest)
                    .then((res) => {
                        if (res.status.success) {
                            let response = this.getAmendActivity(res.data.activityId)
                            return response
                        } else {
                            return res
                        }
                    })
                    .catch((e) => {
                        return e
                    })
            },

            async getAmendActivity(activityId: string) {
                return api
                    .getPolling(`getActivity/${activityId}`, (result) => {
                        if (result.data.status === 'SUCCESS') {
                            return true
                        } else if (result.data.status === 'ERROR') {
                            return true
                        }
                        return false
                    })
                    .then((res) => {
                        if (res.status.success) {
                            let response = validateResponse(res)
                            if (response?.data) {
                                return response.data
                            }
                            return response
                        } else {
                            return res
                        }
                    })
                    .catch((e) => {
                        return e
                    })
            },
        }
    }, [])

    function validateResponse(response: any) {
        if (response?.status?.success) {
            return response?.data
        } else {
            throw new Error(response?.status?.errorMessage)
        }
    }

    const context = {
        reducer: useReducer(ModifyStateReducer, initialState),
        actions: getModifyActions,
    }
    return (
        <TransactionErrorBoundary>
            <ModifyContext.Provider value={context}>{props.children}</ModifyContext.Provider>
        </TransactionErrorBoundary>
    )
}

export function useModifyState(): {state: ModifyState; setter: any; actions: ModifyActions} {
    const {reducer, actions} = useContext(ModifyContext) as any
    const [state, setter] = reducer
    return {state, setter, actions}
}

interface ModifyState {
    loadBusinessAccountQuantity: number
    preview: {
        data: Modify
        isInitialized: boolean
    }
    tax: {
        taxAmount: number
        totalWithTax: number
    }
    currentSubscription: modifyBusinessSubscriptionDataV2

    processingModify: boolean
    payment: {
        paymentMethodId: string | null
        savedPayments: Array<any>
        paymentMethodsInitialized: boolean
    }
    modifyGuard: {
        canModify: boolean | null
        reason: ModifyErrorMessage
        data: any
        loadingGuard: boolean
        subscriptionNumber: string | null
        planId: string
        maxQuantity: number
    }
    result: {
        processed: boolean
        data: Modify
    }
    subscriptionsToAdd: number
    modifyError: boolean
}
interface ModifyPlayLoad {
    paymentMethodId: string | null
    cartId: string
    paymentGateway: string
}

export interface modifyBusinessSubscriptionDataV2 {
    isLoaded: boolean
    unitOfMeasure: string
    subscriptionUrl: string
    subscriptionType: string
    subscriptionTerm: number
    subscriptionStartDate: string
    subscriptionPricing: string
    subscriptionNumber: string
    subscriptionId: string
    subscriptionEndDate: string
    salesOrderURL: string
    salesOrderNumber: string
    salesOrderItemURL: string
    quantity: number
    productSKU: string
    productShortDescription: string
    productName: string
    contractURL: string
    contractNumber: string
    contractId: string
    contact: {
        userHandle: string
        phone: string
        lastName: string
        firstName: string
        email: string
        contactUrl: string
        contactId: string
    }
    billing: {
        unitPrice: number
        listPrice: number
        billingFrequency: string
    }
    account: {
        soldTo: {
            streetAddress: string
            stateProvince: string
            postalCode: string
            name: string
            isPrimary: boolean
            country: string
            city: string
        }
        billTo: {
            streetAddress: string
            stateProvince: string
            postalCode: string
            name: string
            isPrimary: boolean
            country: string
            city: string
        }
        accountURL: string
        accountName: string
        accountId: string
    }
}
interface ModifyActions {
    modify: (planId: string, body: ModifyPayload) => Promise<Modify>
    loadPaymentMethods: (planId: string) => Promise<SavedPaymentMethods>
    postAmend: (planId: string, payload: ModifyRequestV2) => Promise<ModifyGuardResponse>
    getAmendActivity: (activityId: string) => Promise<ModifyGuardResponse>
    checkout: (payload: ModifyPlayLoad) => Promise<Modify>
    getTax: (payload: any) => Promise<any>
    getSubscription: (subscriptionId: any) => Promise<any>
    loadBusinessAccountQuantity: (planId: string) => Promise<any>
    isModifyAllowed: (planId: string, productOptionId: string) => Promise<ModifyGuardResponse>
}

export interface ModifyChild {
    quantity: number
    sku: string
}
export interface ModifyRequestV2 {
    quantity: number
    sku: string
    amendedSubscriptionId: string
    childComponents: [ModifyChild]
    isModify: boolean
    shouldCheckout: boolean
}
