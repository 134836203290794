import * as React from 'react'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/conditions.scss'
export default function Conditions() {
    const {state} = useRenewState()
    const productName = state?.renew?.data?.cart.cartItems?.[0].childItems?.[0].productName
    const renewDate = state?.renew?.data?.cart.cartItems?.[0].childItems?.[0].effectiveEndDate

    const taxText =
        '✝✝ Pluralsight is required by law to collect sales tax on purchases in some states. The actual tax amount will be calculated based on the applicable state and local sales tax when your order is processed.'

    const renewTerms = `✝ Price has been pro-rated for the remainder of your plan, based on the agreed upon price per subscription. All ${productName} subscriptions for this plan will renew on ${renewDate}, unless you cancel before then.`
    return (
        <div className='conditions'>
            <div>{renewTerms}</div>
            <div style={{marginBottom: '100px'}}>{taxText}</div>
        </div>
    )
}
