import React from 'react'

export const useAsyncError = () => {
    const stateArray = React.useState()
    const setError = stateArray[1]
    return React.useCallback(
        (e) => {
            setError(() => {
                throw e
            })
        },
        [setError]
    )
}
