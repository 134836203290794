import React from 'react'
import {Heading} from '@pluralsight/ps-design-system-text'
import styles from './IntegrationsContent.module.scss'

export function IntegrationsContent(props: {label: string; children: any}) {
    return (
        <div className={styles.integrationsContentContainer}>
            <Heading size={Heading.sizes.xSmall} className={styles.integrationHeader}>
                <h5>{props.label}</h5>
            </Heading>
            <div className={styles.content}>{props.children}</div>
        </div>
    )
}
