import React from 'react'
import styles from './Expand-Order-Summary.module.scss'
import {useExpandContext} from '../Expand-Context'
import {AmountPicker} from '../../Amount-Picker/Amount-Picker'
import Drawer from '../../common/Custom-Drawer/drawer'
import CircularProgress from '@pluralsight/ps-design-system-circularprogress'

export function ExpandOrderSummary() {
    return (
        <div className={styles.orderSummaryBase}>
            <div>
                <h4 className={styles.orderSummaryHeader}>Order summary</h4>
            </div>
            <div>
                <ExpandOrderSummaryBody />
            </div>
        </div>
    )
}

export function ExpandSummaryFooter() {
    const {setters, state} = useExpandContext()

    function toggleDrawer() {
        setters.setIsMobileOrderSummaryOpen(!state.isMobileOrderSummaryOpen)
    }

    function TopRow(props: {price: number; currency: string; isLoading: boolean}) {
        return (
            <div className={styles.topRow} onClick={() => toggleDrawer()}>
                <h3>
                    Order summary{' '}
                    <span
                        className={`${styles.chevron} ${
                            state.isMobileOrderSummaryOpen ? styles.openCaret : styles.closedCaret
                        }`}
                    />
                </h3>
                <div>
                    {props.isLoading ? (
                        <SmallLoadingDecorator />
                    ) : (
                        `${props.price + ' ' + props.currency}`
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.expandFooter}>
            <Drawer
                isOpen={state.isMobileOrderSummaryOpen}
                base={
                    <TopRow
                        price={state.expandPreview.amount}
                        isLoading={state.expandPreview.isLoading}
                        currency={state.expandPreview.currency}
                    />
                }
                children={
                    <div className={styles.footerTable}>
                        <hr
                            className={`${styles.expandFooterHrMargin} ${styles.horizontalRuleColor}`}
                        />
                        <ExpandOrderSummaryBody />
                    </div>
                }
            />
        </div>
    )
}

function ExpandOrderSummaryBody() {
    const {setters, state} = useExpandContext()

    function plusOne() {
        setters.setQuantity(Number(state.quantityToAdd) + 1)
    }

    function minusOne() {
        setters.setQuantity(state.quantityToAdd - 1)
    }

    function setAmount(quantity: number) {
        setters.setQuantity(quantity)
    }

    return (
        <OrderSummaryBody
            setAmount={setAmount}
            decrease={minusOne}
            increase={plusOne}
            numberOfSubscriptions={state.quantityToAdd}
            isLoading={state.expandPreview.isLoading}
            pricePerLicence={state.expandPreview.pricePerLicense}
            subtotal={state.expandPreview.amountWithoutTax}
            taxAmount={state.expandPreview.taxAmount}
            totalPrice={state.expandPreview.amount}
            currency={state.expandPreview.currency}
            productName={state.currentSubscription.productName}
        />
    )
}

function OrderSummaryBody(props: {
    numberOfSubscriptions: number
    increase: () => void
    decrease: () => void
    setAmount: (quantity: number) => void
    isLoading: boolean
    pricePerLicence?: number
    totalPrice?: number
    taxAmount?: number
    subtotal?: number
    currency: string
    productName: string
}) {
    return (
        <>
            <div className={`${styles.lowOnDarkText} ${styles.amountPickerRow}`}>
                <div>
                    <div id={'product'} className={styles.upperCase}>
                        Product
                    </div>
                    <div className={styles.productName} aria-labelledby={'product'}>
                        {props.productName}
                    </div>
                </div>
                <div>
                    <div className={`${styles.textAlignCenter} ${styles.upperCase}`}>
                        Subscriptions
                    </div>
                    <div className={styles.amountPickerContainer}>
                        <AmountPicker
                            numberOfLicences={props.numberOfSubscriptions}
                            increase={props.increase}
                            decrease={props.decrease}
                            setAmount={props.setAmount}
                        />
                    </div>
                </div>
                <div className={styles.textAlignRight}>
                    <div id={'price'} className={styles.upperCase}>
                        Price<span className={styles.daggers}>✝</span>
                    </div>

                    <div className={styles.productName}>
                        {props.isLoading ? (
                            <SmallLoadingDecorator />
                        ) : (
                            <span aria-labelledby={'price'}>
                                {props.pricePerLicence} {props.currency}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <hr className={`${styles.firstHrMargin} ${styles.horizontalRuleColor}`} />
            <div className={styles.row}>
                <div id={'subtotal'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                    Subtotal
                </div>
                <div className={styles.highOnDarkText}>
                    {props.isLoading ? (
                        <SmallLoadingDecorator />
                    ) : (
                        <span aria-labelledby={'subtotal'}>
                            {props.subtotal} {props.currency}
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.row}>
                <div id={'tax'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                    Estimated tax<span className={styles.daggers}>✝✝</span>
                </div>
                <div className={styles.highOnDarkText}>
                    {props.isLoading ? (
                        <SmallLoadingDecorator />
                    ) : (
                        <span aria-labelledby={'tax'}>
                            {props.taxAmount} {props.currency}
                        </span>
                    )}
                </div>
            </div>
            <hr className={`${styles.secondHrMargin} ${styles.horizontalRuleColor}`} />
            <div className={styles.totalRow}>
                <div id={'total'}>Total due today</div>
                <div className={styles.textAlignRight}>
                    {props.isLoading ? (
                        <SmallLoadingDecorator />
                    ) : (
                        <span aria-labelledby={'total'}>
                            {props.totalPrice} {props.currency}
                        </span>
                    )}
                </div>
            </div>
        </>
    )
}

function SmallLoadingDecorator() {
    return (
        <CircularProgress
            size={CircularProgress.sizes.small}
            className={styles.loadingDecoratorMargin}
            aria-label={'loading decorator'}
        />
    )
}
