import * as React from 'react'
import {useEffect} from 'react'
import GenericErrorPage from '../generic-error-page/generic-error-page'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/renew-loading.scss'
import '../styles/thank-you.scss'
import ThankYouRenew from './thank-you-renew'
import {ProcessTransactionDecorator} from '../../common/Transaction/process-transaction-decorator'

export default function ProcessRenew() {
    const {state} = useRenewState()
    const processed = state.result.processed
    useEffect(() => {}, [state.result.processed])
    return <>{!processed ? <ProcessTransactionDecorator /> : processResult(state.renewError)}</>
}

function processResult(error: boolean) {
    if (error) {
        return <GenericErrorPage />
    }
    return <ThankYouRenew />
}
