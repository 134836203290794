import React, {ReactNode, useEffect, useState} from 'react'
import {useAppContext} from '../../useAppState'
import {api} from '../../api/api'
import {ForbiddenErrorPage} from '@pluralsight/ps-design-system-errors'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'

export function CacheProvisioningAuth(props: {children: ReactNode}) {
    const {setters} = useAppContext()
    const [hasCacheProvisioningClaim, setHasCacheProvisioningClaim] = useState(false)
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    useEffect(getSupportProvisioningClaim, [setters])

    function getSupportProvisioningClaim() {
        api.get('support/hasCacheProvisioningClaim')
            .then((res) => {
                setHasCacheProvisioningClaim(res.data)
                setShowLoadingDecorator(false)
            })
            .catch(() => {
                setters.setAppHasError(true)
            })
    }

    function mainContent() {
        return hasCacheProvisioningClaim ? (
            props.children
        ) : (
            <ForbiddenErrorPage size={ForbiddenErrorPage.sizes.large} />
        )
    }

    return <>{showLoadingDecorator ? <CenteredLoadingDecorator /> : mainContent()}</>
}
