import React, {useState} from 'react'
import Drawer from '../../common/Custom-Drawer/drawer'
import {RowHeader} from '../../common/Row-Header/Row-Header'
import {useExpandContext} from '../Expand-Context'
import {useParams} from 'react-router-dom'
import UpmcComponent from '../../Billing/upmc/Upmc-Component'
import {ExistingPaymentMethodsSelector} from '../../common/Existing-Payment-Method-Selecttor/Existing-Payment-Method-Selector'
import {ExpandAnalyticsEvents} from '../expand-analytics-events'
import {ParamTypes} from '../../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import styles from './Expand-Payment.module.scss'
import {createPaypalMethod} from '../../Billing/Saved-Payment-Methods/Payments-Data/create-paypal-method/create-paypal-method'
import {useAppContext} from '../../useAppState'

export function ExpandPayment() {
    const {state: appState} = useAppContext()
    const {state, setters, actions} = useExpandContext()
    const {planId} = useParams<ParamTypes>()
    const [creatingPaypalMethod, setCreatingPaypalMethod] = useState(false)
    function hasExistingPaymentMethods() {
        return state.existingPaymentMethods.length > 0
    }

    function submitPayment() {
        setters.setIsPaymentOpen(false)
        setters.setIsPaymentComplete(true)
        setters.setIsReviewConfirmOpen(true)
        fireAnalytics()
    }

    function fireAnalytics() {
        actions.trackAnalyticsEvent(state, ExpandAnalyticsEvents.payment)
    }

    function addPaypalMethod(planId: string, successMessage: PaypalSuccess) {
        const body = {
            paypalBaid: successMessage.paypalBaid,
            paypalEmail: successMessage.paypalEmail,
            address: `${successMessage.address.address1} ${successMessage.address.address2}`,
            city: successMessage.address.city,
            state: successMessage.address.state,
            country: successMessage.address.country,
            zipCode: successMessage.address.postal,
        }

        return createPaypalMethod(
            appState.applicationData.featureConfig.useMulesoftPaypalCreationFlag,
            planId,
            body
        ).then((res) => {
            return res.data.id
        })
    }

    function onSuccess(successMessage: CreditCardSuccess | PaypalSuccess) {
        actions.loadPaymentMethods(planId)
        if (successMessage.paymentMethodType === 'PayPal') {
            setCreatingPaypalMethod(true)
            addPaypalMethod(planId, successMessage).then((paymentMethodId) => {
                setters.setPaymentMethodsInitialized(false)
                setters.setPaymentMethodId(paymentMethodId)
                setCreatingPaypalMethod(false)
                submitPayment()
            })
        } else {
            setters.setPaymentMethodId(successMessage.paymentMethodId)
            submitPayment()
        }
    }

    function ExpandExistingPaymentMethods() {
        return (
            <div>
                {state.paymentMethodsInitialized ? (
                    hasExistingPaymentMethods() ? (
                        <ExistingPaymentMethodsSelector
                            selectedPaymentId={state.paymentMethodId}
                            existingPaymentMethods={state.existingPaymentMethods}
                            selectFunction={(paymentMethodId1) => {
                                setters.setPaymentMethodId(paymentMethodId1)
                                submitPayment()
                            }}
                        />
                    ) : (
                        <div />
                    )
                ) : (
                    <CenteredLoadingDecorator centerVertically={true} />
                )}
            </div>
        )
    }

    function PaymentDropDown(props: {isOpen: boolean}) {
        const umpcHeader = hasExistingPaymentMethods()
            ? 'Or add a new payment method '
            : 'Add a new payment method'
        const currentPlanId = !!planId ? planId : ''
        return (
            <div>
                <ExpandExistingPaymentMethods />
                <h4 className={styles.addPaymentHeader}>{umpcHeader}</h4>
                {props.isOpen && !state.expandPreview.isLoading && (
                    <UpmcComponent
                        handle={state.handle}
                        planId={currentPlanId}
                        currency={state.currentSubscription.currency}
                        onSuccess={onSuccess}
                        authAmount={state.expandPreview.amount}
                    />
                )}
            </div>
        )
    }

    return (
        <div>
            <Drawer
                isOpen={state.isPaymentOpen}
                base={
                    <RowHeader
                        headerText={'Payment'}
                        active={state.isPaymentOpen}
                        complete={state.isPaymentComplete}
                        stepNumber={2}
                        resetToStep={actions.resetToPaymentSelection}
                    />
                }
                children={
                    !creatingPaypalMethod ? (
                        <PaymentDropDown isOpen={state.isPaymentOpen} />
                    ) : (
                        <CenteredLoadingDecorator />
                    )
                }
            />
        </div>
    )
}
