import * as React from 'react'
import {createContext, useContext, useReducer} from 'react'
import {PayPalId, SavedPaymentMethods} from '../../../../definitions/payment-methods'
import {
    Renew,
    RenewErrorMessage,
    RenewGuardResponse,
    RenewPayload,
} from '../../../../definitions/renew'
import {api} from '../../api/api'
import {createPaypalMethod} from '../../Billing/Saved-Payment-Methods/Payments-Data/create-paypal-method/create-paypal-method'
import {useAppContext} from '../../useAppState'
import RenewErrorBoundary from '../renew-error-boundary'
import {RenewSetters} from '../renew-setters'

const RenewContext = createContext({})

export default function RenewStateProvider(props: any) {
    const {state} = useAppContext()

    const initialState: RenewState = {
        renew: {
            data: {} as Renew,
            isInitialized: false,
        },
        payment: {
            paymentMethodId: null,
            savedPayments: [],
            paymentMethodsInitialized: false,
        },
        invoiced: false,
        processingRenew: false,
        renewGuard: {
            canRenew: null,
            reason: '' as RenewErrorMessage,
            loadingGuard: true,
            data: null,
        },
        result: {
            processed: false,
            data: {} as Renew,
        },
        renewError: false,
    }
    const renewStateReducer = (state: RenewState, action: {type: string; data: any}) => {
        const {
            initializeRenew,
            setProcessing,
            setResult,
            loadPaymentMethods,
            setPaymentMethodId,
            resetPaymentMethods,
            setRenewGuard,
            setRenewError,
            resetRenew,
            setRenewContactSales,
        } = RenewSetters
        switch (action.type) {
            case resetRenew:
                return {
                    ...state,
                    ...initialState,
                }
            case initializeRenew:
                return {
                    ...state,
                    renew: {
                        data: action.data,
                        canRenew: true,
                        isInitialized: true,
                    },
                } as any
            case setProcessing:
                return {
                    ...state,
                    processingRenew: true,
                }
            case setResult:
                return {
                    ...state,
                    result: {
                        processed: true,
                        data: action.data,
                    },
                }
            case loadPaymentMethods:
                return {
                    ...state,
                    payment: {
                        savedPayments: action.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    },
                }
            case setPaymentMethodId:
                return {
                    ...state,
                    payment: {
                        paymentMethodId: action.data,
                    },
                }
            case resetPaymentMethods:
                return {
                    ...state,
                    payment: {
                        savedPayments: [],
                        paymentMethodsInitialized: false,
                        setPaymentMethodId: '',
                    },
                }
            case setRenewGuard:
                return {
                    ...state,
                    renewGuard: {
                        canRenew: action.data.canRenew,
                        reason: action.data.message,
                        loadingGuard: false,
                        data: action.data.data,
                    },
                }
            case setRenewContactSales:
                return {
                    ...state,
                    renewGuard: {
                        canRenew: false,
                        reason: 'sales contact form redirect',
                        loadingGuard: false,
                        data: state.renewGuard.data,
                    },
                }
            case setRenewError:
                return {
                    ...state,
                    renewError: true,
                    result: {
                        processed: true,
                    },
                }
            default:
                return state
        }
    }

    const getRenewActions: RenewActions = React.useMemo(() => {
        return {
            getPreviewData: (planId: string) => {
                return api
                    .post(`plans/${planId}/renew-preview`, {paymentMethodId: null})
                    .then(validateResponse)
            },
            renewPlan: (planId: string, body: RenewPayload) => {
                return api.post(`plans/${planId}/renew`, body).then(validateResponse)
            },
            loadPaymentMethods: (planId: string) => {
                return api.get(`plans/${planId}/payment-methods`).then(validateResponse)
            },
            addPaypalMethod: (planId: string, successMessage: PaypalSuccess) => {
                const body = {
                    paypalBaid: successMessage.paypalBaid,
                    paypalEmail: successMessage.paypalEmail,
                    address: `${successMessage.address.address1} ${successMessage.address.address2}`,
                    city: successMessage.address.city,
                    state: successMessage.address.state,
                    country: successMessage.address.country,
                    zipCode: successMessage.address.postal,
                }
                return createPaypalMethod(
                    state.applicationData.featureConfig.useMulesoftPaypalCreationFlag,
                    planId,
                    body
                ).then(validateResponse)
            },
            canRenew: (planId: string) => {
                return api.get(`plans/${planId}/renew-guard`).then(validateResponse)
            },
        }
    }, [state.applicationData.featureConfig.useMulesoftPaypalCreationFlag])

    function validateResponse(response: any) {
        if (response.status.success) {
            return response.data
        } else {
            throw new Error(response.status.errorMessage)
        }
    }

    const context = {
        reducer: useReducer(renewStateReducer, initialState),
        actions: getRenewActions,
    }
    return (
        <RenewErrorBoundary>
            <RenewContext.Provider value={context}>{props.children}</RenewContext.Provider>
        </RenewErrorBoundary>
    )
}

export function useRenewState(): {state: RenewState; setter: any; actions: RenewActions} {
    const {reducer, actions} = useContext(RenewContext) as any
    const [state, setter] = reducer
    return {state, setter, actions}
}

interface RenewState {
    renew: {
        data: Renew
        isInitialized: boolean
    }
    invoiced: boolean
    processingRenew: boolean
    payment: {
        paymentMethodId: string | null
        savedPayments: Array<any>
        paymentMethodsInitialized: boolean
    }
    renewGuard: {
        canRenew: boolean | null
        reason: RenewErrorMessage
        data: any
        loadingGuard: boolean
    }
    result: {
        processed: boolean
        data: Renew
    }
    renewError: boolean
}

interface RenewActions {
    getPreviewData: (planId: string) => Promise<Renew>
    renewPlan: (planId: string, body: RenewPayload) => Promise<Renew>
    loadPaymentMethods: (planId: string) => Promise<SavedPaymentMethods>
    addPaypalMethod: (planId: string, successMessage: PaypalSuccess) => Promise<PayPalId>
    canRenew: (planId: string) => Promise<RenewGuardResponse>
}
