import React from 'react'
import {ReactComponent as PayPalIcon} from '../../images/paypal.svg'
import styles from './payment-methods-css-layouts.module.scss'

export function getPayPalDetails(paypalMethod: any) {
    return (
        <div className={styles.layoutDetails}>
            <div className={styles.image}>
                <PayPalIcon />
            </div>
            <div className={styles.contentText}>
                <div>PayPal account</div>
                <div style={{marginTop: '5px'}}>{paypalMethod.details.paypalEmail}</div>
                <div>{paypalMethod.details.paypalBaid}</div>
            </div>
        </div>
    )
}
