export function getNumberOfTimeUnitsBetweenDates(
    startDate: string,
    endDate: string,
    timeUnitInMilliseconds: number
) {
    const startDateMilliseconds = new Date(startDate).getTime()
    const endDateMilliseconds = new Date(endDate).getTime()
    if (startDateMilliseconds >= endDateMilliseconds) {
        return -1
    }
    const millisecondsUntilDate = endDateMilliseconds - startDateMilliseconds
    const daysBetweenDates = millisecondsUntilDate / timeUnitInMilliseconds
    return Math.floor(daysBetweenDates)
}
