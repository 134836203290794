export var MessageType;
(function (MessageType) {
    MessageType["SUCCESS"] = "SUCCESS";
    MessageType["UNAUTHORIZED"] = "UNAUTHORIZED";
    MessageType["BLOCKED"] = "BLOCKED";
    MessageType["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
    MessageType["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    MessageType["INFO_ZUORA_FORM_ERROR"] = "INFO_ZUORA_FORM_ERROR";
    MessageType["REQUEST_NEW_TOKEN"] = "REQUEST_NEW_TOKEN";
    MessageType["CURRENCY_CHANGE"] = "CURRENCY_CHANGE";
    MessageType["INITIAL_LOAD"] = "INITIAL_LOAD";
    MessageType["RESIZE"] = "RESIZE";
})(MessageType || (MessageType = {}));
