import * as React from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
import '../../common/Transaction/styles/transaction-success.scss'
import {useModifyState} from '../Modify-Context/modify-context'
import {LineItems} from '../../../../definitions/modify'
export default function MofidyOrderReceipt() {
    const {state} = useModifyState()
    const data = state.result.data
    const currency = data.currency
    const lineItems = data.lineItems
    return (
        <>
            <div className='receipt'>
                <h4 className='header'>Order summary</h4>
                <div className='summary'>
                    <table>
                        <tbody>
                            <tr>
                                <th style={{paddingRight: '60px'}}>Add-On</th>
                                <th>Subscriptions</th>
                                <th>Price</th>
                            </tr>
                            {lineItems.map((lineItem) => {
                                return formatLineItemProduct(lineItem, currency)
                            })}
                        </tbody>
                    </table>
                </div>
                <hr />
                <div className='subTotal'>
                    <div className='label'>
                        <div>Subtotal</div>
                        <div>Tax</div>
                    </div>
                    <div className='values'>
                        <div>
                            {formatPrice(data.amount - data.taxAmount)} {currency}
                        </div>
                        <div>
                            {formatPrice(state.tax.taxAmount)} {currency}
                        </div>
                    </div>
                </div>
                <hr />
                <div className='total'>
                    <div className='amountLabel'>Total</div>
                    <div className='amount'>
                        {formatPrice(state.tax.totalWithTax)} {currency}
                    </div>
                </div>
            </div>
            {getPaymentMethodSummary(data.paymentMethod)}
        </>
    )
}

function getPaymentMethodSummary(paymentMethod: string | null) {
    if (!paymentMethod) return
    const IsPayPal = paymentMethod.indexOf('PayPal') === 0
    const paymentMethodDetail = IsPayPal
        ? paymentMethod.substring(8, paymentMethod.length - 1)
        : paymentMethod.substring(paymentMethod.length - 4)
    return (
        <div className='paymentText'>
            {IsPayPal ? (
                <div>
                    Charged to PayPal account: <span>{paymentMethodDetail}</span>{' '}
                </div>
            ) : (
                <div>{`Charged to Visa ending in ${paymentMethodDetail}`}</div>
            )}
        </div>
    )
}

function formatLineItemProduct(lineItem: LineItems, currency: string) {
    return (
        <tr key={lineItem.productId}>
            <td>{lineItem.productName}</td>
            <td>{lineItem.quantity}</td>
            <td>
                {formatPrice(lineItem.amount)} {currency}
            </td>
        </tr>
    )
}
