import Button from '@pluralsight/ps-design-system-button'
import Dialog from '@pluralsight/ps-design-system-dialog'
import React from 'react'
import {colorsBackgroundDark, colorsTextIcon} from '@pluralsight/ps-design-system-core'
import Link from '@pluralsight/ps-design-system-link'
import {config} from '../../environment-config'
import './unpaid-invoice-modal.scss'

export default function UnpaidInvoiceModal(props: {
    linkText: string
    planId: string
    invoiceIds: string[]
    hideLink: boolean
}) {
    const [isOpen, setOpen] = React.useState(false)
    const {invoiceIds} = props
    const multipleUnpaidInvoices = invoiceIds.length > 1
    const contactSalesUrl = `/subscription/plans/${props.planId}/contact-sales/renew`
    const invoicePaymentUrl = multipleUnpaidInvoices
        ? `${config.billing.url}/billing/history`
        : `${config.billing.url}/invoice/${invoiceIds[0]}`

    return (
        <>
            {props.hideLink ? null : (
                <div aria-hidden={isOpen}>
                    <Link>
                        <span onClick={() => setOpen(!isOpen)} style={{cursor: 'pointer'}}>
                            {props.linkText}
                        </span>
                    </Link>
                </div>
            )}
            {isOpen && (
                <div style={{color: colorsTextIcon.highOnDark}}>
                    <Dialog
                        className='xIcon'
                        modal
                        onClose={() => setOpen(false)}
                        aria-label='unpaid invoice'
                        disableFocusOnMount
                        style={{
                            zIndex: 1,
                            background: colorsBackgroundDark[2],
                            padding: '16px',
                            width: '520px',
                            height: '264px',
                        }}
                    >
                        <div className='invoiceModal'>
                            <div className='heading'>
                                <div style={{color: colorsTextIcon.highOnDark}}>
                                    Unpaid invoices
                                </div>
                            </div>
                            <div className='text' style={{color: colorsTextIcon.highOnDark}}>
                                You have at least one unpaid invoice. Please pay your balance before
                                renewing your plan.
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Link>
                                <a href={contactSalesUrl}>Contact Sales</a>
                            </Link>
                            <Button style={{marginLeft: '18px'}} href={invoicePaymentUrl}>
                                Pay Invoice
                            </Button>
                        </div>
                    </Dialog>
                </div>
            )}
        </>
    )
}
