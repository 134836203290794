import {Route, Switch, useRouteMatch} from 'react-router-dom'
import React from 'react'
import CacheProvisioning from '../Cache-Provisioning/cache-provisioning'
import styles from './team-support.module.scss'
import TransactionPermissions from './transaction-permissions'
import {B2bUpgradeRequests} from './b2b-upgrade-requests'
import {NavBar} from '../Nav-Bar/Nav-Bar'
import {PromotionStateProvider} from './promotion-configuration/promotion-configuration-context'
import PromotionConfigurationRoutes from './promotion-configuration/promotion-configuration-routes'
import BatchUpdateSubscriptions from './BatchExtend/batchExtendSubscriptions'
import PhoenixProduct from './phoenix-product'

export default function TeamSupport() {
    let match = useRouteMatch()

    const links = [
        {
            to: `/subscription/team/cache-provisioning`,
            label: 'Cache Provisioning',
        },
        {
            to: `/subscription/team/transaction-permissions`,
            label: 'Transaction Permissions',
        },
        {
            to: `/subscription/team/b2b-upgrade-requests`,
            label: 'B2B Upgrade requests',
        },
        {
            to: `/subscription/team/promotion-config`,
            label: 'Promotion Config',
        },
        {
            to: `/subscription/team/batch-extend`,
            label: 'Batch Extend Subscription',
        },
        {
            to: `/subscription/team/phoenix-product`,
            label: 'Phoenix Product',
        },
    ]
    return (
        <div className={styles.contentArea}>
            <NavBar linkConfigs={links} />
            <Switch>
                <Route exact path={`${match.path}/cache-provisioning`}>
                    <CacheProvisioning />
                </Route>
                <Route exact path={`${match.path}/transaction-permissions`}>
                    <TransactionPermissions />
                </Route>
                <Route exact path={`${match.path}/b2b-upgrade-requests`}>
                    <B2bUpgradeRequests />
                </Route>
                <Route exact path={`${match.path}/batch-extend`}>
                    <BatchUpdateSubscriptions />
                </Route>
                <Route path={`${match.path}/promotion-config`}>
                    <PromotionStateProvider>
                        <PromotionConfigurationRoutes />
                    </PromotionStateProvider>
                </Route>
                <Route exact path={`${match.path}/phoenix-product`}>
                    <PhoenixProduct />
                </Route>
            </Switch>
        </div>
    )
}
