import React from 'react'
import {UpgradeErrorData, UpgradeErrorMessage} from '../../../../definitions/upgrade'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'
import {useUpgradeContext} from '../Upgrade-Context'
import GenericUpgradeError from './Generic-Upgrade-Error'
import UnpaidInvoice from '../../common/Unpaid-Invoice/Unpaid-Invoice'
import styles from './blocked-upgrade.module.scss'
import ContactSalesForm from '../../Contact-Sales-Form/Contact-Sales-Form'
import Pricing from './Pricing'
import {Redirect} from 'react-router'

export default function BlockedUpgradeRoutes(props: {
    errorMessage: UpgradeErrorMessage
    planId: string
    guardData: UpgradeErrorData
}) {
    const [open] = React.useState(true)
    return (
        <div>
            <div className={styles.logo}>
                <PluralSightHeader />
            </div>
            <div>
                <UpgradeError
                    errorMessage={props.errorMessage}
                    planId={props.planId}
                    guardData={props.guardData}
                />
            </div>
        </div>
    )

    function UpgradeError(props: {
        errorMessage: UpgradeErrorMessage
        planId: string
        guardData: UpgradeErrorData
    }) {
        const {setters, state} = useUpgradeContext()
        const previousProduct = state?.currentSubscription?.productName
        const redirectSalesForm = () => {
            setters.setHasError('sales contact form redirect')
        }
        switch (props.errorMessage) {
            case 'unpaid invoice':
                return (
                    <UnpaidInvoice
                        invoiceIds={props.guardData}
                        message={'upgrading your plan.'}
                        redirectSalesForm={redirectSalesForm}
                    />
                )
            case 'external customer source':
                return <Redirect to={`/subscription/plans/${props.planId}`} />
            case 'starter or professional sku not present - unknown error':
            case 'cancelled subscription':
            case 'has future products':
            case 'sales contact form redirect':
            case 'multiple in term products':
            case 'expired product cannot checkout':
            case 'non-standard billing period':
            case 'product has future state':
            case 'Negative amount preview':
            case 'Quantity limitation':
                return <ContactSalesForm requestType={'upgrade'} />
            case 'No products found':
            case 'pilot subscription':
            case 'expired product can checkout':
            case 'product price 0':
            case 'expired subscription':
                return (
                    <Pricing
                        isOpen={open}
                        subscription={state?.currentSubscription?.quantity}
                        previousProduct={previousProduct}
                        errorMessage={props.errorMessage}
                        requestType={'upgrade'}
                    />
                )
            default:
                return <GenericUpgradeError />
        }
    }
}
