import {
    CreditCardPaymentButton,
    ExpiredCreditCardDisplay,
    PayPalPaymentButton,
} from '../../Payment-Drawer/payment-method'
import React from 'react'
import {
    CreditCardPaymentMethod,
    PaymentMethod as PaymentMethodType,
    PaypalPaymentMethod,
} from '../../../../definitions/payment-methods'
import styles from './Existing-Payment-Method-Selector.module.scss'

export function ExistingPaymentMethodsSelector(props: {
    existingPaymentMethods: PaymentMethodType[]
    selectFunction: (paymentMethodId: string) => void
    selectedPaymentId: string
}) {
    const creditCards: CreditCardPaymentMethod[] = props.existingPaymentMethods.filter(isCreditCard)
    const expiredCreditCards = creditCards.filter(isExpiredCreditCard)
    const hasExpiredCreditCard = expiredCreditCards.length > 0
    const expiredCardNumber = hasExpiredCreditCard
        ? getExpiredCard(expiredCreditCards).details.cardNumber
        : ''
    const activeCreditCards = creditCards.filter(isActiveCreditCard)
    const hasActiveCreditCards = activeCreditCards.length > 0
    const paypalPaymentMethods: PaypalPaymentMethod[] = props.existingPaymentMethods.filter(
        isPaypalPaymentMethod
    )

    return (
        <div>
            <h4>Select a saved payment method</h4>
            <hr className={styles.existingPaymentHR} />
            <div className={styles.paymentRow}>
                {hasActiveCreditCards ? (
                    activeCreditCards.map((paymentMethod) => {
                        return (
                            <CreditCardPaymentButton
                                key={paymentMethod.id}
                                selectFunction={() => props.selectFunction(paymentMethod.id)}
                                selected={props.selectedPaymentId === paymentMethod.id}
                                cardNumber={paymentMethod.details.cardNumber}
                                expires={`${paymentMethod.details.expirationMonth}/${paymentMethod.details.expirationYear}`}
                            />
                        )
                    })
                ) : (
                    <ExpiredCreditCardDisplay cardNumber={expiredCardNumber} />
                )}
                {paypalPaymentMethods.map((paypalPaymentMethod) => {
                    return (
                        <PayPalPaymentButton
                            key={paypalPaymentMethod.id}
                            selectFunction={() => props.selectFunction(paypalPaymentMethod.id)}
                            selected={props.selectedPaymentId === paypalPaymentMethod.id}
                            paypalEmail={paypalPaymentMethod.details.paypalEmail}
                        />
                    )
                })}
            </div>
        </div>
    )
}

function isCreditCard(paymentMethod: PaymentMethodType): paymentMethod is CreditCardPaymentMethod {
    return paymentMethod.type === 'CreditCard'
}

function isExpiredCreditCard(creditCard: CreditCardPaymentMethod) {
    return getCreditCardExpirationDate(creditCard).getTime() < Date.now()
}

function isActiveCreditCard(creditCard: CreditCardPaymentMethod) {
    return getCreditCardExpirationDate(creditCard).getTime() > Date.now()
}

function getCreditCardExpirationDate(creditCard: CreditCardPaymentMethod): Date {
    return new Date(creditCard.details.expirationYear, creditCard.details.expirationMonth - 1, 0)
}

function getExpiredCard(expiredCreditCards: CreditCardPaymentMethod[]) {
    return (
        expiredCreditCards.filter((creditCard) => {
            return creditCard.default
        })[0] || expiredCreditCards[0]
    )
}

function isPaypalPaymentMethod(
    paymentMethod: PaymentMethodType
): paymentMethod is PaypalPaymentMethod {
    return paymentMethod.type === 'PayPal'
}
