import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import {config} from '../../environment-config'
import styles from './Unpaid-Invoice.module.scss'

export default function UnpaidInvoice(props: {
    invoiceIds: string[]
    redirectSalesForm?: () => void
    message?: string
}) {
    const {invoiceIds} = props
    const multipleUnpaidInvoices = invoiceIds.length > 1
    const invoicePaymentUrl = multipleUnpaidInvoices
        ? `${config.billing.url}/billing/history`
        : `${config.billing.url}/invoice/${invoiceIds[0]}`
    return (
        <div className={styles.errorPageLayout}>
            <div className={styles.container}>
                <div className={styles.title}>Unpaid Invoices</div>
                <div>
                    You have at least one unpaid invoice. Please pay your balance before{' '}
                    {props.message}
                </div>
                <div className={styles.buttonSection}>
                    {!!props.redirectSalesForm && (
                        <Button appearance='flat' onClick={props.redirectSalesForm}>
                            Contact sales
                        </Button>
                    )}
                    <Button href={invoicePaymentUrl} target={'_blank'}>
                        Pay invoice
                    </Button>
                </div>
            </div>
        </div>
    )
}
