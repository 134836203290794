import React, {createContext, useContext, useMemo, useState} from 'react'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {api} from '../api/api'
import {Upgrade} from '../../external-types/upgrade'
import {
    UpgradeErrorData,
    UpgradeErrorMessage,
    UpgradeGuardResponse,
    UpgradePayload,
    UpgradePreviewPayload,
} from '../../../definitions/upgrade'
import {useAsyncError} from '../useAsyncError'

const UpgradeState = (props: any) => {
    const activateErrorBoundary = useAsyncError()
    const initialState = {
        planId: '',
        handle: '',
        errorMessage: '',
        guardData: undefined,
        hasError: false,
        existingPaymentMethods: [],
        paymentMethodId: '',
        paymentMethodsInitialized: false,
        isPaymentOpen: true,
        isPaymentComplete: false,
        isMobileOrderSummaryOpen: false,
        isReviewConfirmOpen: false,
        guardResponse: null,
        currentSubscription: {
            isLoaded: false,
            productId: '',
            currentSubscriptionQuantity: 0,
            formattedAutoRenewDate: '',
        },
        upgradePreview: {
            amount: 0,
            taxAmount: 0,
            discountAmount: 0,
            percentDiscount: 0,
            currency: '',
            termEndDate: '',
            lineItems: [
                {
                    productId: '',
                    productOptionId: '',
                    priceOptionId: '',
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                    uom: '',
                },
                {
                    productId: '',
                    productOptionId: '',
                    priceOptionId: '',
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                    uom: '',
                },
            ],
            loaded: false,
            isLoading: true,
        },
        upgrade: {
            amount: 0,
            taxAmount: 0,
            discountAmount: 0,
            percentDiscount: 0,
            currency: '',
            termEndDate: '',
            lineItems: [
                {
                    productId: '',
                    productOptionId: '',
                    priceOptionId: '',
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                    uom: '',
                },
                {
                    productId: '',
                    productOptionId: '',
                    priceOptionId: '',
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                    uom: '',
                },
            ],
            loaded: false,
            pending: false,
        },
    }

    const useUpgradeState = () => {
        const [state, setState] = useState(!!props.initialState ? props.initialState : initialState)
        const setters = useMemo<UpgradeSetters>(() => getUpgradeSetters(setState), [setState])
        const [actions] = useState(getUpgradeActions(setState))
        return {state, actions, setters}
    }

    const getUpgradeSetters: (setState: any) => UpgradeSetters = (setState: any) => ({
        setPlanId: (planId: string | undefined) => {
            setState((state: any) => ({
                ...state,
                planId,
            }))
        },
        setHandle: (handle: string) => {
            setState((state: any) => ({
                ...state,
                handle,
            }))
        },
        setPaymentMethodId: (paymentMethodId: string) => {
            setState((state: any) => ({
                ...state,
                paymentMethodId,
            }))
        },
        setExistingPaymentMethods: (existingPaymentMethods: any) => {
            setState((state: any) => ({
                ...state,
                existingPaymentMethods,
            }))
        },
        setPaymentMethodsInitialized: (paymentMethodsInitialized: boolean) => {
            setState((state: any) => ({
                ...state,
                paymentMethodsInitialized,
            }))
        },
        setIsPaymentOpen: (isPaymentOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isPaymentOpen,
            }))
        },
        setIsPaymentComplete: (isPaymentComplete: boolean) => {
            setState((state: any) => ({
                ...state,
                isPaymentComplete,
            }))
        },
        setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isReviewConfirmOpen,
            }))
        },
        setHasAgreedToTerms: (hasAgreedToTerms: boolean) => {
            setState((state: any) => ({
                ...state,
                hasAgreedToTerms,
            }))
        },
        setPreviewLoading: () => {
            setState((state: any) => ({
                ...state,
                upgradePreview: {
                    ...state.upgradePreview,
                    isLoading: true,
                },
            }))
        },
        setCurrentSubscription: (currentSubscription: UpgradeBusinessSubscriptionData) => {
            setState((state: any) => ({
                ...state,
                currentSubscription,
            }))
        },
        setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isMobileOrderSummaryOpen,
            }))
        },
        setHasError(errorMessage: UpgradeErrorMessage) {
            setState((state: any) => ({
                ...state,
                hasError: true,
                errorMessage: errorMessage,
            }))
        },
    })

    const getUpgradeActions: (setState: any) => UpgradeActions = (setState: any) => ({
        loadPaymentMethods(planId: string) {
            api.get(`plans/${planId}/payment-methods`).then((res) => {
                if (res.status.success) {
                    setState((state: any) => ({
                        ...state,
                        existingPaymentMethods: res.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    }))
                }
            })
        },
        upgradePreview(planId: string, upgradePreviewPayload: UpgradePreviewPayload) {
            api.post(`plans/${planId}/upgrade-preview`, upgradePreviewPayload).then((res) => {
                if (res.status.success) {
                    setState((state: UpgradeState) => ({
                        ...state,
                        upgradePreview: {...res.data, isLoading: false, loaded: true},
                    }))
                } else {
                    setState((state: UpgradeState) => ({
                        ...state,
                        hasError: true,
                        errorMessage: res.status.errorMessage,
                        upgradePreview: {
                            ...state.upgradePreview,
                            isLoading: false,
                            loaded: true,
                        },
                    }))
                }
            })
        },
        upgradeGuardCheck(planId: string) {
            api.get(`plans/${planId}/upgrade-guard`).then((res) => {
                if (res.status.success) {
                    const guardResponse: UpgradeGuardResponse = res.data
                    setState((state: UpgradeState) => {
                        return {
                            ...state,
                            hasError: !guardResponse.canUpgrade,
                            errorMessage: guardResponse.canUpgrade ? '' : guardResponse.message,
                            guardResponse: guardResponse,
                            guardData: guardResponse.canUpgrade ? undefined : guardResponse.data,
                        }
                    })
                } else {
                    throw Error(res.status.errorMessage)
                }
            })
        },
        resetState() {
            setState((state: UpgradeState) => ({
                ...initialState,
            }))
        },
        resetToPaymentSelection() {
            setState((state: UpgradeState) => ({
                ...state,
                paymentMethodId: '',
                isPaymentOpen: true,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
        postUpgrade(currentState: UpgradeState) {
            setState((state: UpgradeState) => ({
                ...state,
                upgrade: {
                    loaded: false,
                    pending: true,
                },
            }))
            const upgradePayload: UpgradePayload = {
                paymentMethod: {paymentMethodId: currentState.paymentMethodId},
                invoiced: false,
                fromProductId: currentState.currentSubscription.productId,
                quantity: currentState.currentSubscription.currentSubscriptionQuantity,
            }
            const {planId} = currentState

            return api
                .post(`plans/${planId}/upgrade`, upgradePayload)
                .then((res) => {
                    if (res.status.success) {
                        setState((state: UpgradeState) => ({
                            ...state,
                            upgrade: {
                                ...res.data,
                                loaded: true,
                                pending: false,
                            },
                        }))
                    } else {
                        setState((state: any) => ({
                            ...state,
                            hasError: true,
                        }))
                    }
                })
                .catch((e) => {
                    activateErrorBoundary(e)
                })
        },
    })

    return (
        <UpgradeContext.Provider value={useUpgradeState()}>
            {props.children}
        </UpgradeContext.Provider>
    )
}

const UpgradeContext = createContext({} as any)

const useUpgradeContext = () => {
    return useContext<{state: UpgradeState; actions: UpgradeActions; setters: UpgradeSetters}>(
        UpgradeContext
    )
}

interface UpgradeSetters {
    setPlanId: (planId: string | undefined) => void
    setHandle: (handle: string) => void
    setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => void
    setIsPaymentOpen: (isPaymentOpen: boolean) => void
    setIsPaymentComplete: (isPaymentComplete: boolean) => void
    setPaymentMethodId: (paymentMethodId: string) => void
    setExistingPaymentMethods: (paymentMethods: any) => void
    setPaymentMethodsInitialized: (isInitialized: boolean) => void
    setPreviewLoading: () => void
    setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => void
    setCurrentSubscription: (currentSubscription: UpgradeBusinessSubscriptionData) => void
    setHasError: (errorMessage: UpgradeErrorMessage) => void
}

interface UpgradeActions {
    loadPaymentMethods: (planId: string) => void
    upgradePreview: (planId: string, upgradePreviewPayload: UpgradePreviewPayload) => void
    resetToPaymentSelection: () => void
    resetState: () => void
    postUpgrade: (currentState: UpgradeState) => void
    upgradeGuardCheck: (planId: string) => void
}

// eslint-disable-next-line
interface UpgradeState {
    planId: string
    handle: string
    currentSubscription: UpgradeBusinessSubscriptionData
    upgradePreview: UpgradePreview
    upgrade: UpgradePost
    hasError: boolean
    errorMessage: UpgradeErrorMessage
    guardData: UpgradeErrorData
    guardResponse: UpgradeGuardResponse | null
    isOrderSummaryOpen: boolean
    existingPaymentMethods: PaymentMethod[]
    isPaymentOpen: boolean
    isPaymentComplete: boolean
    paymentMethodId: string
    hasAgreedToTerms: boolean
    isReviewConfirmOpen: boolean
    isMobileOrderSummaryOpen: boolean
    paymentMethodsInitialized: boolean
}

interface UpgradeBusinessSubscriptionData {
    isLoaded: boolean
    currentSubscriptionQuantity: number
    currency: string
    productName: string
    formattedAutoRenewDate: string
    productId: string
}

interface UpgradePreview extends Upgrade {
    isLoading: boolean
    loaded: boolean
}

interface UpgradePost extends Upgrade {
    loaded: boolean
    pending: boolean
}

export {UpgradeState, useUpgradeContext}
