import * as React from 'react'
import {ReactNode} from 'react'
import '../styles/renew-error-content.scss'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'

export default function RenewErrorContent(props: {children: ReactNode}) {
    return (
        <div>
            <div className='logo'>
                <PluralSightHeader />
            </div>
            <div className='errorContentLayout'>{props.children}</div>
        </div>
    )
}
