import React, {createContext, useContext, useMemo, useState} from 'react'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {api} from '../api/api'
import {
    UpgradeErrorData,
    UpgradeErrorMessage,
    UpgradeGuardResponse,
    UpgradeRequestV2,
} from '../../../definitions/upgrade'
import {useAsyncError} from '../useAsyncError'

const UpgradeContextV2 = (props: any) => {
    const activateErrorBoundary = useAsyncError()
    const initialState: Partial<UpgradeStateV2> = {
        planId: '',
        handle: '',
        errorMessage: '',
        guardData: undefined,
        hasError: false,
        existingPaymentMethods: [],
        paymentMethodId: '',
        paymentMethodsInitialized: false,
        isPaymentOpen: true,
        isPaymentComplete: false,
        isMobileOrderSummaryOpen: false,
        isReviewConfirmOpen: false,
        guardResponse: null,
        getActivityError: false,
        currentSubscription: {
            isLoaded: false,
        } as UpgradeBusinessSubscriptionDataV2,
        upgradePreview: {
            amount: 0,
            currency: '',
            lineItems: [
                {
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                },
            ],
            loaded: false,
            isLoading: true,
        },
        upgrade: {
            amount: 0,
            taxAmount: 0,
            currency: '',
            termEndDate: '',
            lineItems: [
                {
                    amount: 0,
                    unitPrice: 0,
                    quantity: 0,
                },
            ],
            paymentMethod: '',
            loaded: false,
            pending: false,
        },
    }

    const useUpgradeState = () => {
        const [state, setState] = useState<UpgradePreview>(
            !!props.initialState ? props.initialState : initialState
        )
        const setters = useMemo<UpgradeSetters>(() => getUpgradeSetters(setState), [setState])
        const [actions] = useState(getActions(setState))
        return {state, actions, setters}
    }

    const getUpgradeSetters: (setState: any) => UpgradeSetters = (setState: any) => ({
        setPlanId: (planId: string | undefined) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                planId,
            }))
        },
        setHandle: (handle: string) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                handle,
            }))
        },
        setPaymentMethodId: (paymentMethodId: string) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentMethodId,
            }))
        },
        setExistingPaymentMethods: (existingPaymentMethods: any) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                existingPaymentMethods,
            }))
        },
        setPaymentMethodsInitialized: (paymentMethodsInitialized: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentMethodsInitialized,
            }))
        },
        setIsPaymentOpen: (isPaymentOpen: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isPaymentOpen,
            }))
        },
        setIsPaymentComplete: (isPaymentComplete: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isPaymentComplete,
            }))
        },
        setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isReviewConfirmOpen,
            }))
        },
        setHasAgreedToTerms: (hasAgreedToTerms: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                hasAgreedToTerms,
            }))
        },
        setPreviewLoading: () => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                upgradePreview: {
                    ...state.upgradePreview,
                    isLoading: true,
                },
            }))
        },
        setCurrentSubscription: (currentSubscription: UpgradeBusinessSubscriptionDataV2) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                currentSubscription,
            }))
        },
        setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => {
            setState((state: UpgradeStateV2) => ({
                ...state,
                isMobileOrderSummaryOpen,
            }))
        },
        setHasError(errorMessage: UpgradeErrorMessage) {
            setState((state: UpgradeStateV2) => ({
                ...state,
                hasError: true,
                errorMessage: errorMessage,
            }))
        },
    })

    const getActions: (setState: any) => UpgradeActions = (setState: any) => ({
        loadPaymentMethods(planId: string) {
            api.get(`plans/${planId}/payment-methods`).then((res) => {
                if (res.status.success) {
                    setState((state: any) => ({
                        ...state,
                        existingPaymentMethods: res.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    }))
                }
            })
        },
        postAmend(upgradeRequest: UpgradeRequestV2) {
            api.post(`plans/${upgradeRequest.planId}/amend`, upgradeRequest)
                .then((res) => {
                    if (res.status.success) {
                        setState((state: UpgradeStateV2) => ({
                            ...state,
                            amendActivity: {amendActivityId: res.data.activityId},
                        }))

                        this.getAmendActivity(res.data.activityId)
                    } else {
                        setState((state: UpgradeStateV2) => ({
                            ...state,
                            hasError: true,
                            errorMessage: res.status.errorMessage,
                        }))
                    }
                })
                .catch((e) => {
                    activateErrorBoundary(e)
                })
        },
        getAmendActivity(activityId: string) {
            api.getPolling(`getActivity/${activityId}`, (result) => {
                if (result.data.status === 'SUCCESS') {
                    return true
                } else if (result.data.status === 'ERROR') {
                    return true
                }
                return false
            }).then((res) => {
                if (res.status.success) {
                    if (res.data.status === 'ERROR') {
                        setState((state: any) => {
                            return {
                                ...state,
                                getActivityError: true,
                            }
                        })
                    } else {
                        setState((state: any) => {
                            const cart = res.data.data.cart
                            return {
                                ...state,
                                cartId: cart.cartId,
                                upgradePreview: {
                                    amount: cart.customerAmount,
                                    currency: cart.currencyCode,
                                    lineItems: [
                                        {
                                            amount: cart.listAmount,
                                            unitPrice:
                                                cart.listAmount / state.planSubscriptionQuantity,
                                            quantity: state.planSubscriptionQuantity,
                                        },
                                    ],
                                    isLoading: false,
                                    loaded: true,
                                },
                            }
                        })
                    }
                }
            })
        },
        getSubscription(subscriptionId: string) {
            api.get(`subscription/${subscriptionId}`)
                .then((res) => {
                    if (res.status.success) {
                        let currentSubscription: UpgradeBusinessSubscriptionDataV2 =
                            res.data?.subscriptions?.[0]
                        setState(
                            (state: UpgradeStateV2) =>
                                ({
                                    ...state,
                                    currentSubscription: {
                                        ...currentSubscription,
                                        isLoaded: true,
                                    },
                                } as UpgradeStateV2)
                        )
                    }
                })
                .catch((error) => activateErrorBoundary(error))
        },
        loadBusinessAccount(planId: string) {
            api.get(`plans/${planId}/account`).then((res) => {
                if (res.status.success) {
                    setState((state: UpgradeStateV2) => ({
                        ...state,
                        planSubscriptionQuantity:
                            res.data.productState.products[0].productInfo.quantity,
                    }))
                } else {
                    throw new Error(res.status.errorMessage)
                }
            })
        },
        getTax(taxRequest: any) {
            api.post('calculateTax', taxRequest).then((res) => {
                if (res.status.success) {
                    setState((state: UpgradeStateV2) => ({
                        ...state,
                        tax: {
                            subTotal: res.data.subTotal,
                            tax: res.data.tax,
                            totalWithTax: res.data.totalWithTax,
                            cartItems: res.data.cartItems,
                        },
                    }))
                }
            })
        },
        postCheckout(body: CheckoutPayLoad) {
            setState((state: UpgradeStateV2) => ({
                ...state,
                upgrade: {
                    pending: true,
                },
            }))
            api.post('checkout', body).then((res) => {
                if (res.status.success) {
                    setState((state: UpgradeStateV2) => ({
                        ...state,
                        upgrade: {
                            amount: state.tax.totalWithTax,
                            taxAmount: state.tax.tax | 0,
                            currency: state.upgradePreview.currency,
                            termEndDate: res.data.orderDetails.orderItems[0].endDate,
                            lineItems: res.data.orderDetails.orderItems.map((orderItem: any) => ({
                                amount: orderItem.totalPrice,
                                unitPrice: orderItem.unitPrice,
                                quantity: orderItem.quantity,
                            })),
                            paymentMethod: state.paymentMethodId,
                            loaded: true,
                            pending: false,
                        },
                    }))
                }
            })
        },
        upgradeGuardCheck(planId: string) {
            api.get(`plans/${planId}/upgrade-guard`).then((res) => {
                if (res.status.success) {
                    const guardResponse: UpgradeGuardResponse = res.data
                    setState((state: UpgradeStateV2) => {
                        return {
                            ...state,
                            hasError: !guardResponse.canUpgrade,
                            errorMessage: guardResponse.canUpgrade ? '' : guardResponse.message,
                            guardResponse: guardResponse,
                            guardData: guardResponse.canUpgrade ? undefined : guardResponse.data,
                        }
                    })
                } else {
                    throw Error(res.status.errorMessage)
                }
            })
        },
        resetState() {
            setState((state: UpgradeStateV2) => ({
                ...initialState,
            }))
        },
        resetToPaymentSelection() {
            setState((state: UpgradeStateV2) => ({
                ...state,
                paymentMethodId: state.paymentMethodId,
                isPaymentOpen: true,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
    })

    return (
        <UpgradeContext.Provider value={useUpgradeState()}>
            {props.children}
        </UpgradeContext.Provider>
    )
}

const UpgradeContext = createContext({} as any)

const useUpgradeContext = () => {
    return useContext<{state: UpgradeStateV2; actions: UpgradeActions; setters: UpgradeSetters}>(
        UpgradeContext
    )
}

interface UpgradeSetters {
    setPlanId: (planId: string | undefined) => void
    setHandle: (handle: string) => void
    setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => void
    setIsPaymentOpen: (isPaymentOpen: boolean) => void
    setIsPaymentComplete: (isPaymentComplete: boolean) => void
    setPaymentMethodId: (paymentMethodId: string) => void
    setExistingPaymentMethods: (paymentMethods: any) => void
    setPaymentMethodsInitialized: (isInitialized: boolean) => void
    setPreviewLoading: () => void
    setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => void
    setCurrentSubscription: (currentSubscription: UpgradeBusinessSubscriptionDataV2) => void
    setHasError: (errorMessage: UpgradeErrorMessage) => void
}

interface UpgradeActions {
    loadPaymentMethods: (planId: string) => void
    upgradeGuardCheck: (planId: string) => void
    postAmend: (upgradePreviewPayload: UpgradeRequestV2) => void
    getAmendActivity: (amendActivityId: string) => void
    postCheckout: (body: CheckoutPayLoad) => void
    getSubscription: (subscriptionId: string) => void
    loadBusinessAccount: (planId: string) => void
    getTax: (taxRequest: any) => void
    resetToPaymentSelection: () => void
    resetState: () => void
}

// eslint-disable-next-line
interface UpgradeStateV2 {
    cartId: string
    planId: string
    handle: string
    currentSubscription: UpgradeBusinessSubscriptionDataV2
    upgradePreview: UpgradePreview
    upgrade: UpgradePost
    hasError: boolean
    errorMessage: UpgradeErrorMessage
    guardData: UpgradeErrorData
    guardResponse: UpgradeGuardResponse | null
    isOrderSummaryOpen: boolean
    existingPaymentMethods: PaymentMethod[]
    isPaymentOpen: boolean
    isPaymentComplete: boolean
    paymentMethodId: string
    hasAgreedToTerms: boolean
    isReviewConfirmOpen: boolean
    isMobileOrderSummaryOpen: boolean
    paymentMethodsInitialized: boolean
    amendActivity: {amendActivityId: string}
    paymentAddress: PaymentAddress
    tax: UpgradeTaxV2
    getActivityError: boolean
    planSubscriptionQuantity: number
}

interface UpgradeTaxV2 {
    userCurrency: string
    shipFrom: {
        country: string
        postalCode: string
        state: string
        streetAddress: string
        city: string
    }
    shipTo: {
        country: string
        postalCode: string
        state: string
        streetAddress: string
        city: string
    }
    cartItems: [
        {
            sku: string
            quantity: number
            subTotal: number
            tax: number
            totalWithTax: number
        }
    ]
    subTotal: number
    tax: number
    totalWithTax: number
}

interface PaymentAddress {
    addressLine1: string
    addressLine2: string
    city: string
    stateProvince: string
    country: string
    postalCode: string
}
export interface CheckoutPayLoad {
    paymentMethodId: string | null
    cartId: string
}
export interface UpgradeBusinessSubscriptionDataV2 {
    isLoaded: boolean
    unitOfMeasure: string
    subscriptionUrl: string
    subscriptionType: string
    subscriptionTerm: number
    subscriptionStartDate: string
    subscriptionPricing: string
    subscriptionNumber: string
    subscriptionId: string
    subscriptionEndDate: string
    salesOrderURL: string
    salesOrderNumber: string
    salesOrderItemURL: string
    quantity: number
    productSKU: string
    productShortDescription: string
    productName: string
    contractURL: string
    contractNumber: string
    contractId: string
    contact: {
        userHandle: string
        phone: string
        lastName: string
        firstName: string
        email: string
        contactUrl: string
        contactId: string
    }
    billing: {
        unitPrice: number
        listPrice: number
        billingFrequency: string
    }
    account: {
        soldTo: {
            streetAddress: string
            stateProvince: string
            postalCode: string
            name: string
            isPrimary: boolean
            country: string
            city: string
        }
        billTo: {
            streetAddress: string
            stateProvince: string
            postalCode: string
            name: string
            isPrimary: boolean
            country: string
            city: string
        }
        accountURL: string
        accountName: string
        accountId: string
    }
}

interface UpgradePreview {
    amount: number
    currency: string
    lineItems: [
        {
            amount: number
            unitPrice: number
            quantity: number
        }
    ]
    isLoading: boolean
    loaded: boolean
}

export interface UpgradePost {
    amount: number
    taxAmount: number
    currency: string
    termEndDate: string
    lineItems: [
        {
            amount: number
            unitPrice: number
            quantity: number
        }
    ]
    paymentMethod: string
    loaded: boolean
    pending: boolean
}

export {UpgradeContextV2, useUpgradeContext}
