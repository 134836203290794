import CircularProgress from '@pluralsight/ps-design-system-circularprogress'
import React from 'react'
import styles from './Centered-Loading-Decorator.module.scss'

export function CenteredLoadingDecorator(props: {centerVertically?: boolean}) {
    return !!props.centerVertically ? (
        <div className={styles.centerVertical}>
            <CircularProgress aria-label={'Loading...'} />
        </div>
    ) : (
        <div>
            <CircularProgress aria-label={'Loading...'} className={styles.loadingDecoratorStyles} />
        </div>
    )
}
