import * as React from 'react'
import styles from './logo-header.module.scss'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'

export default function LogoHeader() {
    return (
        <div className={styles.header}>
            <PluralSightHeader />
        </div>
    )
}
