import * as React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {ModifySetters} from './modify-setters'
import {useModifyState} from './Modify-Context/modify-context'
import ModifyLandingPage from './modify-landing-page'
import ModifyBlocked from './Modify-Blocked/modify-blocked'
import {LinearLoadingDecorator} from '../Loading-Decorator/Linear-Loading-Decorator'

export default function ModifyGuard() {
    const {state, actions, setter} = useModifyState()
    const {planId, productOptionId} = useParams<ParamTypes>()
    useEffect(() => {
        if (state.modifyGuard.loadingGuard) {
            actions.isModifyAllowed(planId, productOptionId).then((data) => {
                if (data?.canModify) {
                    setter({type: ModifySetters.updateSubscriptionCount, data: data.maxQuantity})
                }
                setter({type: ModifySetters.setModifyGuard, data})
            })
        }
    }, [state.modifyGuard.loadingGuard, actions, setter, planId, productOptionId])

    useEffect(() => {
        actions
            .loadBusinessAccountQuantity(planId)
            .then((data) => setter({type: ModifySetters.loadBusinessAccountQuantity, data}))
    }, [actions, setter, planId])
    return (
        <>
            {state.modifyGuard.loadingGuard ? (
                <LinearLoadingDecorator centerVertically={true} />
            ) : state.modifyGuard.canModify ? (
                <ModifyLandingPage />
            ) : (
                <ModifyBlocked
                    planId={planId}
                    errorMessage={state.modifyGuard.reason}
                    data={state.modifyGuard.data}
                />
            )}
        </>
    )
}
