import * as React from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/thank-you.scss'
export default function OrderReceiptSummary() {
    const {state} = useRenewState()
    const renewData = state.result.data
    const currency = renewData.currency
    const lineItems = renewData.lineItems
    return (
        <>
            <div className='receipt'>
                <h4 className='header'>Order summary</h4>
                <div className='summary'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Plan</th>
                                <th>Subscriptions</th>
                                <th>Price</th>
                            </tr>
                            {lineItems.map((lineItem) => {
                                return formatLineItemProduct(lineItem, currency)
                            })}
                        </tbody>
                    </table>
                </div>
                <hr />
                <div className='subTotal'>
                    <div className='label'>
                        <div>Subtotal</div>
                        <div>Tax</div>
                    </div>
                    <div className='values'>
                        <div>
                            {formatPrice(renewData.amount - renewData.taxAmount)} {currency}
                        </div>
                        <div>
                            {formatPrice(renewData.taxAmount)} {currency}
                        </div>
                    </div>
                </div>
                <hr />
                <div className='total'>
                    <div className='amountLabel'>Total</div>
                    <div className='amount'>
                        {formatPrice(renewData.amount)} {currency}
                    </div>
                </div>
            </div>
            {getPaymentMethodSummary(renewData.paymentMethod)}
        </>
    )
}

function getPaymentMethodSummary(paymentMethod: string | null) {
    if (!paymentMethod) return
    const IsPayPal = paymentMethod.indexOf('PayPal') === 0
    const paymentMethodDetail = IsPayPal
        ? paymentMethod.substring(8, paymentMethod.length - 1)
        : paymentMethod.substring(paymentMethod.length - 4)
    return (
        <div className='paymentText'>
            {IsPayPal ? (
                <div>
                    Charged to PayPal account: <span>{paymentMethodDetail}</span>{' '}
                </div>
            ) : (
                <div>{`Charged to Visa ending in ${paymentMethodDetail}`}</div>
            )}
        </div>
    )
}

function formatLineItemProduct(lineItem: any, currency: string) {
    return (
        <tr key={lineItem.productRatePlanId}>
            <td>{lineItem.productName}</td>
            <td>{lineItem.quantity}</td>
            <td>
                {formatPrice(lineItem.unitPrice)} {currency}
            </td>
        </tr>
    )
}
