import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import styles from './blocked-expand.module.scss'
import {config} from '../../environment-config'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@pluralsight/ps-design-system-link'

export default function ExpiredPlan() {
    function RenewNowButton() {
        const pricingPage = `${config.www.url}/pricing`
        return (
            <Button className={styles.mainCTAButton} href={pricingPage}>
                Renew Now
            </Button>
        )
    }
    function BackToSub() {
        return (
            <Link>
                <RouterLink to={'/subscription/plans'} className={styles.backToPlanMessage}>
                    Back to Plan
                </RouterLink>
            </Link>
        )
    }

    return (
        <div className={styles.errorPageLayout}>
            <div className={styles.errorMessage}>
                We can’t add new subscriptions to your account because your plan has expired.
            </div>
            <RenewNowButton />
            <BackToSub />
        </div>
    )
}
