import NumberIcon from '../../Number-Icon/number-icon'
import React, {ReactNode} from 'react'
import styles from './Row-Header.module.scss'

export function RowHeader(props: RowHeaderProps) {
    return (
        <div className={styles.rowBase}>
            <NumberIcon active={props.active} complete={props.complete} value={props.stepNumber} />
            <h3
                className={`${styles.rowHeader} 
                ${props.active ? styles.activeRowHeader : ''}
                ${props.complete ? styles.completedRowHeaderHover : ''}`}
                onClick={() => {
                    if (props.complete) {
                        props.resetToStep()
                    }
                }}
            >
                {props.headerText}
            </h3>
        </div>
    )
}

export function RowHeaderRightIcon(props: RowHeaderWithIcon) {
    return (
        <div className={`${styles.rowBase} ${styles.rowBaseForIcon} `}>
            <div className={styles.section}>
                <NumberIcon
                    active={props.active}
                    complete={props.complete}
                    value={props.stepNumber}
                />
                <h3 className={`${styles.rowHeader} ${props.active ? styles.activeRowHeader : ''}`}>
                    {props.headerText}
                </h3>
            </div>
            {props.active && (
                <div className={styles.section}>
                    <span style={{marginRight: '8px'}}>
                        <div>{props.alternateText}</div>
                    </span>
                    {props.icon}
                </div>
            )}
        </div>
    )
}

interface RowHeaderProps {
    headerText: string
    active: boolean
    complete: boolean
    stepNumber: number
    resetToStep: () => void
}

interface RowHeaderWithIcon {
    headerText: string
    active: boolean
    complete: boolean
    stepNumber: number
    icon: ReactNode
    alternateText?: string
}
