import * as React from 'react'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'
import UpmcComponent from '../../Billing/upmc/Upmc-Component'
import {ExistingPaymentMethodsSelector} from '../../common/Existing-Payment-Method-Selecttor/Existing-Payment-Method-Selector'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import {useAppContext} from '../../useAppState'
import {useRenewState} from '../Renew-Context/renew-context'
import {RenewSetters} from '../renew-setters'
import {setDefaultPaymentMethod} from '../../Billing/Saved-Payment-Methods/Payments-Data/Set-Default-Payment-Method/set-default-payment-method'

export default function PaymentMethods() {
    const {state, actions, setter} = useRenewState()
    const {state: appState} = useAppContext()
    const {planId} = useParams<ParamTypes>()
    const [processingPaymentMethod, setProcessingPaymentMethod] = useState(false)

    useEffect(() => {
        actions.loadPaymentMethods(planId).then((data) => {
            setter({type: RenewSetters.loadPaymentMethods, data})
        })
    }, [actions, setter, planId, state.payment.paymentMethodsInitialized])

    function selectPayment(selectedPaymentMethodId: string) {
        setProcessingPaymentMethod(true)
        const paymentMethodUsed = state?.payment?.savedPayments.filter((paymentMethod) => {
            return selectedPaymentMethodId === paymentMethod.id
        })[0]
        let body = {
            userCurrency: state.renew.data.cart.currencyCode,
            cartItems: state.renew.data.cart.cartItems
                .filter((cartItem) => cartItem.hasOwnProperty('childItems'))
                .map((cartItem) => {
                    return {
                        sku: cartItem.productCode,
                        quantity: cartItem.quantity,
                        subTotal: state.renew.data.cart.netAmount,
                    }
                }),
            shipTo: {
                streetAddress: paymentMethodUsed?.details?.cardHolderInfo?.addressLine1,
                city: paymentMethodUsed?.details?.cardHolderInfo?.city,
                stateProvince: paymentMethodUsed?.details?.cardHolderInfo?.state,
                country: paymentMethodUsed.details?.cardHolderInfo.country,
                postalCode: paymentMethodUsed?.details?.cardHolderInfo?.zipCode,
            },
        }

        actions.calculateTax(body).then((data) => {
            setter({type: RenewSetters.calculateTax, data})
            setter({type: RenewSetters.setPaymentMethodId, data: selectedPaymentMethodId})

            setProcessingPaymentMethod(false)
        })
    }
    async function onSuccess(successMessage: CreditCardSuccess | PaypalSuccess) {
        setProcessingPaymentMethod(true)
        let body = {
            userCurrency: state.renew.data.cart.currencyCode,
            cartItems: state.renew.data.cart.cartItems
                .filter((cartItem) => cartItem.hasOwnProperty('childItems'))
                .map((cartItem) => {
                    return {
                        sku: cartItem.productCode,
                        quantity: cartItem.quantity,
                        subTotal: state.renew.data.cart.netAmount,
                    }
                }),
            shipTo: {
                streetAddress: `${successMessage.address.address1} ${successMessage.address.address2}`,
                city: successMessage.address.city,
                stateProvince: successMessage.address.state,
                country: successMessage.address.country,
                postalCode: successMessage.address.postal,
            },
        }

        if (successMessage.paymentMethodType === 'PayPal') {
            actions.addPaypalMethod(planId, successMessage).then((response) => {
                setDefaultPaymentMethod(response.id, planId)
                actions.calculateTax(body).then((data) => {
                    const newPaymentSetData = {
                        paymentMethodId: successMessage.paypalBaid,
                        paymentMethodType: successMessage.paymentMethodType,
                    }
                    setter({type: RenewSetters.calculateTax, data})
                    setter({type: RenewSetters.setPaymentMethodId, data: response.id})
                    setter({type: RenewSetters.setNewPaymentMethod, data: newPaymentSetData})
                    setProcessingPaymentMethod(false)
                })
            })
        } else {
            setter({type: RenewSetters.setPaymentMethodId, data: successMessage.paymentMethodId})
            setDefaultPaymentMethod(successMessage.paymentMethodId, planId)
            actions.calculateTax(body).then((data) => {
                const newPaymentSetData = {
                    paymentMethodId: successMessage.paymentMethodId,
                    paymentMethodType: successMessage.paymentMethodType,
                }
                setter({type: RenewSetters.calculateTax, data})
                setter({
                    type: RenewSetters.setPaymentMethodId,
                    data: successMessage.paymentMethodId,
                })
                setter({type: RenewSetters.setNewPaymentMethod, data: newPaymentSetData})
                setProcessingPaymentMethod(false)
            })
        }
    }
    function getUpmcHeaderText(existingPayments: boolean) {
        return existingPayments ? 'Or add a new payment method' : 'Add a new Payment Method'
    }
    return (
        <div className='content'>
            {!processingPaymentMethod ? (
                <div>
                    {!!state.payment.paymentMethodsInitialized ? (
                        <>
                            {state.payment.savedPayments &&
                                state.payment.savedPayments.length > 0 && (
                                    <ExistingPaymentMethodsSelector
                                        selectedPaymentId={
                                            !!state.payment.paymentMethodId
                                                ? state.payment.paymentMethodId
                                                : ''
                                        }
                                        existingPaymentMethods={state.payment.savedPayments}
                                        selectFunction={selectPayment}
                                    />
                                )}
                            <div style={{margin: '30px 0'}}>
                                <div style={{marginBottom: '20px'}}>
                                    {getUpmcHeaderText(
                                        state.payment.savedPayments &&
                                            state.payment.savedPayments.length > 0
                                    )}
                                </div>
                                <UpmcComponent
                                    handle={appState.applicationData.user.handle}
                                    planId={planId}
                                    currency={state.renew.data.cart.currencyCode}
                                    onSuccess={onSuccess}
                                    authAmount={1}
                                />
                            </div>
                        </>
                    ) : (
                        <CenteredLoadingDecorator />
                    )}
                </div>
            ) : (
                <CenteredLoadingDecorator />
            )}
        </div>
    )
}
