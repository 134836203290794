import React from 'react'
import {useUpgradeContext} from './Upgrade-Context'
import {useAppContext} from '../useAppState'
import Button from '@pluralsight/ps-design-system-button'
import {config} from '../environment-config'
import {Upgrade} from '../../external-types/upgrade'
import styles from './upgrade-thank-you-page.module.scss'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {Heading} from '@pluralsight/ps-design-system-text'
import GenericUpgradeError from './Blocked-Upgrade/Generic-Upgrade-Error'
import {upgradeLineItemUnitPrice} from './upgrade-line-items'

export default function UpgradeThankYouPage() {
    const {state} = useUpgradeContext()
    const {state: appState} = useAppContext()
    const {firstName, email} = appState.applicationData.user
    const {currentSubscriptionQuantity, productName} = state.currentSubscription
    const viewInvoiceLink = `${config.billing.url}/billing/history`
    const backToSubscriptionLink = `/subscription/plans`
    const upgrade = state.upgrade
    const upgradeUnitPrice = upgradeLineItemUnitPrice(state.upgradePreview.lineItems)
    const paymentMethodUsed = state.existingPaymentMethods.filter(
        (paymentMethod) => paymentMethod.id === state.paymentMethodId
    )[0]
    const upgradeSuccess = state.upgrade.loaded
    const upgradeProductName = upgradeProduct(productName)

    return (
        <>
            {upgradeSuccess ? (
                <div className={styles.layout}>
                    <Heading size={Heading.sizes.large} className={styles.heading}>
                        <h2>Thank you, {firstName}!</h2>
                    </Heading>
                    <div className={styles.subHeading}>
                        Your plan has been upgraded to <b>{upgradeProductName}</b>.
                    </div>
                    <div className={styles.buttonRow}>
                        <div className={styles.button}>
                            <Button appearance={Button.appearances.stroke} href={viewInvoiceLink}>
                                View Invoice
                            </Button>
                        </div>
                        <div className={styles.button}>
                            <Button
                                appearance={Button.appearances.primary}
                                href={backToSubscriptionLink}
                            >
                                Back to Subscription
                            </Button>
                        </div>
                    </div>
                    <hr className={styles.ruling} />
                    <div className={styles.emailDisplay}>
                        Your full receipt has been sent to {email}
                    </div>
                    <OrderSummary
                        upgrade={upgrade}
                        quantity={currentSubscriptionQuantity}
                        productName={upgradeProductName}
                        unitPrice={upgradeUnitPrice}
                    />
                    <PaymentMethodFormat paymentMethod={paymentMethodUsed} />
                </div>
            ) : (
                <GenericUpgradeError />
            )}
        </>
    )
}

function PaymentMethodFormat(props: {paymentMethod: PaymentMethod}) {
    if (props.paymentMethod.type === 'PayPal') {
        return (
            <div className={styles.note}>
                Charged to PayPal account:{' '}
                <span style={{display: 'block'}}>props.paymentMethod.details.paypalEmail</span>
            </div>
        )
    } else {
        return (
            <div className={styles.note}>
                Charged To Visa ending in {props.paymentMethod.details.cardNumber.slice(-4)}
            </div>
        )
    }
}

function OrderSummary(props: {
    upgrade: Upgrade
    quantity: number
    productName: string
    unitPrice: number
}) {
    return (
        <div className={styles.orderSum}>
            <div className={styles.orderSummaryHeading}> Order summary</div>
            <div className={styles.row}>
                <div>Plan</div>
                <div>Subscriptions</div>
                <div>Price</div>
            </div>
            <div className={styles.row}>
                <p>{props.productName}</p>
                <p className={styles.second}>{props.quantity}</p>
                <p>
                    {formatMoney(props.unitPrice)} {props.upgrade.currency}
                </p>
            </div>
            <hr className={styles.ruling1} />
            <div className={styles.midrow}>
                <div className={styles.first}>Subtotal</div>
                <div>
                    {formatMoney(props.upgrade.amount)} {props.upgrade.currency}
                </div>
                <div className={styles.first}>
                    Tax<span className={styles.sym}>✝✝</span>
                </div>
                <div>
                    {formatMoney(props.upgrade.taxAmount)} {props.upgrade.currency}
                </div>
            </div>
            <hr className={styles.ruling2} />
            <div className={styles.total}>
                <div>Total</div>
                <div>
                    {formatMoney(props.upgrade.amount)} {props.upgrade.currency}
                </div>
            </div>
        </div>
    )
}

function formatMoney(amount: number) {
    return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function upgradeProduct(productName: string) {
    if (productName === 'Business Starter') {
        return 'Business Professional'
    } else if (productName === 'Business Professional') {
        return 'Business Enterprise'
    } else {
        return 'Business'
    }
}
