import React, {useEffect, useState} from 'react'
import {api} from '../../api/api'
import './batchExtendSubscriptions.module.scss'

export default function BatchUpdateSubscriptions() {
    const [file, setFile] = useState<File>()
    const [notice, setNotice] = useState('')
    const [date, setDate] = useState<string>('')
    const [validDate, setValidDate] = useState<boolean>()

    function onChangeHandler(event: any) {
        setFile(event.target.files[0])
    }

    useEffect(() => {
        if (date.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/)) {
            setValidDate(true)
        } else {
            setValidDate(false)
        }
    }, [date])

    const onClickHandler = async () => {
        setNotice('processing started')
        const fileText = await file?.text()

        const payload = {
            file: fileText,
            date: date,
        }
        await api.post('internal-api/batch-extend-subscription', payload)
        setNotice('Processing finished')
    }

    return (
        <div>
            <div>{notice}</div>
            <h2 style={{marginBottom: '20px'}}>Extend subscriptions until date</h2>
            <div className='batchExtendContainer'>
                <input type='file' name='file' onChange={onChangeHandler} />
                <input
                    type='text'
                    placeholder='yyyy-mm-dd'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                {validDate ? <div /> : <div>date invalid please match format yyyy-mm-dd</div>}
                <button disabled={!file || !validDate} type='button' onClick={onClickHandler}>
                    Post
                </button>
            </div>
        </div>
    )
}
