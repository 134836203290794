import React, {ReactNode, useEffect} from 'react'
import {useAppContext} from '../useAppState'
import {useUpgradeContext} from './Upgrade-Context'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {formatDate} from '../utils/date-utils/format-date'

export default function UpgradeDataLoader(props: {children: ReactNode}) {
    const {state: appState, actions: appActions} = useAppContext()
    const {state, actions, setters} = useUpgradeContext()

    let {planId} = useParams<ParamTypes>()

    useEffect(() => {
        const hasPlanId = !!planId
        const hasGuardResponse = !!state.guardResponse
        const planIdMismatch = planId !== state.guardResponse?.planId
        if ((hasPlanId && !hasGuardResponse) || planIdMismatch) {
            actions.upgradeGuardCheck(planId)
        }
    }, [actions, planId, state.guardResponse])

    useEffect(() => {
        if (
            !!planId &&
            state.currentSubscription.isLoaded &&
            !state.upgrade.loaded &&
            state.guardResponse?.canUpgrade
        ) {
            setters.setPreviewLoading()
            actions.upgradePreview(planId, {
                fromProductId: state.currentSubscription.productId,
                quantity: state.currentSubscription.currentSubscriptionQuantity,
            })
        }
    }, [
        planId,
        actions,
        state.upgrade.loaded,
        state.currentSubscription.isLoaded,
        state.currentSubscription.currentSubscriptionQuantity,
        state.currentSubscription.productId,
        state.guardResponse?.canUpgrade,
        setters,
    ])

    useEffect(() => {
        if (!!planId) {
            appActions.loadBusinessAccount(planId)
        }
    }, [planId, appActions])

    useEffect(() => {
        const account = appState.businessAccountMap[planId]
        const accountLoaded = (account && account.loaded) || false
        if (!!planId && accountLoaded) {
            const product = account.data.productState.products.filter(
                (p) =>
                    p.productInfo.inTerm &&
                    p.productCatalogInfo.product.category === 'Base Products'
            )[0]
            const productName = account.data.productState.products.filter(
                (p) => p.productCatalogInfo.product.category === 'Base Products'
            )[0].productCatalogInfo.product.name
            let currentSubscription = {
                isLoaded: true,
                currentSubscriptionQuantity: product?.productInfo.quantity | 0,
                currency: account.data.billingInfo.currency,
                productName: productName,
                formattedAutoRenewDate: formatDate(product?.productInfo.expiresAt),
                productId: product?.productCatalogInfo.productOption.productId,
            }
            setters.setCurrentSubscription(currentSubscription)
        }
    }, [planId, setters, appState])

    useEffect(() => {
        if (!!planId && !state.paymentMethodsInitialized) {
            actions.loadPaymentMethods(planId)
        }
    }, [planId, state.paymentMethodsInitialized, actions])

    useEffect(() => {
        setters.setPlanId(planId)
        setters.setHandle(appState.applicationData.user.handle)
    }, [planId, setters, appState.applicationData.user.handle])

    useEffect(
        function negativeAmountPreviewGuard() {
            const previewLoaded = state.upgradePreview.loaded
            const noError = !state.hasError
            if (previewLoaded && noError) {
                if (state.upgradePreview.amount < 0) {
                    setters.setHasError('Negative amount preview')
                }
            }
        },
        [setters, state.hasError, state.upgradePreview.amount, state.upgradePreview.loaded]
    )

    return <div>{props.children}</div>
}
