import React, {useEffect, useState} from 'react'
import PaymentBox from '../../common/Transaction/Payment-Box/payment-box'
import {useModifyState} from '../Modify-Context/modify-context'
import {ModifySetters} from '../modify-setters'
export default function ModifyPayment(props: {planId: string; currency: string}) {
    const {actions, state, setter} = useModifyState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!state.payment.paymentMethodsInitialized) {
            setLoading(true)
            actions.loadPaymentMethods(props.planId).then((data) => {
                setter({type: ModifySetters.loadPaymentMethods, data})
                setLoading(false)
            })
        }
    }, [actions, setter, props.planId, state.payment.paymentMethodsInitialized])
    function resetPayment() {
        setter({type: ModifySetters.resetPaymentMethods, data: null})
    }
    function handlePaymentMethodId(id: string) {
        actions.loadPaymentMethods(props.planId).then((data) => {
            setter({type: ModifySetters.loadPaymentMethods, data})
            setter({type: ModifySetters.setPaymentMethodId, data: id})
        })
    }
    function selectPayment(id: string) {
        setter({type: ModifySetters.setPaymentMethodId, data: id})
    }
    return (
        <PaymentBox
            handlePaymentMethodId={handlePaymentMethodId}
            resetPayment={resetPayment}
            paymentMethodSelected={!!state.payment.paymentMethodId}
            currency={props.currency}
            existingPayments={state.payment.savedPayments}
            selectPayment={selectPayment}
            planId={props.planId}
            loading={loading}
        />
    )
}
