import React, {useState} from 'react'
import {api} from '../api/api'
import TextInput from '@pluralsight/ps-design-system-textinput'

export default function TransactionPermissions() {
    const [file, setFile] = useState('')
    const [notice, setNotice] = useState('')
    const [singlePlanNotice, setSinglePlanNotice] = useState('')
    const [planId, setPlanId] = useState()
    function onChangeHandler(event: any) {
        setFile(event.target.files[0])
    }

    const onClickHandler = async () => {
        setNotice('processing started')
        await api.postFile('internal-api/transaction-permissions/bulk-update', file)
        setNotice('Processing finished')
    }

    const handleChange = (event: any) => {
        setPlanId(event.target.value)
    }

    const postPlan = async () => {
        setSinglePlanNotice('processing started')
        await api.post(`internal-api/transaction-permissions/${planId}`)
        setSinglePlanNotice('Processing finished')
    }

    return (
        <div>
            <div
                style={{
                    marginBottom: 50,
                }}
            >
                <h2>Update single Plan</h2>
                <TextInput name='planid' onChange={handleChange} style={{marginRight: 50}} />
                <button disabled={!planId} type='button' onClick={postPlan}>
                    Post
                </button>
                {singlePlanNotice}
            </div>
            <div>
                <h2 style={{marginBottom: '20px'}}>Expand permission bulk file upload</h2>
                <div>
                    <input type='file' name='file' onChange={onChangeHandler} />
                    <button disabled={!file} type='button' onClick={onClickHandler}>
                        Post
                    </button>
                </div>
                {notice}
            </div>
        </div>
    )
}
