import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import PsContactInfo from './ps-contact-info/Ps-Contact-Info'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {ParamTypes} from '../../../definitions/param-types'
import {useAppContext} from '../useAppState'

export default function Accounts() {
    const {planId} = useParams<ParamTypes>()
    const {state, actions} = useAppContext()
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    useEffect(() => {
        if (!!planId) {
            actions.loadPluralsightContact(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        const contactWrapper = state.pluralsightContactMap[planId]
        const contactLoaded = contactWrapper && contactWrapper.loaded
        if (contactLoaded && planId) {
            setShowLoadingDecorator(false)
        }
    }, [planId, state.pluralsightContactMap])

    const contact = state.pluralsightContactMap[planId]?.data

    return (
        <>
            {!showLoadingDecorator ? (
                <>
                    <PsContactInfo PluralsightContact={contact} />
                </>
            ) : (
                <CenteredLoadingDecorator />
            )}
        </>
    )
}
