import styles from './Invoice-Download-Icon.module.scss'
import {DownloadIcon} from '@pluralsight/ps-design-system-icon'
import React from 'react'

export function InvoiceDownloadIcon() {
    return (
        <div className={styles.iconColor}>
            <DownloadIcon size={DownloadIcon.sizes.small} />
        </div>
    )
}
