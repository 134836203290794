import * as React from 'react'
import {createContext, useContext, useReducer} from 'react'
import {api} from '../../api/api'
import {PromotionConfigurationSetters} from './promotion-configuration-setters'
import {PromotionConfig} from '../../../../definitions/promotion-config'

const PromotionConfigurationContext = createContext({})

export function PromotionStateProvider(props: any) {
    const initialState: PromotionConfigurationState = {
        configurations: [],
    }
    const PromotionConfigurationStateReducer = (
        state: PromotionConfigurationState,
        action: {type: string; data: any}
    ) => {
        const {loadConfigurations} = PromotionConfigurationSetters
        switch (action.type) {
            case loadConfigurations:
                return {
                    ...state,
                    configurations: action.data,
                }
            default:
                throw new Error()
        }
    }

    const getPromotionConfigurationActions: PromotionConfigurationActions = React.useMemo(() => {
        return {
            loadConfigurations: () => {
                return api.get('internal-api/promo-config').then(validateResponse)
            },
            updateConfiguration: (promotionConfig: PromotionConfig) => {
                return api.put(`internal-api/promo-config/${promotionConfig.id}`, promotionConfig)
            },
            createConfiguration: (promotionConfig: PromotionConfig) => {
                return api.post(`internal-api/promo-config/`, promotionConfig)
            },
            deleteConfiguration: (id: number) => {
                return api.del(`internal-api/promo-config/${id}`)
            },
        }
    }, [])

    function validateResponse(response: any) {
        if (response.status.success) {
            return response.data
        } else {
            throw new Error(response.status.errorMessage)
        }
    }

    const context = {
        reducer: useReducer(PromotionConfigurationStateReducer, initialState),
        actions: getPromotionConfigurationActions,
    }
    return (
        <PromotionConfigurationContext.Provider value={context}>
            {props.children}
        </PromotionConfigurationContext.Provider>
    )
}

export function usePromotionConfigurationState(): {
    state: PromotionConfigurationState
    setter: any
    actions: PromotionConfigurationActions
} {
    const {reducer, actions} = useContext(PromotionConfigurationContext) as any
    const [state, setter] = reducer
    return {state, setter, actions}
}

interface PromotionConfigurationState {
    configurations: PromotionConfig[]
}

interface PromotionConfigurationActions {
    loadConfigurations: () => Promise<PromotionConfig[]>
    updateConfiguration: (promotionConfig: PromotionConfig) => Promise<any>
    createConfiguration: (promotionConfig: PromotionConfig) => Promise<any>
    deleteConfiguration: (id: number) => Promise<any>
}
