import * as React from 'react'
import {useEffect} from 'react'
import {RowHeader} from '../../common/Row-Header/Row-Header'
import {useRenewState} from '../Renew-Context/renew-context'
import {RenewSetters} from '../renew-setters'
import '../styles/renew-payment.scss'
import PaymentMethods from './payment-methods'
export default function Payment() {
    const {state, setter} = useRenewState()
    const paymentSelected = !!state.payment.paymentMethodId
    useEffect(() => {}, [paymentSelected])
    function resetPayment() {
        setter({type: RenewSetters.resetPaymentMethods, data: null})
    }
    return (
        <div className='paymentBox'>
            <RowHeader
                headerText='Payment'
                active={!paymentSelected}
                complete={paymentSelected}
                stepNumber={1}
                resetToStep={resetPayment}
            />
            {!paymentSelected && <PaymentMethods />}
        </div>
    )
}
