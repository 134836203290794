import React, {useEffect, useState} from 'react'
import TextInput from '@pluralsight/ps-design-system-textinput'
import Button from '@pluralsight/ps-design-system-button'
import Form from '@pluralsight/ps-design-system-form'
import Theme from '@pluralsight/ps-design-system-theme'
import Banner from '@pluralsight/ps-design-system-banner'

import './promotion-configuration-editor.scss'
import {usePromotionConfigurationState} from './promotion-configuration-context'
import {useHistory, useParams} from 'react-router-dom'
import {PromosHeader, PromoRow} from './promotion-table-components'
import Table from '@pluralsight/ps-design-system-table'
import {PromotionConfig} from '../../../../definitions/promotion-config'
import {DateRange, DateRangeCalendar} from './date-range-calendar'
import {PromotionConfigurationSetters} from './promotion-configuration-setters'

export function PromotionConfigurationEditor() {
    const {actions, setter} = usePromotionConfigurationState()
    const history = useHistory()
    const {id} = useParams<{id: string}>()

    const promotionConfigurationState = usePromotionConfigurationState()

    const [changes, setChanges] = useState<Partial<PromotionConfig>>({})
    const [dateRange, setDateRange] = useState<DateRange>()
    const [error, setError] = useState<string | null>(null)
    const promo = getPromo(id)

    useEffect(() => {
        if (!!dateRange) {
            setChanges((c) => {
                return {...c, startTime: dateRange.start, endTime: dateRange.end}
            })
        }
    }, [dateRange, setChanges])

    function getPromo(id: string): PromotionConfig {
        if (id === 'new') {
            return {
                id: -1,
                ctaText: 'WOW, MUCH DISCOUNT',
                promoLanding: 'offer/2021/q2-33-off-3-day',
                startTime: new Date().toISOString(),
                endTime: new Date().toISOString(),
            }
        } else {
            const configId = Number(id)
            const configurations = promotionConfigurationState.state.configurations
            const promo = configurations.find((p) => p.id === configId)
            if (promo) {
                return promo
            } else {
                throw new Error(`Cannot find promo for id: ${configId}`)
            }
        }
    }

    function updateConfig() {
        actions.updateConfiguration({...promo, ...changes} as PromotionConfig).then((res) => {
            if (res.status.success) {
                actions.loadConfigurations().then((configurations) => {
                    setter({
                        type: PromotionConfigurationSetters.loadConfigurations,
                        data: configurations,
                    })
                })
            } else {
                throw new Error(res.status.errorMessage)
            }
        })
    }

    function createConfig() {
        const hasStartTime = !!changes.startTime
        const hasEndTime = !!changes.endTime
        if (!hasStartTime || !hasEndTime) {
            setError('Must have start and end dates')
        } else {
            const baseConfig: PromotionConfig = {
                id: -1,
                ctaText: null,
                promoLanding: null,
                startTime: '',
                endTime: '',
            }
            const newConfig = {...baseConfig, ...changes}
            actions.createConfiguration(newConfig).then((res) => {
                if (res.status.success) {
                    actions.loadConfigurations().then((configurations) => {
                        setter({
                            type: PromotionConfigurationSetters.loadConfigurations,
                            data: configurations,
                        })
                        returnToConfigs()
                    })
                } else {
                    throw new Error(res.status.errorMessage)
                }
            })
        }
    }

    function deleteConfig() {
        actions.deleteConfiguration(promo.id).then(() => {
            returnToConfigs()
        })
    }

    return (
        <Theme>
            <div className='edit-promo'>
                <Table>
                        <PromosHeader />
                        <PromoRow promotion={promo} />
                </Table>

                <div className='edit-section'>
                    <Form.VerticalLayout>
                        <TextInput
                            onChange={(e) =>
                                setChanges({...changes, ctaText: e.currentTarget.value})
                            }
                            label='Call to action'
                        />
                        <TextInput
                            onChange={(e) =>
                                setChanges({...changes, promoLanding: e.currentTarget.value})
                            }
                            label='Promo landing'
                        />
                        <DateRangeCalendar handleSelection={setDateRange} />

                        <div>{!!error && <Banner color={Banner.colors.red}>{error}</Banner>}</div>
                        <div className='promo-actions'>
                            <Button
                                appearance={Button.appearances.secondary}
                                onClick={returnToConfigs}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{backgroundColor: '#D21C09'}}
                                disabled={promo.id === -1}
                                appearance={Button.appearances.secondary}
                                onClick={() => deleteConfig()}
                            >
                                Delete
                            </Button>
                            {promo.id === -1 ? (
                                <Button onClick={() => createConfig()}>Create</Button>
                            ) : (
                                <Button onClick={() => updateConfig()}>Update</Button>
                            )}
                        </div>
                    </Form.VerticalLayout>
                </div>
            </div>
        </Theme>
    )

    function returnToConfigs() {
        history.push('../promotion-config')
    }
}
