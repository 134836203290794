import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {ForbiddenErrorPage} from '@pluralsight/ps-design-system-errors'
import {useAppContext} from '../../useAppState'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'

export function SetDefaultPlan() {
    const history = useHistory()
    const {state} = useAppContext()
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)

    useEffect(redirectToDefaultPlan, [history, state.applicationData.planInfo.admin])

    function redirectToDefaultPlan() {
        const firstPlanId =
            state.applicationData.planInfo.admin.length > 0 &&
            state.applicationData.planInfo.admin[0]
        if (!!firstPlanId) {
            history.push(`/subscription/plans/${firstPlanId}`)
        } else {
            setShowLoadingDecorator(false)
        }
    }

    return (
        <>
            {showLoadingDecorator ? (
                <CenteredLoadingDecorator />
            ) : (
                <ForbiddenErrorPage size={ForbiddenErrorPage.sizes.large} />
            )}
        </>
    )
}
