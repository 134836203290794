import React, {useEffect, useState} from 'react'
import Link from '@pluralsight/ps-design-system-link'
import UpmcComponent from '../upmc/Upmc-Component'
import styles from './payment-methods-css-layouts.module.scss'
import {useParams} from 'react-router-dom'
import {useAppContext} from '../../useAppState'
import {createPaypalMethod} from './Payments-Data/create-paypal-method/create-paypal-method'
import {setDefaultPaymentMethod} from './Payments-Data/Set-Default-Payment-Method/set-default-payment-method'
import {ParamTypes} from '../../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'

export function AddNewPaymentMethod(prop: any) {
    const {state, setters, actions} = useAppContext()
    const [isOpenUpmc, toggle] = useState(false)
    const [loading, setLoading] = useState(true)
    const {planId} = useParams<ParamTypes>()
    const [currency, setCurrency] = useState('')
    useEffect(() => {
        const account = state.businessAccountMap[planId]
        if (!!account && account.loaded) {
            setCurrency(account.data.billingInfo.currency)
            setLoading(false)
        }
    }, [planId, state.businessAccountMap])

    useEffect(() => {
        if (!!planId) {
            actions.loadBusinessAccount(planId)
        }
    }, [planId, actions])

    function toggleModal() {
        toggle(!isOpenUpmc)
    }
    function onSuccess(successMessage: CreditCardSuccess | PaypalSuccess) {
        setters.setUpdatePaymentsFlag(true)
        if (successMessage.paymentMethodType === 'PayPal') {
            const paypalMethod = {
                paypalBaid: successMessage.paypalBaid,
                paypalEmail: successMessage.paypalEmail,
                address: `${successMessage.address.address1} ${successMessage.address.address2}`,
                city: successMessage.address.city,
                state: successMessage.address.state,
                country: successMessage.address.country,
                zipCode: successMessage.address.postal,
            }
            createPaypalMethod(
                state.applicationData.featureConfig.useMulesoftPaypalCreationFlag,
                planId,
                paypalMethod
            ).then((res: any) => {
                if (res.status.success) {
                    setters.setUpdatePaymentsFlag(false)
                }
            })
        } else {
            setDefaultPaymentMethod(successMessage.paymentMethodId, planId).then((res: any) => {
                if (res.status.success) {
                    setters.setUpdatePaymentsFlag(false)
                }
            })
        }
    }

    return (
        <>
            {loading ? (
                <CenteredLoadingDecorator />
            ) : (
                <div>
                    <div aria-hidden={isOpenUpmc}>
                        {prop.savedPayment ? (
                            <Link className={styles.buttonText}>
                                <button onClick={toggleModal}>Add a new payment Method</button>
                            </Link>
                        ) : (
                            <div style={{marginTop: '10px'}}>
                                <UpmcComponent
                                    planId={planId as string}
                                    currency={currency}
                                    handle={state.applicationData.user.handle}
                                    onSuccess={onSuccess}
                                    cta={'Add payment method'}
                                />
                            </div>
                        )}
                    </div>
                    {isOpenUpmc && (
                        <div style={{marginTop: '20px'}}>
                            <UpmcComponent
                                planId={planId as string}
                                currency={currency}
                                handle={state.applicationData.user.handle}
                                onSuccess={onSuccess}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
