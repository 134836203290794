import React, {useState, useEffect} from 'react'
import Banner from '@pluralsight/ps-design-system-banner'

export default function UPMCFatalError(props: {fatalError: string}) {
    const [displayBanner, setDisplayBanner] = useState(false)
    useEffect(() => {
        if (!!props.fatalError) {
            setDisplayBanner(true)
        }
    }, [props.fatalError])
    function closeBanner() {
        setDisplayBanner(false)
    }
    return (
        <>
            {displayBanner && (
                <Banner color={Banner.colors.red} onClick={closeBanner}>
                    {props.fatalError}
                </Banner>
            )}
        </>
    )
}
