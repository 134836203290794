import React from 'react'
import TransactionSuccessPage from '../../common/Transaction/Transaction-Success-Page/transaction-success-page'
import {useAppContext} from '../../useAppState'
import {useModifyState} from '../Modify-Context/modify-context'
import MofidyOrderReceipt from './modify-order-receipt'

export default function ThankYouModify() {
    const {state: appState} = useAppContext()
    const {state} = useModifyState()
    return (
        <TransactionSuccessPage
            firstName={appState.applicationData.user.firstName}
            email={appState.applicationData.user.email}
            message={
                <>
                    <b>{state.result.data.lineItems[0].productName}</b> was successfully added.
                </>
            }
            orderSummary={<MofidyOrderReceipt />}
        />
    )
}
