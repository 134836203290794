import React from 'react'
import {useUpgradeContext} from './Upgrade-Context'
import {ReviewConfirm, ReviewConfirmMobile} from '../common/Review-Confirm/Review-Confirm'
import {UpgradeTermsAndConditions} from './Upgrade-Terms-And-Conditions'

export function UpgradeReviewConfirm() {
    let {state, actions} = useUpgradeContext()
    let payLoad = {paymentMethodId: state.paymentMethodId, cartId: state.cartId}

    return (
        <>
            <ReviewConfirm
                isDisabled={!state.cartId}
                isOpen={state.isReviewConfirmOpen}
                isActive={state.isReviewConfirmOpen}
                isComplete={false}
                stepNumber={2}
                purchase={() => {
                    actions.postCheckout(payLoad)
                }}
                terms={UpgradeTermsAndConditions}
            />
        </>
    )
}

export function UpgradeReviewConfirmMobile() {
    let {state, actions} = useUpgradeContext()
    let payLoad = {paymentMethodId: state.paymentMethodId, cartId: state.cartId}

    return (
        <ReviewConfirmMobile
            isOpen={state.isReviewConfirmOpen}
            isActive={state.isReviewConfirmOpen}
            isComplete={false}
            stepNumber={2}
            purchase={() => {
                actions.postCheckout(payLoad)
            }}
            autoRenewDate={state.currentSubscription.subscriptionEndDate}
            productName={state.currentSubscription.productName}
            terms={UpgradeTermsAndConditions}
        />
    )
}
