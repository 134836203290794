import React from 'react'
import {onlyAcceptNumbers} from '../utils/only-accept-numbers'
import styles from './Amount-Picker.module.scss'
import {CaretDownIcon, CaretUpIcon, CodeIcon} from '@pluralsight/ps-design-system-icon'

export function AmountPicker(props: {
    numberOfLicences: number
    increase: () => void
    decrease: () => void
    setAmount: (quantity: number) => void
}) {
    function validateNumber(quantityString: string): number {
        const possibleQuantity = parseInt(quantityString)
        return isNaN(possibleQuantity) ? 0 : possibleQuantity
    }

    return (
        <div className={styles.amountPicker}>
            <button
                className={styles.carrotButton}
                onClick={() => props.decrease()}
                disabled={props.numberOfLicences <= 1}
            >
                <CaretDownIcon
                    size={CodeIcon.sizes.xSmall}
                    color={CodeIcon.colors.textIconHighOnDark}
                />
            </button>
            <input
                className={styles.input}
                aria-label={'Add subscriptions'}
                name='subscription quantity'
                onKeyDown={onlyAcceptNumbers}
                onChange={(event) => props.setAmount(validateNumber(event.target.value))}
                value={props.numberOfLicences === 0 ? '' : props.numberOfLicences}
                type={'text'}
                onBlur={(e) => props.setAmount(Number(e.target.value))}
            />
            <button className={styles.carrotButton} onClick={() => props.increase()}>
                <CaretUpIcon
                    size={CodeIcon.sizes.xSmall}
                    color={CodeIcon.colors.textIconHighOnDark}
                />
            </button>
        </div>
    )
}
