import * as React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import Renew from './renew'
import {useRenewState} from './Renew-Context/renew-context'
import {RenewSetters} from './renew-setters'

export default function RenewGuardV2() {
    const {state, actions, setter} = useRenewState()
    const {planId} = useParams<ParamTypes>()
    useEffect(() => {
        if (state.renewGuard.loadingGuard) {
            actions.canRenew(planId).then((data) => {
                setter({type: RenewSetters.setRenewGuard, data})
            })
        }
    }, [state.renewGuard.loadingGuard, actions, setter, planId])
    return (
        <>
            {/* {!state.renewGuard.loadingGuard ? (
                <CenteredLoadingDecorator centerVertically={true} />
            ) : !state.renewGuard.canRenew ? ( */}
            <Renew />
            {/* ) : (
                <BlockedRenewRoutes
                    errorMessage={state.renewGuard.reason}
                    data={state.renewGuard.data}
                    planId={planId}
                />
            )} */}
        </>
    )
}
