import {useExpandContext} from './Expand-Context'
import React from 'react'
import styles from './Expand-Terms-And-Conditions.module.scss'

export function ExpandTermsAndConditions(props: {lowOnDark?: boolean}) {
    const taxText =
        '✝✝ Pluralsight is required by law to collect sales tax on purchases in some states. The actual tax amount will be calculated based on the applicable state and local sales tax when your order is processed.'

    function getAutoRenewText(productName: string, renewDate: string) {
        return `✝ Price has been pro-rated for the remainder of your plan, based on the agreed upon price per subscription. All ${productName} subscriptions for this plan will renew on ${renewDate}, unless you cancel before then.`
    }
    let {state} = useExpandContext()
    return (
        <div className={`${styles.termsAndConditions} ${props.lowOnDark ? styles.lowOnDark : ''}`}>
            <div>
                {getAutoRenewText(
                    state.currentSubscription.productName,
                    state.currentSubscription.formattedAutoRenewDate
                )}
            </div>
            <div>{taxText}</div>
        </div>
    )
}
