import React, {useState} from 'react'
import Button from '@pluralsight/ps-design-system-button'
import Link from '@pluralsight/ps-design-system-link'
import {config} from '../environment-config'
import {Heading} from '@pluralsight/ps-design-system-text'
import Checkbox from '@pluralsight/ps-design-system-checkbox'
import TextInput from '@pluralsight/ps-design-system-textinput'
import {api} from '../api/api'
import {ApiContext} from '../../../definitions/api'
import styles from './B2b-data-capture.module.scss'

export default function B2bDataCapture() {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [state, setState] = useState({
        checkboxes: {
            'A Cloud Guru subscription': false,
            'Advanced video library': false,
            Labs: false,
            Sandboxes: false,
            Conferences: false,
        },
        textInputs: {
            topics: '',
        },
    })
    const businessSkills = `${config.www.url}/pricing/skills?type=business`
    const appLibrary = `${config.people.url}/library`

    const handleCheckbox = (evt: any, checked: boolean, value: string) => {
        setState((state) => {
            const checkboxes = {...state.checkboxes, [value]: checked}
            return {...state, checkboxes}
        })
    }

    const handleTextInput = (name: 'topics') => {
        return (evt: any) => {
            evt.persist()
            setState((state) => {
                const textInputs = {...state.textInputs, [name]: evt.target.value}
                return {...state, textInputs}
            })
        }
    }

    const getNotes = () => {
        const notes: string[] = []
        Object.keys(state.checkboxes).forEach((key) => {
            if ((state.checkboxes as any)[key]) {
                notes.push(key)
            }
        })
        Object.keys(state.textInputs).forEach((key) => {
            const topicValue = (state.textInputs as any)[key]
            if (!!topicValue) {
                notes.push(topicValue)
            }
        })
        return notes
    }

    const handleSubmit = () => {
        const notes = getNotes()
        setFormSubmitted(true)
        api.post(`b2b/upgrade-landing/data-capture`, {notes}).then((res: ApiContext<void>) => {
            if (!res.status.success) {
                throw new Error(res.status.errorMessage)
            }
        })
    }

    return !formSubmitted ? (
        <div className={styles.pageLayout}>
            <div className={styles.messageLayout}>
                <Heading
                    className={styles.header}
                    size={Heading.sizes.medium}
                    color={Heading.colors.primary}
                >
                    <h2>Interested in more learning content? </h2>
                </Heading>
                <div className={styles.subheader}>
                    <div>
                        Please share what would be most valuable for your skill development journey.
                    </div>
                    <div className={styles.subheaderBold}>Choose one or more of the following:</div>
                </div>
                <form className={styles.form}>
                    {Object.keys(state.checkboxes).map(fieldCheckbox)}
                    <div className={styles.inputWrapper}>
                        <TextInput
                            className={styles.topicsField}
                            value={state.textInputs.topics}
                            onChange={handleTextInput('topics')}
                            label='Or share any interest in specific topics or courses'
                            placeholder='What topics or courses are you interested in?'
                        />
                    </div>
                </form>
                <div>
                    <Button onClick={handleSubmit}>
                        <span className={styles.textStyles}>Share your interest</span>
                    </Button>
                </div>
                <div className={styles.linkWrapper}>
                    <Link>
                        <a href={businessSkills} className={styles.textStyles}>
                            Learn more about plans and pricing
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    ) : (
        <div className={styles.pageLayout}>
            <div className={styles.messageLayout}>
                <Heading
                    size={Heading.sizes.medium}
                    className={styles.header}
                    color={Heading.colors.primary}
                >
                    <h2>Way to go! We've recorded your interests.</h2>
                </Heading>
                <div>
                    <Button href={appLibrary}>
                        <span className={styles.textStyles}>Back to Skills library</span>
                    </Button>
                </div>
            </div>
        </div>
    )

    function fieldCheckbox(field: string) {
        return (
            <Checkbox
                onCheck={handleCheckbox}
                checked={(state.checkboxes as any)[field]}
                key={field}
                label={field}
                value={field}
                name={field}
            />
        )
    }
}
