import React, {useState} from 'react'
import UpmcComponent from '../../../Billing/upmc/Upmc-Component'
import {useAppContext} from '../../../useAppState'
import {ExistingPaymentMethodsSelector} from '../../Existing-Payment-Method-Selecttor/Existing-Payment-Method-Selector'
import {PaymentMethod} from '../../../../../definitions/payment-methods'
import {CenteredLoadingDecorator} from '../../../Loading-Decorator/Centered-Loading-Decorator'
export default function PaymentMethods(props: {
    planId: string
    currency: string
    selectPayment: (id: string) => any
    existingPayments: PaymentMethod[]
    handlePaymentMethodId: (id: string) => any
}) {
    const [processingPayment, setProcessPayment] = useState(false)
    const {state: appState} = useAppContext()
    const upmcHeadderText = props.existingPayments
        ? 'Or add a new payment method'
        : 'Add a new Payment Method'
    function processPayment(process: boolean) {
        setProcessPayment(process)
    }
    return (
        <>
            {!processingPayment ? (
                <div className='content'>
                    <ExistingPaymentMethodsSelector
                        selectedPaymentId=''
                        existingPaymentMethods={props.existingPayments}
                        selectFunction={props.selectPayment}
                    />
                    <div style={{margin: '30px 0px'}}>
                        <div style={{marginBottom: '20px'}}>{upmcHeadderText}</div>
                        <UpmcComponent
                            handle={appState.applicationData.user.handle}
                            planId={props.planId}
                            currency={props.currency}
                            handlePaymentMethodId={props.handlePaymentMethodId}
                            authAmount={1}
                            processPayment={processPayment}
                        />
                    </div>
                </div>
            ) : (
                <CenteredLoadingDecorator />
            )}
        </>
    )
}
