import React, {createContext, useContext, useMemo, useState} from 'react'
import {api} from '../api/api'
import {
    ExpandErrorData,
    ExpandErrorMessage,
    ExpandGuardResponse,
    ExpandPayload,
    ExpandPreviewPayload,
    ExpandResponse,
} from '../../../definitions/expand'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {ExpandAnalyticsEvents} from './expand-analytics-events'
import {track} from '../analytics'
import {useAsyncError} from '../useAsyncError'

const ExpandState = (props: any) => {
    const activateErrorBoundary = useAsyncError()
    const initialState = {
        isQuantityOpen: true,
        isQuantityComplete: false,
        isPaymentOpen: false,
        isPaymentComplete: false,
        isReviewConfirmOpen: false,
        isMobileOrderSummaryOpen: false,
        quantityToAdd: 1,
        hasAgreedToTerms: false,
        paymentMethodId: '',
        existingPaymentMethods: [],
        paymentMethodsInitialized: false,
        planId: '',
        handle: '',
        hasError: false,
        errorMessage: '',
        guardData: undefined,
        currentSubscription: {
            isLoaded: false,
            currentSubscriptionQuantity: 0,
            currency: '',
            productId: '',
            formattedAutoRenewDate: '',
        },
        guardResponse: null,
        expandPreview: {
            amount: 0,
            amountWithoutTax: 0,
            currency: '',
            pricePerLicense: 0,
            taxAmount: 0,
            isLoading: true,
        },
        expand: {
            amount: 0,
            amountWithoutTax: 0,
            currency: '',
            pricePerLicense: 0,
            taxAmount: 0,
            loaded: false,
            pending: false,
        },
    }

    const useExpandState = () => {
        const [state, setState] = useState(!!props.initialState ? props.initialState : initialState)
        const setters = useMemo<Setters>(() => getSetters(setState), [setState])
        const [actions] = useState(getActions(setState))
        return {state, actions, setters}
    }

    const getSetters: (setState: any) => Setters = (setState: any) => ({
        setPlanId: (planId: string | undefined) => {
            setState((state: any) => ({
                ...state,
                planId,
            }))
        },
        setQuantity: (quantityToAdd: number) => {
            if (quantityToAdd >= 0) {
                setState((state: any) => ({
                    ...state,
                    quantityToAdd: quantityToAdd,
                }))
            }
        },
        setPaymentMethodsInitialized: (paymentMethodsInitialized: boolean) => {
            setState((state: any) => ({
                ...state,
                paymentMethodsInitialized,
            }))
        },
        setPaymentMethodId: (paymentMethodId: string) => {
            setState((state: any) => ({
                ...state,
                paymentMethodId,
            }))
        },
        setExistingPaymentMethods: (existingPaymentMethods: any) => {
            setState((state: any) => ({
                ...state,
                existingPaymentMethods,
            }))
        },
        setIsQuantityOpen: (isQuantityOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isQuantityOpen,
            }))
        },
        setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isReviewConfirmOpen,
            }))
        },
        setIsPaymentOpen: (isPaymentOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isPaymentOpen,
            }))
        },
        setIsPaymentComplete: (isPaymentComplete: boolean) => {
            setState((state: any) => ({
                ...state,
                isPaymentComplete,
            }))
        },
        setHasAgreedToTerms: (hasAgreedToTerms: boolean) => {
            setState((state: any) => ({
                ...state,
                hasAgreedToTerms,
            }))
        },
        setPreviewLoading: () => {
            setState((state: any) => ({
                ...state,
                expandPreview: {
                    ...state.expandPreview,
                    isLoading: true,
                },
            }))
        },
        setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isMobileOrderSummaryOpen,
            }))
        },
        setHandle: (handle: string) => {
            setState((state: any) => ({
                ...state,
                handle,
            }))
        },
        setCurrentSubscription: (currentSubscription: ExpandBusinessSubscriptionData) => {
            setState((state: any) => ({
                ...state,
                currentSubscription,
            }))
        },
        setHasError(errorMessage: ExpandErrorMessage) {
            setState((state: any) => ({
                ...state,
                hasError: true,
                errorMessage: errorMessage,
            }))
        },
    })

    const getActions: (setState: any) => Actions = (setState: any) => ({
        loadPaymentMethods(planId: string) {
            api.get(`plans/${planId}/payment-methods`).then((res) => {
                if (res.status.success) {
                    setState((state: any) => ({
                        ...state,
                        existingPaymentMethods: res.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    }))
                }
            })
        },
        expandGuardCheck(planId: string) {
            api.get(`plans/${planId}/expand-guard`).then((res) => {
                if (res.status.success) {
                    const guardResponse: ExpandGuardResponse = res.data
                    setState((state: ExpandState) => {
                        return {
                            ...state,
                            hasError: !guardResponse.canExpand,
                            errorMessage: guardResponse.canExpand ? '' : guardResponse.message,
                            guardResponse: guardResponse,
                            guardData: guardResponse.canExpand ? undefined : guardResponse.data,
                        }
                    })
                } else {
                    throw Error(res.status.errorMessage)
                }
            })
        },
        expandPreview(planId: string, previewPayload: ExpandPreviewPayload) {
            api.post(`plans/${planId}/expand-preview`, previewPayload).then((res) => {
                if (res.status.success) {
                    if (res.data.pricePerLicense <= 0) {
                        setState((state: ExpandState) => ({
                            ...state,
                            hasError: true,
                            errorMessage: 'product price 0 or less',
                        }))
                    } else {
                        setState((state: ExpandState) => ({
                            ...state,
                            expandPreview: {...res.data, isLoading: false},
                        }))
                    }
                } else {
                    setState((state: ExpandState) => ({
                        ...state,
                        hasError: true,
                        errorMessage: res.status.errorMessage,
                        expandPreview: {
                            ...state.expandPreview,
                            isLoading: false,
                        },
                    }))
                }
            })
        },
        postExpand(currentState: ExpandState) {
            setState((state: ExpandState) => ({
                ...state,
                expand: {
                    loaded: false,
                    pending: true,
                },
            }))
            const expandPayload: ExpandPayload = {
                licensesToAdd: currentState.quantityToAdd,
                invoiced: false,
                paymentMethodId: currentState.paymentMethodId,
                currentLicenseCount: currentState.currentSubscription.currentSubscriptionQuantity,
            }
            return api
                .post(`plans/${currentState.planId}/expand`, expandPayload)
                .then((res) => {
                    if (res.status.success) {
                        setState((state: ExpandState) => ({
                            ...state,
                            expand: {
                                ...res.data,
                                loaded: true,
                                pending: false,
                            },
                        }))
                        this.trackAnalyticsEvent(currentState, ExpandAnalyticsEvents.success)
                    } else {
                        setState((state: any) => ({
                            ...state,
                            hasError: true,
                        }))
                        this.trackAnalyticsEvent(currentState, ExpandAnalyticsEvents.failure)
                    }
                })
                .catch((e) => {
                    activateErrorBoundary(e)
                })
        },
        resetState() {
            setState((state: ExpandState) => ({
                ...initialState,
            }))
        },
        resetToQuantitySelection() {
            setState((state: ExpandState) => ({
                ...state,
                paymentMethodId: '',
                isQuantityOpen: true,
                isQuantityComplete: false,
                isPaymentOpen: false,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
        resetToPaymentSelection() {
            setState((state: ExpandState) => ({
                ...state,
                paymentMethodId: '',
                isQuantityOpen: false,
                isQuantityComplete: true,
                isPaymentOpen: true,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
        trackAnalyticsEvent(
            stateSnapshot: ExpandState,
            expandAnalyticsEvent: ExpandAnalyticsEvents,
            additionalData?: any
        ) {
            track(expandAnalyticsEvent, {
                currency: stateSnapshot.expandPreview.currency,
                productPrice: stateSnapshot.expandPreview.pricePerLicense,
                quantity: stateSnapshot.quantityToAdd,
                handle: stateSnapshot.handle,
                planId: stateSnapshot.planId,
                ...additionalData,
            })
        },
    })

    return (
        <ExpandContext.Provider value={useExpandState()}>{props.children}</ExpandContext.Provider>
    )
}

const ExpandContext = createContext({} as any)

const useExpandContext = () => {
    return useContext<{state: ExpandState; actions: Actions; setters: Setters}>(ExpandContext)
}

interface Setters {
    setPlanId: (planId: string | undefined) => void
    setHandle: (planId: string) => void
    setQuantity: (quantity: number) => void
    setIsQuantityOpen: (isQuantityOpen: boolean) => void
    setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => void
    setIsPaymentOpen: (isPaymentOpen: boolean) => void
    setIsPaymentComplete: (isPaymentComplete: boolean) => void
    setPaymentMethodId: (paymentMethodId: string) => void
    setHasAgreedToTerms: (agreeToTerms: boolean) => void
    setExistingPaymentMethods: (paymentMethods: any) => void
    setPaymentMethodsInitialized: (isInitialized: boolean) => void
    setPreviewLoading: () => void
    setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => void
    setCurrentSubscription: (currentSubscription: ExpandBusinessSubscriptionData) => void
    setHasError: (errorMessage: ExpandErrorMessage) => void
}

interface Actions {
    loadPaymentMethods: (planId: string) => void
    expandGuardCheck: (planId: string) => void
    expandPreview: (planId: string, expandPreviewPayload: ExpandPreviewPayload) => void
    postExpand: (currentState: ExpandState) => void
    resetState: () => void
    resetToQuantitySelection: () => void
    resetToPaymentSelection: () => void
    trackAnalyticsEvent: (
        stateSnapshot: ExpandState,
        expandAnalyticsEvent: ExpandAnalyticsEvents,
        additionalData?: any
    ) => void
}

// eslint-disable-next-line
interface ExpandState {
    quantityToAdd: number
    currentSubscription: ExpandBusinessSubscriptionData
    isQuantityOpen: boolean
    isQuantityComplete: boolean
    hasAgreedToTerms: boolean
    isPaymentOpen: boolean
    isPaymentComplete: boolean
    paymentMethodId: string
    existingPaymentMethods: PaymentMethod[]
    paymentMethodsInitialized: boolean
    isReviewConfirmOpen: boolean
    planId: string
    handle: string
    guardResponse: ExpandGuardResponse | null
    expandPreview: ExpandPreview
    isMobileOrderSummaryOpen: boolean
    expand: ExpandPost
    hasError: boolean
    errorMessage: ExpandErrorMessage
    guardData: ExpandErrorData
}

interface ExpandPreview extends ExpandResponse {
    isLoading: boolean
}

interface ExpandPost extends ExpandResponse {
    loaded: boolean
    pending: boolean
}

interface ExpandBusinessSubscriptionData {
    productName: string
    formattedAutoRenewDate: string
    isLoaded: boolean
    currentSubscriptionQuantity: number
    currency: string
}

export {ExpandState, useExpandContext}
