import React from 'react'
import {ReactComponent as Visa} from '../../images/visa_final.svg'
import {ReactComponent as MasterCard} from '../../images/mastercard.svg'
import styles from './payment-methods-css-layouts.module.scss'

export function getCreditCardDetails(creditCard: any) {
    return (
        <div className={styles.layoutDetails}>
            <div className={styles.image}>{IconSelector(creditCard.details.cardType)}</div>
            <div className={styles.contentText}>
                <div>
                    {creditCard.details.cardType} ending in{' '}
                    {creditCard.details.cardNumber.substr(-4)} | Expires{' '}
                    {creditCard.details.expirationMonth}/{creditCard.details.expirationYear}
                </div>
                <div style={{marginTop: '5px'}}>
                    {creditCard.details.cardHolderInfo.cardHolderName}
                </div>
                <div>
                    {creditCard.details.cardHolderInfo.addressLine1},{' '}
                    {creditCard.details.cardHolderInfo.city},{' '}
                    {creditCard.details.cardHolderInfo.state},{' '}
                    {creditCard.details.cardHolderInfo.zipCode},{' '}
                    {creditCard.details.cardHolderInfo.country}
                </div>
            </div>
        </div>
    )
}

function IconSelector(type: string) {
    if (type === 'Visa') {
        return <Visa />
    } else {
        //TODO
        return <MasterCard />
    }
}
