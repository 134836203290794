import * as React from 'react'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'
import UpmcComponent from '../../Billing/upmc/Upmc-Component'
import {ExistingPaymentMethodsSelector} from '../../common/Existing-Payment-Method-Selecttor/Existing-Payment-Method-Selector'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import {useAppContext} from '../../useAppState'
import {useRenewState} from '../Renew-Context/renew-context'
import {RenewSetters} from '../renew-setters'

export default function PaymentMethods() {
    const {state, actions, setter} = useRenewState()
    const {state: appState} = useAppContext()
    const {planId} = useParams<ParamTypes>()
    const [processingPaymentMethod, setProcessingPaymentMethod] = useState(false)
    useEffect(() => {
        actions.loadPaymentMethods(planId).then((data) => {
            setter({type: RenewSetters.loadPaymentMethods, data})
        })
    }, [actions, setter, planId, state.payment.paymentMethodsInitialized])
    function selectPayment(id: string) {
        setter({type: RenewSetters.setPaymentMethodId, data: id})
    }
    function onSuccess(successMessage: CreditCardSuccess | PaypalSuccess) {
        setProcessingPaymentMethod(true)
        if (successMessage.paymentMethodType === 'PayPal') {
            actions.addPaypalMethod(planId, successMessage).then((response) => {
                setter({type: RenewSetters.setPaymentMethodId, data: response.id})
                setProcessingPaymentMethod(false)
            })
        } else {
            setter({type: RenewSetters.setPaymentMethodId, data: successMessage.paymentMethodId})
            setProcessingPaymentMethod(false)
        }
    }
    function getUpmcHeaderText(existingPayments: boolean) {
        return existingPayments ? 'Or add a new payment method' : 'Add a new Payment Method'
    }
    return (
        <div className='content'>
            {!processingPaymentMethod ? (
                <div>
                    {!!state.payment.paymentMethodsInitialized ? (
                        <>
                            {state.payment.savedPayments &&
                                state.payment.savedPayments.length > 0 && (
                                    <ExistingPaymentMethodsSelector
                                        selectedPaymentId={
                                            !!state.payment.paymentMethodId
                                                ? state.payment.paymentMethodId
                                                : ''
                                        }
                                        existingPaymentMethods={state.payment.savedPayments}
                                        selectFunction={selectPayment}
                                    />
                                )}
                            <div style={{margin: '30px 0'}}>
                                <div style={{marginBottom: '20px'}}>
                                    {getUpmcHeaderText(
                                        state.payment.savedPayments &&
                                            state.payment.savedPayments.length > 0
                                    )}
                                </div>
                                <UpmcComponent
                                    handle={appState.applicationData.user.handle}
                                    planId={planId}
                                    currency={state.renew.data.currency}
                                    onSuccess={onSuccess}
                                    authAmount={1}
                                />
                            </div>
                        </>
                    ) : (
                        <CenteredLoadingDecorator />
                    )}
                </div>
            ) : (
                <CenteredLoadingDecorator />
            )}
        </div>
    )
}
