import * as React from 'react'
import {SubLifeAccountSwitcher} from '../../common/Sub-Life-Account-Switcher/Sub-Life-Account-Switcher'
import {DisplayNameMap, useAppContext} from '../../useAppState'
import {useState, useEffect} from 'react'
import styles from '../styles/renew.module.scss'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../../definitions/param-types'
import {useRenewState} from '../Renew-Context/renew-context'
import {RenewSetters} from '../renew-setters'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
export default function RenewAccountSwitcher() {
    const {planId} = useParams<ParamTypes>()
    const {state, actions} = useAppContext()
    const planIds = state.applicationData.planInfo.admin
    const [displayName, setDisplayName] = useState({} as DisplayNameMap)
    const [hasDisplayName, setHasDisplayName] = useState(false)
    const {setter} = useRenewState()
    useEffect(() => {
        async function getDisplayNames() {
            await actions.loadPlanDisplayName(planIds)
            setDisplayName(state.planDisplayNames)
            setHasDisplayName(true)
        }
        getDisplayNames()
    }, [state.planDisplayNames, actions, planIds])
    function resetRenew() {
        setter({type: RenewSetters.resetRenew})
    }
    return (
        <div className={styles.rowLayout}>
            <h2 className={styles.cell1}>Renew Plan</h2>
            <>
                {!hasDisplayName ? (
                    <CenteredLoadingDecorator />
                ) : (
                    <div className={styles.cell2}>
                        <SubLifeAccountSwitcher
                            planIds={planIds}
                            currentPlanId={planId}
                            displayName={displayName}
                            clearData={resetRenew}
                            disabled={true}
                        />
                    </div>
                )}
            </>
        </div>
    )
}
