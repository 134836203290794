import React from 'react'
import {PaymentMethod} from '../../../../../definitions/payment-methods'
import {CenteredLoadingDecorator} from '../../../Loading-Decorator/Centered-Loading-Decorator'
import {RowHeader} from '../../Row-Header/Row-Header'
import PaymentMethods from './payments'
export default function PaymentBox(props: {
    paymentMethodSelected: boolean
    resetPayment: () => any
    planId: string
    handlePaymentMethodId: (id: string) => any
    currency: string
    existingPayments: PaymentMethod[]
    selectPayment: (id: string) => any
    loading: boolean
}) {
    return (
        <div className='paymentBox'>
            <RowHeader
                headerText='Payment'
                active={!props.paymentMethodSelected}
                complete={props.paymentMethodSelected}
                stepNumber={1}
                resetToStep={props.resetPayment}
            />
            {!props.paymentMethodSelected && (
                <>
                    {props.loading ? (
                        <CenteredLoadingDecorator />
                    ) : (
                        <PaymentMethods
                            planId={props.planId}
                            currency={props.currency}
                            existingPayments={props.existingPayments}
                            selectPayment={props.selectPayment}
                            handlePaymentMethodId={props.handlePaymentMethodId}
                        />
                    )}
                </>
            )}
        </div>
    )
}
