export const STARTER_SKU = 'Starter-SKU'
export const PROFESSIONAL_SKU = 'BUS-PROF'
export const ENTERPRISE_SKU = 'BUS-ENT'

export const STARTER_BUNDLE_SKU = 'BUS-STARTER-BUNDLE'
export const PROFESSIONAL_BUNDLE_SKU = 'BUS-PROF-BUNDLE'
export const ENTERPRISE_BUNDLE_SKU = 'BUS-ENT-BUNDLE'

export function getUpgradeSku(sku: string) {
    switch (sku) {
        case STARTER_SKU:
            return PROFESSIONAL_SKU
        case PROFESSIONAL_SKU:
            return ENTERPRISE_SKU
        default:
            throw new Error(`Sku ${sku} is invalid for updgrade`)
    }
}

export function getBundleSku(sku: string) {
    switch (sku) {
        case STARTER_SKU:
            return STARTER_BUNDLE_SKU
        case PROFESSIONAL_SKU:
            return PROFESSIONAL_BUNDLE_SKU
        case ENTERPRISE_SKU:
            return ENTERPRISE_BUNDLE_SKU
        default:
            throw new Error(`Sku ${sku} is invalid for bundle`)
    }
}
