import React, {createContext, useContext, useMemo, useState} from 'react'
import {api} from '../api/api'
import {
    ExpandErrorData,
    ExpandErrorMessage,
    ExpandGuardResponse,
    ExpandResponseV2,
} from '../../../definitions/expand'
import {PaymentMethod} from '../../../definitions/payment-methods'
import {ExpandAnalyticsEvents} from './expand-analytics-events'
import {track} from '../analytics'
import {useAsyncError} from '../useAsyncError'
import {ExpandState} from '../expand/Expand-Context'
import {ExpandPayloadV2} from '../../../definitions/renew'

const ExpandStateV2 = (props: any) => {
    const activateErrorBoundary = useAsyncError()
    const initialState = {
        isQuantityOpen: true,
        isQuantityComplete: false,
        isPaymentOpen: false,
        isPaymentComplete: false,
        isReviewConfirmOpen: false,
        isMobileOrderSummaryOpen: false,
        quantityToAdd: 1,
        hasAgreedToTerms: false,
        paymentMethodId: '',
        existingPaymentMethods: [],
        paymentMethodsInitialized: false,
        planId: '',
        handle: '',
        hasError: false,
        errorMessage: '',
        guardData: undefined,
        currentSubscription: {
            isLoaded: false,
            currency: '',
            productId: '',
            formattedAutoRenewDate: '',
        },
        currentPaymentMethod: {},
        guardResponse: null,
        amount: 0,
        amountWithoutTax: 0,
        currency: '',
        pricePerLicense: 0,
        taxAmount: 0,
        subTotal: 0,
        amendActivityId: '',
        checkoutActivityId: '',
        cartId: '',
        discountAmount: 0,
        paymentAddress: {} as any,
        subscription: {} as any,
        currentSubscriptionQuantity: 0,
        expandActivityIdError: false,
        expandPreviewLoading: true,
        proratedPriceLoaded: false,
    }

    const useExpandState = () => {
        const [state, setState] = useState(!!props.initialState ? props.initialState : initialState)
        const setters = useMemo<Setters>(() => getSetters(setState), [setState])
        const [actions] = useState(getActions(setState))
        return {state, actions, setters}
    }

    const getSetters: (setState: any) => Setters = (setState: any) => ({
        setPlanId: (planId: string | undefined) => {
            setState((state: any) => ({
                ...state,
                planId,
            }))
        },
        setQuantity: (quantityToAdd: number) => {
            if (quantityToAdd >= 0) {
                setState((state: any) => ({
                    ...state,
                    // amount:
                    //     (state.pricePerLicense || state.currentSubscription.pricePerLicense) *
                    //     quantityToAdd,
                    quantityToAdd: quantityToAdd,
                }))
            }
        },
        setPaymentData: (paymentData: PaymentData) => {
            setState((state: any) => ({
                ...state,
                paymentData,
            }))
        },
        setPaymentAddress: (paymentAddress: PaymentAddress) => {
            setState((state: any) => ({
                ...state,
                paymentAddress,
            }))
        },
        setPaymentMethodsInitialized: (paymentMethodsInitialized: boolean) => {
            setState((state: any) => ({
                ...state,
                paymentMethodsInitialized,
            }))
        },
        setPaymentMethodId: (paymentMethodId: string) => {
            setState((state: any) => ({
                ...state,
                paymentMethodId,
            }))
        },
        setExistingPaymentMethods: (existingPaymentMethods: any) => {
            setState((state: any) => ({
                ...state,
                existingPaymentMethods,
            }))
        },
        setIsQuantityOpen: (isQuantityOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isQuantityOpen,
            }))
        },
        setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isReviewConfirmOpen,
            }))
        },
        setIsPaymentOpen: (isPaymentOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isPaymentOpen,
            }))
        },
        setIsPaymentComplete: (isPaymentComplete: boolean) => {
            setState((state: any) => ({
                ...state,
                isPaymentComplete,
            }))
        },
        setHasAgreedToTerms: (hasAgreedToTerms: boolean) => {
            setState((state: any) => ({
                ...state,
                hasAgreedToTerms,
            }))
        },
        setCurrentPaymentMethod: (paymentMethod: PaymentMethod) => {
            setState((state: any) => ({
                ...state,
                currentPaymentMethod: paymentMethod,
            }))
        },
        setPreviewLoading: () => {
            setState((state: any) => ({
                ...state,
                expandPreview: {
                    ...state.expandPreview,
                    isLoading: true,
                },
            }))
        },
        setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => {
            setState((state: any) => ({
                ...state,
                isMobileOrderSummaryOpen,
            }))
        },
        setHandle: (handle: string) => {
            setState((state: any) => ({
                ...state,
                handle,
            }))
        },
        setCurrentSubscription: (currentSubscription: ExpandV2BusinessSubscriptionData) => {
            setState((state: any) => ({
                ...state,
                currentSubscription,
            }))
        },
        setHasError(errorMessage: ExpandErrorMessage) {
            setState((state: any) => ({
                ...state,
                hasError: true,
                errorMessage: errorMessage,
            }))
        },
    })

    const getActions: (setState: any) => Actions = (setState: any) => ({
        loadPaymentMethods(planId: string) {
            api.get(`plans/${planId}/payment-methods`).then((res) => {
                if (res.status.success) {
                    setState((state: any) => ({
                        ...state,
                        existingPaymentMethods: res.data.paymentMethods.savedPayments,
                        currentPaymentMethod: res.data.paymentMethods.savedPayments[0],
                        paymentMethodsInitialized: true,
                    }))
                }
            })
        },
        postAmend(amendRequest: any) {
            api.post(`plans/${amendRequest.planId}/amend`, amendRequest)
                .then((res) => {
                    if (res.status.success) {
                        setState((state: ExpandState) => ({
                            ...state,
                            amendActivity: {amendActivityId: res.data.activityId},
                            expandPreviewLoading: false,
                            proratedPriceLoaded: true,
                        }))
                        this.getAmendActivity(res.data.activityId)
                    } else {
                        setState((state: ExpandState) => ({
                            ...state,
                            hasError: true,
                            expandPreviewLoading: false,
                            errorMessage: res.status.errorMessage,
                        }))
                    }
                })
                .catch((e) => {
                    activateErrorBoundary(e)
                })
        },
        getAmendActivity(activityId: string) {
            api.getPolling(`getActivity/${activityId}`, (result) => {
                if (result.data.status === 'SUCCESS') {
                    return true
                } else if (result.data.status === 'ERROR') {
                    return true
                }
                return false
            }).then((res) => {
                if (res.status.success) {
                    if (res.data.status === 'ERROR') {
                        setState((state: any) => ({
                            ...state,
                            expandActivityIdError: true,
                            expandPreviewLoading: false,
                            proratedPriceLoaded: false,
                        }))
                    } else {
                        setState((state: any) => ({
                            ...state,
                            //todo this will also return the info that is needed for the order summery, but needs to be wired up.
                            proratedPriceLoaded: false,
                            expandPreviewLoading: false,
                            cartId: res.data.data.cart.cartId,
                            subTotal: res.data.data.cart.netAmount,
                            discountAmount: res.data.data.cart.additionalDiscountAmount,
                        }))
                    }
                }
            })
        },
        getSubscription(subscriptionId: string) {
            api.get(`subscription/${subscriptionId}`)
                .then((res) => {
                    if (res.status.success) {
                        setState((state: any) => ({
                            ...state,
                            subscription: res.data.subscriptions[0],
                            pricePerLicense: res.data.subscriptions[0]?.billing?.listPrice,
                            currentSubscriptionQuantity: res.data.subscriptions[0].quantity,
                        }))
                    }
                })
                .catch((error) => activateErrorBoundary(error))
        },
        getTax(taxRequest: any) {
            api.post('calculateTax', taxRequest).then((res) => {
                if (res.status.success) {
                    setState((state: any) => ({
                        ...state,
                        amountWithoutTax: res.data.subTotal,
                        taxAmount: res.data.tax,
                        amount: res.data.totalWithTax,
                    }))
                }
            })
        },
        postCheckout(planId: string, payload: ExpandPayloadV2) {
            setState((state: ExpandStateV2) => ({
                ...state,
                expand: {
                    loaded: false,
                    pending: true,
                },
            }))

            api.post('checkout', payload).then((res) => {
                if (res.status.success) {
                    api.get(`plans/${planId}/payment-methods`).then((res2) => {
                        if (res2.status.success) {
                            setState((state: ExpandStateV2) => ({
                                ...state,
                                existingPaymentMethods: res2.data.paymentMethods.savedPayments,
                                paymentMethodsInitialized: true,
                                expand: {
                                    ...res.data,
                                    loaded: true,
                                    pending: false,
                                },
                            }))
                        }
                    })
                }
            })
        },
        expandGuardCheck(planId: string) {
            api.get(`plans/${planId}/expand-guard`).then((res) => {
                if (res.status.success) {
                    const guardResponse: ExpandGuardResponse = res.data
                    setState((state: ExpandStateV2) => {
                        return {
                            ...state,
                            hasError: !guardResponse.canExpand,
                            errorMessage: guardResponse.canExpand ? '' : guardResponse.message,
                            guardResponse: guardResponse,
                            guardData: guardResponse.canExpand ? undefined : guardResponse.data,
                        }
                    })
                } else {
                    throw Error(res.status.errorMessage)
                }
            })
        },
        resetState() {
            setState((state: ExpandStateV2) => ({
                ...initialState,
            }))
        },
        resetToQuantitySelection() {
            setState((state: ExpandStateV2) => ({
                ...state,
                paymentMethodId: '',
                isQuantityOpen: true,
                isQuantityComplete: false,
                isPaymentOpen: false,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
        resetToPaymentSelection() {
            setState((state: ExpandStateV2) => ({
                ...state,
                paymentMethodId: '',
                isQuantityOpen: false,
                isQuantityComplete: true,
                isPaymentOpen: true,
                isPaymentComplete: false,
                isReviewConfirmOpen: false,
            }))
        },
        trackAnalyticsEvent(
            stateSnapshot: ExpandStateV2,
            expandAnalyticsEvent: ExpandAnalyticsEvents,
            additionalData?: any
        ) {
            track(expandAnalyticsEvent, {
                currency: stateSnapshot.currency,
                productPrice: stateSnapshot.pricePerLicense,
                quantity: stateSnapshot.quantityToAdd,
                handle: stateSnapshot.handle,
                planId: stateSnapshot.planId,
                ...additionalData,
            })
        },
    })

    return (
        <ExpandContext.Provider value={useExpandState()}>{props.children}</ExpandContext.Provider>
    )
}

const ExpandContext = createContext({} as any)

const useExpandContext = () => {
    return useContext<{state: ExpandStateV2; actions: Actions; setters: Setters}>(ExpandContext)
}

interface Setters {
    setPlanId: (planId: string | undefined) => void
    setHandle: (planId: string) => void
    setQuantity: (quantity: number) => void
    setCurrentPaymentMethod: (paymentMethod: PaymentMethod) => void
    setPaymentAddress: (paymentAddress: any) => void
    setPaymentData: (paymentData: PaymentData) => void
    setIsQuantityOpen: (isQuantityOpen: boolean) => void
    setIsReviewConfirmOpen: (isReviewConfirmOpen: boolean) => void
    setIsPaymentOpen: (isPaymentOpen: boolean) => void
    setIsPaymentComplete: (isPaymentComplete: boolean) => void
    setPaymentMethodId: (paymentMethodId: string) => void
    setHasAgreedToTerms: (agreeToTerms: boolean) => void
    setExistingPaymentMethods: (paymentMethods: any) => void
    setPaymentMethodsInitialized: (isInitialized: boolean) => void
    setPreviewLoading: () => void
    setIsMobileOrderSummaryOpen: (isMobileOrderSummaryOpen: boolean) => void
    setCurrentSubscription: (currentSubscription: ExpandV2BusinessSubscriptionData) => void
    setHasError: (errorMessage: ExpandErrorMessage) => void
}

interface Actions {
    loadPaymentMethods: (planId: string) => void
    expandGuardCheck: (planId: string) => void
    postAmend: (amendRequest: any) => void
    getAmendActivity: (amendActivityId: string) => void
    postCheckout: (planId: string, payload: ExpandPayloadV2) => void
    resetState: () => void
    getTax: (taxRequest: any) => void
    getSubscription: (subscriptionId: string) => void
    resetToQuantitySelection: () => void
    resetToPaymentSelection: () => void
    trackAnalyticsEvent: (
        stateSnapshot: ExpandStateV2,
        expandAnalyticsEvent: ExpandAnalyticsEvents,
        additionalData?: any
    ) => void
}

// eslint-disable-next-line
interface ExpandStateV2 {
    paymentData: PaymentData
    cartId: string
    discountAmount: number
    quantityToAdd: number
    currentSubscription: ExpandV2BusinessSubscriptionData
    currentPaymentMethod: PaymentMethod
    isQuantityOpen: boolean
    isQuantityComplete: boolean
    hasAgreedToTerms: boolean
    isPaymentOpen: boolean
    isPaymentComplete: boolean
    paymentMethodId: string
    existingPaymentMethods: PaymentMethod[]
    paymentMethodsInitialized: boolean
    isReviewConfirmOpen: boolean
    planId: string
    handle: string
    guardResponse: ExpandGuardResponse | null
    amount: number
    amountWithoutTax: number
    currency: string
    pricePerLicense: number
    taxAmount: number
    subTotal: number
    isMobileOrderSummaryOpen: boolean
    expand: ExpandPostV2
    hasError: boolean
    errorMessage: ExpandErrorMessage
    guardData: ExpandErrorData
    amendActivityId: string
    checkoutActivityId: string
    subscriptionId: string
    paymentAddress: PaymentAddress
    subscription: any
    currentSubscriptionQuantity: any
    expandActivityIdError: boolean
    proratedPriceLoaded: boolean
    expandPreviewLoading: boolean
}

interface ExpandPostV2 extends ExpandResponseV2 {
    loaded: boolean
    pending: boolean
}

interface ExpandV2BusinessSubscriptionData {
    currentSubscriptionQuantity: number
    sfdcProductId: string
    productName: string
    isLoaded: boolean
    formattedAutoRenewDate: string
    currency: string
    pricePerLicense: number
}

interface PaymentAddress {
    addressLine1: string
    addressLine2: string
    city: string
    stateProvince: string
    country: string
    postalCode: string
}
interface PaymentData {
    paymentMethodId: string
    paymenMethodType: 'PayPal' | 'CreditCard'
    paypalEmail?: string
}
export {ExpandStateV2, useExpandContext}
