export enum ModifySetters {
    initializeModify = 'INITIALIZE_MODIFY',
    setProcessing = 'SET_PROCESSING',
    setResult = 'SET_RESULT',
    loadPaymentMethods = 'LOAD_PAYMENT_METHODS',
    setPaymentMethodId = 'SET_PAYMENT_METHOD_ID',
    resetPaymentMethods = 'RESET_PAYMENT_METHODS',
    setModifyGuard = 'SET_MODIFY_GUARD',
    setModifyError = 'SET_MODIFY_ERROR',
    resetModify = 'RESET_MODIFY',
    taxModify = 'TAX_MODIFY',
    updateSubscriptionCount = 'UPDATE_SUBSCRIPTION_COUNT',
    currentSubscription = 'CURRENT_SUBSCRIPTION',
    loadBusinessAccountQuantity = 'LOAD_BUSINESS_ACCOUNT_QUANTITY',
}
