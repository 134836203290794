import React, {useEffect, useState} from 'react'
import {useAppContext} from '../useAppState'
import {api} from '../api/api'
import {ApiContext, ApplicationDataWrapper} from '../../../definitions/api'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'

export default function BootstrapData(props: any) {
    const {setters} = useAppContext()
    useEffect(bootstrap, [setters])
    let [initialized, setInitialized] = useState(false)

    function bootstrap() {
        api.get('bootstrap').then((res: ApiContext<ApplicationDataWrapper>) => {
            if (res.status.success) {
                setters.setApplicationData(res.data.applicationData)
                setInitialized(true)
            } else {
                setters.setAppHasError(true)
            }
        })
    }

    return (
        <div>
            {initialized ? props.children : <CenteredLoadingDecorator centerVertically={true} />}
        </div>
    )
}
