import React from 'react'
import Link from '@pluralsight/ps-design-system-link'
import './styles/terms-layout.scss'
import {shortFormatDate} from '../../utils/date-utils/format-date'
import UnpaidInvoiceModal from '../../common/Unpaid-Invoice-Modal/Unpaid-Invoice-Modal'
import BusinessCancelModal from '../../common/Business-Cancel-Modal/business-cancel-modal'

export default function AccountTermsV2(props: {
    hasSubscription: boolean
    subscription: any
    displayName: string
    planId: string
    productStartDate: string
    productExpirationDate: string
    customerSource: string
    billingUrl: string | undefined
    unpaidInvoiceIds: string[]
    subscribeLink: string
    subscriptionId: string
    disableTransactionLinks: boolean
    isPsOneProduct: boolean
}) {
    const billingUrl = !!props.billingUrl ? props.billingUrl : ''
    const displayName = props.displayName
    const autoConvert = props.hasSubscription && props.subscription.autoConvert
    const expiration = props.hasSubscription ? props.subscription.termEndDate : ''
    const billingHref = `${props.billingUrl}/?planId=${props.planId}`
    const isResellerOrThirdParty = props.customerSource && props.customerSource !== 'Pluralsight'
    const thirdPartyHref = thirdPartySubscriptionHref(
        props.customerSource,
        props.planId,
        billingUrl
    )
    const currentDate: Date = new Date()
    const planEndDate: Date = new Date(props.productExpirationDate)
    const diffTime = Math.abs(currentDate.getTime() - planEndDate.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const isRenewAllowed = diffDays <= 90
    const managePlanHref = isResellerOrThirdParty ? thirdPartyHref : billingHref
    const renewLink = `/subscription/transaction/renew/${props.planId}/${props.subscriptionId}?acta=b2b_uue_click_renew`
    const hasUnpaidInvoice = !!props.unpaidInvoiceIds && props.unpaidInvoiceIds.length > 0
    const unpaidInvoiceIds = hasUnpaidInvoice ? props.unpaidInvoiceIds : []
    const subscribeText = `There is not currently an active plan for this account.`
    const autoRenewText =
        props.hasSubscription && props.subscription.autoRenew ? 'AUTO-RENEWS ON' : 'EXPIRES ON'
    const thirdPartyBillingInfo = null
    const hasFutureStartDate = new Date(props.productStartDate).getTime() > Date.now()
    const outOfTermExpirationText = hasFutureStartDate ? 'EXPIRES ON' : 'EXPIRED ON'
    return (
        <>
            <div className='accountDetails'>
                {termDetails(
                    props.disableTransactionLinks,
                    'ACCOUNT NAME',
                    managePlanHref,
                    isResellerOrThirdParty
                        ? props.customerSource === 'Reseller'
                            ? ''
                            : `Manage plan via ${props.customerSource}`
                        : 'Manage plan',
                    displayName,
                    props.customerSource !== 'Pluralsight',
                    {
                        thirdPartyBillingInfo,
                    },
                    isResellerOrThirdParty
                )}
                {props.hasSubscription ? (
                    <div className='termsLayout'>
                        {props.productStartDate &&
                            termDetails(
                                props.disableTransactionLinks,
                                'START DATE',
                                '',
                                'Cancel',
                                shortFormatDate(props.productStartDate),
                                props.customerSource !== 'Pluralsight'
                            )}
                        {autoConvert
                            ? termDetails(
                                  props.disableTransactionLinks,
                                  'AUTO CONVERTS ON',
                                  '',
                                  '',
                                  shortFormatDate(expiration),
                                  props.customerSource !== 'Pluralsight'
                              )
                            : termDetails(
                                  props.disableTransactionLinks,
                                  autoRenewText,
                                  isResellerOrThirdParty || props.isPsOneProduct || !isRenewAllowed
                                      ? ''
                                      : renewLink,
                                  isResellerOrThirdParty || props.isPsOneProduct || !isRenewAllowed
                                      ? ''
                                      : 'Renew now',
                                  shortFormatDate(expiration),
                                  props.customerSource !== 'Pluralsight',
                                  {
                                      hasUnpaidInvoice,
                                      unpaidInvoiceIds,
                                      planId: props.planId,
                                      subscriptionId: props.subscriptionId,
                                  }
                              )}
                    </div>
                ) : (
                    <>
                        <div className='termsLayout'>
                            {props.productStartDate &&
                                termDetails(
                                    props.disableTransactionLinks,
                                    'START DATE',
                                    '',
                                    '',
                                    shortFormatDate(props.productStartDate),
                                    props.customerSource !== 'Pluralsight'
                                )}
                            {props.productExpirationDate &&
                                termDetails(
                                    props.disableTransactionLinks,
                                    outOfTermExpirationText,
                                    '',
                                    '',
                                    shortFormatDate(props.productExpirationDate),
                                    props.customerSource !== 'Pluralsight'
                                )}
                        </div>
                        {!hasFutureStartDate &&
                            termDetails(
                                props.disableTransactionLinks,
                                'PLAN',
                                props.subscribeLink,
                                'Subscribe',
                                subscribeText,
                                props.customerSource !== 'Pluralsight'
                            )}
                    </>
                )}
            </div>
        </>
    )
}

function thirdPartySubscriptionHref(customerSource: string, planId: string, billingUrl: string) {
    switch (customerSource) {
        case 'Amazon':
            return 'https://www.amazon.com/yourmembershipsandsubscriptions'
        case 'AWS Marketplace':
            return 'https://console.aws.amazon.com/marketplace/home?region=us-east-1#/subscriptions'
        case 'Apple':
            return 'https://support.apple.com/en-us/HT202039'
        default:
            return `${billingUrl}/?planId=${planId}`
    }
}

function termDetails(
    disableTransactionLinks: boolean,
    header: string,
    link: string,
    linkText: string,
    data: string,
    isThirdParty: boolean,
    {
        thirdPartyBillingInfo,
        hasUnpaidInvoice,
        unpaidInvoiceIds,
        planId,
        subscriptionId,
    }: OptionalTerms = {
        thirdPartyBillingInfo: null,
        hasUnpaidInvoice: false,
    },
    isResellerOrThirdParty?: boolean | string
) {
    const subscribeText = linkText === 'Subscribe'
    const productLinkEnabled =
        !disableTransactionLinks && (linkText !== 'Renew now' || (planId && subscriptionId))
    return (
        <div className='termDetails'>
            <div className='text'>{header}</div>
            <div className='link'>
                {hasUnpaidInvoice ? (
                    <UnpaidInvoiceModal
                        linkText={'Renew now'}
                        planId={!!planId ? planId : ''}
                        invoiceIds={!!unpaidInvoiceIds ? unpaidInvoiceIds : []}
                        hideLink={isThirdParty}
                    />
                ) : linkText === 'Cancel' ? (
                    <BusinessCancelModal heading='Cancel your plan' hideLink={isThirdParty} />
                ) : (
                    <Link className={productLinkEnabled ? '' : 'link-disabled'}>
                        <a
                            href={link}
                            target={isResellerOrThirdParty ? '_blank' : ''}
                            rel='noreferrer'
                        >
                            {linkText}
                        </a>
                    </Link>
                )}
            </div>
            <div className={subscribeText ? 'subscribeText' : 'termData'}>{data}</div>
            {!!thirdPartyBillingInfo && <div className='resellerInfo'>{thirdPartyBillingInfo}</div>}
        </div>
    )
}

interface OptionalTerms {
    thirdPartyBillingInfo?: string | null
    hasUnpaidInvoice?: boolean
    unpaidInvoiceIds?: string[]
    planId?: string
    subscriptionId?: string
    isResellerOrThirdParty?: boolean | string
}
