import React, {useEffect, useState} from 'react'
import {api} from '../api/api'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import Radio from '@pluralsight/ps-design-system-radio'
import TextInput from '@pluralsight/ps-design-system-textinput'
import Dropdown from '@pluralsight/ps-design-system-dropdown'
import Form from '@pluralsight/ps-design-system-form'
import * as Text from '@pluralsight/ps-design-system-text'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import Button from '@pluralsight/ps-design-system-button'
import styles from './cache-provisioning.module.scss'

export default function CacheProvisioning() {
    const [keysLoading, setKeysLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [apiLoading, setApiLoading] = useState(false)
    const [data, setData] = useState('') as any
    const [dataRetrieved, setDataRetrieved] = useState(false)
    const [searchBy, setSearchBy] = useState('handle')
    const [searchValue, setSearchValue] = useState(null)
    const [registeredKeys, setRegisteredKeys] = useState({handleKeys: [], planKeys: []})
    const [keyOptions, setKeyOptions] = useState([] as any)
    const [keyValue, setKeyValue] = useState('')
    const [apiDataRetrieved, setApiDataRetrieved] = useState(false)
    const [apiData, setApiData] = useState({})

    useEffect(() => {
        api.get(`internal-api/registry-keys`).then((res) => {
            if (res.status.success) {
                setRegisteredKeys(res.data)
                setKeyOptions(res.data.handleKeys)
                setKeysLoading(false)
            }
        })
    }, [])
    const serachByHandleorPlanId = (e: any) => {
        if (e.currentTarget.value === 'handle') {
            setKeyOptions(registeredKeys.handleKeys)
        } else {
            setKeyOptions(registeredKeys.planKeys)
        }
        setSearchValue(null)
        setSearchBy(e.currentTarget.value)
    }
    const getSearchValue = (e: any) => {
        setSearchValue(e.currentTarget.value)
    }

    const getCacheData = async () => {
        setLoading(true)
        setDataRetrieved(false)
        const response = await makePostRequest(`internal-api/view-cache`)
        loadCacheData(response)
    }

    const refreshCache = async () => {
        setLoading(true)
        setDataRetrieved(false)
        const response = await makePostRequest(`internal-api/refresh-cache`)
        loadCacheData(response)
    }

    const loadCacheData = (res: any) => {
        const success = res.status.success
        let responseText = success ? res.data : res.status.errorMessage
        setData(responseText)
        setDataRetrieved(true)
        setLoading(false)
    }

    const getDataFromAPI = async () => {
        setApiLoading(true)
        setApiDataRetrieved(false)
        makePostRequest(`internal-api/get-fresh-data`).then((res: any) => {
            setApiData(res)
            setApiDataRetrieved(true)
            setApiLoading(false)
        })
    }

    const makePostRequest = async (path: string) => {
        const body = {
            handle: searchBy === 'handle' ? searchValue : null,
            planId: searchBy === 'planId' ? searchValue : null,
            key: keyValue,
        }
        return api.post(path, body)
    }

    return (
        <div>
            <div className={styles.appContent}>
                {keysLoading ? (
                    <CenteredLoadingDecorator />
                ) : (
                    <Form.VerticalLayout>
                        <Text.Heading>
                            <h5>Provision Cache by Handle or PlanId</h5>
                        </Text.Heading>
                        <Radio.Group
                            name={'handleOrPlanId'}
                            onChange={serachByHandleorPlanId}
                            value={searchBy}
                            className={styles.radioButton}
                        >
                            <Radio.Button value='handle' label='handle' />
                            <Radio.Button value='planId' label='planId' />
                        </Radio.Group>
                        <TextInput placeholder={searchBy} onChange={getSearchValue} />
                        <Dropdown
                            onChange={(evt: any, value: any) => setKeyValue(value)}
                            placeholder='Select key to search'
                            menu={keyOptions.map((key: string) => (
                                <Dropdown.Item key={key} value={key}>
                                    {key}
                                </Dropdown.Item>
                            ))}
                        />
                        <Form.ButtonRow>
                            {searchValue && keyValue ? (
                                <Button size={Button.sizes.medium} onClick={getCacheData}>
                                    Search Cache
                                </Button>
                            ) : (
                                <Button size={Button.sizes.medium} disabled>
                                    Search Cache
                                </Button>
                            )}
                            {searchValue && keyValue ? (
                                <Button
                                    size={Button.sizes.medium}
                                    appearance={Button.appearances.secondary}
                                    onClick={refreshCache}
                                >
                                    Refresh Cache
                                </Button>
                            ) : (
                                <Button
                                    size={Button.sizes.medium}
                                    appearance={Button.appearances.secondary}
                                    disabled
                                >
                                    Refresh Cache
                                </Button>
                            )}
                            {searchValue && keyValue ? (
                                <Button
                                    size={Button.sizes.medium}
                                    appearance={Button.appearances.secondary}
                                    onClick={getDataFromAPI}
                                >
                                    Hit API
                                </Button>
                            ) : (
                                <Button
                                    size={Button.sizes.medium}
                                    appearance={Button.appearances.secondary}
                                    disabled
                                >
                                    Hit API
                                </Button>
                            )}
                        </Form.ButtonRow>
                        <Form.Divider />
                        {loading ? (
                            <CenteredLoadingDecorator />
                        ) : !dataRetrieved ? (
                            <></>
                        ) : (
                            <div>
                                <p>Cache Response</p>
                                {displayData(data)}
                            </div>
                        )}
                        {apiLoading ? (
                            <CenteredLoadingDecorator />
                        ) : !apiDataRetrieved ? (
                            <></>
                        ) : (
                            <div>
                                <p>Api Response</p>
                                {displayData(apiData)}
                            </div>
                        )}
                    </Form.VerticalLayout>
                )}
            </div>
        </div>
    )
}

function displayData(apiResponse: any) {
    return (
        <div>
            <JSONPretty
                id='json-pretty'
                className='JSONPretty1337'
                data={JSON.stringify(apiResponse)}
            />
        </div>
    )
}
