import React, {useEffect, useState} from 'react'
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {PromotionConfigurationEditor} from './promotion-configuration-editor'
import {PromotionConfigurations} from './promotion-configurations'
import './promotion-configurations.scss'
import {usePromotionConfigurationState} from './promotion-configuration-context'
import {PromotionConfigurationSetters} from './promotion-configuration-setters'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'

export default function PromotionConfigurationRoutes() {
    let {path, url} = useRouteMatch()
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)

    const {actions, setter} = usePromotionConfigurationState()
    useEffect(() => {
        actions.loadConfigurations().then((configurations) => {
            setter({
                type: PromotionConfigurationSetters.loadConfigurations,
                data: configurations,
            })
            setShowLoadingDecorator(false)
        })
    }, [actions, setter])
    return (
        <div className='promotion-configuration-container'>
            {showLoadingDecorator ? <CenteredLoadingDecorator /> : <Routes />}
        </div>
    )

    function Routes() {
        return (
            <Switch>
                <Route exact path={`${url}`}>
                    <PromotionConfigurations />
                </Route>
                <Route exact path={`${path}/:id`}>
                    <PromotionConfigurationEditor />
                </Route>
            </Switch>
        )
    }
}
