import React from 'react'
import styles from './Account-Nav-Bar.module.scss'

export default function AccountNavBar() {
    return (
        <div>
            <nav className={styles.navBar}>
                <div className={styles.accountLayout}>Account</div>
                <a href='/profile/'>Profile</a>
                <a href='/id/accountsettings'>Settings</a>
                <a href='/communications/preferences'>Communication preferences</a>
                <a href='/id/devices'>Devices</a>
                <a href='/subscription' className={styles.navLink}>
                    Subscription &amp; Billing
                </a>
            </nav>
        </div>
    )
}
