import * as React from 'react'
import {useEffect, useState} from 'react'
import Form from '@pluralsight/ps-design-system-form'
import TextInput from '@pluralsight/ps-design-system-textinput'
import Radio from '@pluralsight/ps-design-system-radio'
import * as Text from '@pluralsight/ps-design-system-text'
import TextArea from '@pluralsight/ps-design-system-textarea'
import Button from '@pluralsight/ps-design-system-button'
import {api} from '../api/api'
import {useHistory, useParams} from 'react-router-dom'
import {useAppContext} from '../useAppState'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {ParamTypes} from '../../../definitions/param-types'
import styles from './Cpq-Expand.module.scss'

export default function CpqExpand(props: {cancelTo?: string}) {
    const bannerMessage =
        'Your request to add subscriptions has been submitted. A member of our sales team will reach out to you shortly.'
    const [reason, setReason] = useState()
    const [charLimit, setCharLimit] = useState(250)
    const [licensesToAdd, setLicencesToAdd] = useState('')
    const [notes, setNotes] = useState('')
    const expandReasons: any = getExpandReasons()
    const {planId} = useParams<ParamTypes>()
    const {state, actions} = useAppContext()
    const [displayName, setPlanDisplayName] = useState('' as any)
    const {setters} = useAppContext()
    const {productId} = useParams<ParamTypes>()
    const [processing, setProcessing] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (!!planId) {
            actions.loadPlanDisplayName([planId])
        }
    }, [planId, actions])
    useEffect(() => {
        if (!!planId) {
            actions.loadBusinessAccount(planId)
        }
    }, [planId, actions])
    useEffect(() => {
        if (planId in state.planDisplayNames && !state.planDisplayNames[planId].pending) {
            setPlanDisplayName(state.planDisplayNames[planId].displayName)
        }
    }, [state.planDisplayNames, planId])
    const handleChange = (e: any) => {
        if (e.target.name === 'reason') setReason(e.target.value)
        if (e.target.name === 'notes') {
            setNotes(e.target.value)
            setCharLimit(250 - e.target.value.length)
        }
        if (e.target.name === 'licenseCount')
            setLicencesToAdd(e.target.value < 0 ? 1 : e.target.value)
    }

    const handleCancel = (e: any) => {
        let cancelUrl = `/subscription/plans/${planId}`
        if (props.cancelTo) {
            cancelUrl = props.cancelTo
        }
        window.location.assign(cancelUrl)
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setProcessing(true)
        const body = {
            licensesToAdd: parseInt(licensesToAdd),
            notes,
            reason: expandReasons.get(reason),
            planDisplayName: displayName,
            user: state.applicationData.user,
        }
        await api.post(`plans/${planId}/cpq-expand`, body).then((res) => {
            if (res.status.success) {
                setters.setGreenProductBanner({
                    showBanner: true,
                    message: bannerMessage,
                    product: productId,
                })
                history.push(`/subscription/plans/${planId}`)
            } else {
                throw new Error(res.status.errorMessage)
            }
        })
    }
    let subLabel
    const accountWrapper = state.businessAccountMap[planId]
    if (accountWrapper && accountWrapper.loaded) {
        let productQuantity = state.businessAccountMap[planId].data.productState.products.filter(
            (product) => product.productInfo.inTerm
        )[0].productInfo.quantity
        if (productQuantity) {
            subLabel = `Current subscription count: ${productQuantity}`
        }
    }

    return (
        <>
            {processing ? (
                <CenteredLoadingDecorator />
            ) : (
                <>
                    {!!displayName ? (
                        <div className={styles.formLayout}>
                            <Form.VerticalLayout>
                                <Text.Heading>
                                    <h2>
                                        Request subscriptions for{' '}
                                        <span style={{fontWeight: 'bold'}}>{displayName}</span>
                                    </h2>
                                </Text.Heading>
                                <TextInput
                                    type='number'
                                    min='1'
                                    name='licenseCount'
                                    onChange={handleChange}
                                    label='How many subscriptions would you like to add?*'
                                    subLabel={subLabel}
                                    value={licensesToAdd}
                                />

                                <Radio.Group
                                    onChange={handleChange}
                                    name='reason'
                                    value={reason}
                                    label='Why would you like to add more subscriptions? (optional)'
                                >
                                    {[...expandReasons.keys()].map((reason) => {
                                        return (
                                            <Radio.Button
                                                key={reason}
                                                value={reason}
                                                label={expandReasons.get(reason)}
                                            />
                                        )
                                    })}
                                </Radio.Group>

                                <TextArea
                                    label='Notes (optional)'
                                    name='notes'
                                    maxLength={250}
                                    subLabel={`${charLimit} characters left`}
                                    onChange={handleChange}
                                />
                                <Form.ButtonRow>
                                    {!licensesToAdd ? (
                                        <Button disabled>Submit request</Button>
                                    ) : (
                                        <Button onClick={handleSubmit}>Submit request</Button>
                                    )}

                                    <Button
                                        appearance={Button.appearances.secondary}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Form.ButtonRow>
                            </Form.VerticalLayout>
                        </div>
                    ) : (
                        <CenteredLoadingDecorator />
                    )}
                </>
            )}
        </>
    )
}

function getExpandReasons() {
    const expandReasons = new Map()
    expandReasons.set('existing', 'For a team already using Pluralsight')
    expandReasons.set('new_team', 'For a team new to using Pluralsight')
    expandReasons.set(
        'new_and_existing',
        'For both teams already using Pluralsight & teams new to using Pluralsight'
    )
    return expandReasons
}
