import {useRenewState} from '../Renew-Context/renew-context'
import {RenewSetters} from '../renew-setters'
import UnpaidInvoice from '../../common/Unpaid-Invoice/Unpaid-Invoice'
import * as React from 'react'
import RenewErrorContent from '../Renew-Error-Content/renew-error-content'

export default function RenewUnpaidInvoice(props: {invoiceIds: string[]}) {
    const {setter} = useRenewState()
    const redirectSalesForm = () => {
        setter({type: RenewSetters.setRenewContactSales, data: null})
    }
    return (
        <RenewErrorContent>
            <UnpaidInvoice
                invoiceIds={props.invoiceIds}
                message={'renewing your plan.'}
                redirectSalesForm={redirectSalesForm}
            />
        </RenewErrorContent>
    )
}
