import * as React from 'react'
import {RenewErrorMessage} from '../../../../definitions/renew'
import GenericErrorPage from '../generic-error-page/generic-error-page'
import {config} from '../../environment-config'
import ContactSalesForm from '../../Contact-Sales-Form/Contact-Sales-Form'
import RenewErrorContent from '../Renew-Error-Content/renew-error-content'
import RenewUnpaidInvoice from '../Renew-Unpaid-Invoice/renew-unpaid-invoice'

export default function BlockedRenewRoutes(props: {
    errorMessage: RenewErrorMessage
    planId: string
    data: any
}) {
    const pricingPage = `${config.www.url}/pricing/skills?type=business`
    switch (props.errorMessage) {
        case 'unpaid invoice':
            return <RenewUnpaidInvoice invoiceIds={props.data} />
        case 'pilot subscription':
        case 'No products found':
        case 'digital renew set to false':
        case 'reseller':
        case 'sales contact form redirect':
            return (
                <RenewErrorContent>
                    <ContactSalesForm requestType={'renew'} />
                </RenewErrorContent>
            )
        case 'expired subscription':
        case 'cancelled subscription':
            window.location.assign(pricingPage)
            return <></>
        default:
            return <GenericErrorPage />
    }
}
