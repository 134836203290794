import Button from '@pluralsight/ps-design-system-button'
import React, {ReactNode} from 'react'
import {config} from '../../../environment-config'
import '../styles/transaction-success.scss'

export default function TransactionSuccessPage(props: {
    firstName: string
    message: ReactNode
    email: string
    orderSummary: ReactNode
}) {
    const viewInvoiceHref = `${config.billing.url}/billing/history`
    return (
        <div className='center'>
            <div className='heading'>
                Thank You, <span>{props.firstName}!</span>
            </div>
            <div className='subHeading'>{props.message}</div>
            <div className='buttonRow'>
                <div>
                    <Button
                        appearance={Button.appearances.stroke}
                        href={viewInvoiceHref}
                        target='_blank'
                    >
                        View invoice
                    </Button>
                </div>
                <div>
                    <Button href='/subscription/plans'>Back to subscription</Button>
                </div>
            </div>
            <hr></hr>
            <div className='notificationText'>Your full receipt has been sent to {props.email}</div>
            {props.orderSummary}
        </div>
    )
}
