import * as React from 'react'
import {ReactNode} from 'react'
import '../Transaction/styles/transaction-error-content.scss'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'

export default function TransactionErrorContent(props: {children: ReactNode}) {
    return (
        <div>
            <div className='ps-logo'>
                <PluralSightHeader />
            </div>
            <div className='transactionErrorContentLayout'>{props.children}</div>
        </div>
    )
}
