import * as React from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/thank-you.scss'

export default function OrderReceiptSummary() {
    const {state} = useRenewState()
    const currency = state.renew.data.cart.currencyCode
    const paymentMethodUsed = state.payment.savedPayments.filter(
        (data) => state.newPayment.paymentMethodId === data.id
    )[0]

    let lineItems
    const lineItem = state.renew.data.cart.cartItems.filter((cartItem) =>
        cartItem.hasOwnProperty('childItems')
    )
    lineItems = lineItem[0].childItems

    return (
        <>
            <div className='receipt'>
                <h4 className='header'>Order summary</h4>
                <div className='summary'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Plan</th>
                                <th>Subscriptions</th>
                                <th>Price</th>
                            </tr>
                            {lineItems.map((lineItem: any) => {
                                return formatLineItemProduct(lineItem, currency)
                            })}
                        </tbody>
                    </table>
                </div>
                <hr />
                <div className='subTotal'>
                    <div className='label'>
                        <div>Subtotal</div>
                        <div>Tax</div>
                    </div>
                    <div className='values'>
                        <div>
                            {formatPrice(
                                state?.tax?.data?.subTotal || state.renew.data.cart.netAmount
                            )}{' '}
                            {currency}
                        </div>
                        <div>
                            {state?.tax?.data ? formatPrice(state?.tax?.data?.tax) : formatPrice(0)}{' '}
                            {currency}
                        </div>
                    </div>
                </div>
                <hr />
                <div className='total'>
                    <div className='amountLabel'>Total</div>
                    <div className='amount'>
                        {state?.tax?.data?.totalWithTax
                            ? formatPrice(state?.tax?.data?.totalWithTax)
                            : formatPrice(state.renew.data.cart.customerAmount)}{' '}
                        {currency}
                    </div>
                </div>
            </div>
            {getPaymentMethodSummary(paymentMethodUsed)}
        </>
    )
}

function getPaymentMethodSummary(paymentMethod: any) {
    if (!paymentMethod) return
    const IsPayPal = paymentMethod.type === 'PayPal'
    // const paymentMethodDetail = IsPayPal
    //     ? paymentMethod.substring(8, paymentMethod.length - 1)
    //     : paymentMethod.substring(paymentMethod.length - 4)
    return (
        <div className='paymentText'>
            {IsPayPal ? (
                <div>
                    Charged to PayPal account: <span>{paymentMethod.details.paypalEmail}</span>{' '}
                </div>
            ) : (
                <div>{`Charged to Visa ending in ${paymentMethod.details.cardNumber.slice(
                    -4
                )}`}</div>
            )}
        </div>
    )
}

function formatLineItemProduct(lineItem: any, currency: string) {
    return (
        <tr key={lineItem.productId}>
            <td>{lineItem.productName}</td>
            <td>{lineItem.quantity}</td>
            <td>
                {formatPrice(lineItem.listPrice)} {currency}
            </td>
        </tr>
    )
}
