import {QuantitySelection} from '../../common/Quantity-Selection/Quantity-Selection'
import {useExpandContext} from '../Expand-Context'
import React from 'react'

export function ExpandQuantitySelector() {
    let {state, setters, actions} = useExpandContext()

    function submitQuantity() {
        setters.setIsQuantityOpen(false)
        setters.setIsPaymentOpen(true)
        const amendBody = {
            quantity: state.currentSubscription.currentSubscriptionQuantity + state.quantityToAdd,
            planId: state.planId,
            sku: state.currentSubscription.sfdcProductId,
            amendedSubscriptionId: state.subscription.subscriptionId,
            isUpgrade: false,
        }
        actions.postAmend(amendBody)
    }

    /*    function fireAnalytics() {
        actions.trackAnalyticsEvent(state, ExpandAnalyticsEvents.quantity)
    }*/

    function handleQuantityChange(event: any) {
        const quantity = Number(event.target.value)
        if (quantity > 0) {
            setters.setQuantity(quantity)
        }
    }

    function onCheck(event: any, checked: boolean, value: string, name: string) {
        setters.setHasAgreedToTerms(checked)
    }

    function contactSales() {
        setters.setHasError('transaction will put quantity above limit')
    }

    return (
        <QuantitySelection
            continueEnabled={state.subscription.subscriptionId}
            quantityToAdd={state.quantityToAdd}
            currentSubscriptionCount={
                state.currentSubscription.currentSubscriptionQuantity
                    ? state.currentSubscription.currentSubscriptionQuantity
                    : state.currentSubscriptionQuantity
            }
            updateQuantity={submitQuantity}
            isOpen={state.isQuantityOpen}
            onCheck={onCheck}
            handleQuantityChange={handleQuantityChange}
            hasAgreedToTerms={state.hasAgreedToTerms}
            resetToQuantitySelection={actions.resetToQuantitySelection}
            contactSales={() => contactSales()}
        />
    )
}
