import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {NotFoundErrorPage} from '@pluralsight/ps-design-system-errors'
import {AnalyticsPageView} from './Main-Content/Analytics-page-view'
import {Expand} from './expand/Expand'
import {TransactionAuth} from './Router/Transaction-Auth/TransactionAuth'
import RenewStateProvider from './Renew/Renew-Context/renew-context'
import RenewGuard from './Renew/renew-guard'
import {useAppContext} from './useAppState'
import UpsellStateProviderV2 from './Modify_v2/Modify-Context/modify-context'
import ModifyGuardV2 from './Modify_v2/modify-guard'
import UpsellStateProvider from './Modify/Modify-Context/modify-context'
import ModifyGuard from './Modify/modify-guard'
import Upgrade from './Upgrade/Upgrade'
import {CenteredPlanSelection} from './Plan-Selection/CenteredPlanSelection'
import {MainContent} from './Main-Content/Main-Content'
import RenewStateProviderV2 from './Renew-V2/Renew-Context/renew-context'
import RenewGuardV2 from './Renew-V2/renew-guard'
import {ExpandV2} from './expand-v2/Expand'
import UpgradeV2 from './Upgrade-V2/Upgrade'

export function TransactionRoutes() {
    const {state} = useAppContext()
    return (
        <AnalyticsPageView>
            <Switch>
                <Route exact path='/subscription/transaction/expand'>
                    <CenteredPlanSelection
                        destinationFn={(planId) => `/subscription/transaction/expand/${planId}`}
                    />
                </Route>
                <Redirect
                    from='/subscription/transaction/plans/:planId/expand'
                    to='/subscription/transaction/expand/:planId'
                />
                <Route exact path='/subscription/transaction/expand/:planId'>
                    <TransactionAuth>
                        {state.applicationData.featureConfig.useDocmationCheckout ? (
                            <ExpandV2 />
                        ) : (
                            <Expand />
                        )}
                    </TransactionAuth>
                </Route>
                <Route exact path='/subscription/transaction/expand/:planId/:subscriptionId/'>
                    <TransactionAuth>
                        {state.applicationData.featureConfig.useDocmationCheckout && <ExpandV2 />}
                    </TransactionAuth>
                </Route>

                <Route exact path='/subscription/transaction/account'>
                    <CenteredPlanSelection
                        destinationFn={(planId) => `/subscription/transaction/account/${planId}`}
                    />
                </Route>
                <Redirect
                    from='/subscription/transaction/account/:planId'
                    to='/subscription/plans/:planId'
                />
                <Route exact path='/subscription/plans/:planId'>
                    <TransactionAuth>
                        <MainContent />
                    </TransactionAuth>
                </Route>

                <Route exact path='/subscription/transaction/renew'>
                    <CenteredPlanSelection
                        destinationFn={(planId) => `/subscription/transaction/renew/${planId}`}
                    />
                </Route>
                <Redirect
                    from='/subscription/transaction/plans/:planId/renew'
                    to='/subscription/transaction/renew/:planId'
                />
                <Route path='/subscription/transaction/renew/:planId/:subscriptionId'>
                    <TransactionAuth>
                        {state.applicationData.featureConfig.useDocmationCheckout && (
                            <RenewStateProviderV2>
                                <RenewGuardV2 />
                            </RenewStateProviderV2>
                        )}
                    </TransactionAuth>
                </Route>
                <Route path='/subscription/transaction/renew/:planId'>
                    <TransactionAuth>
                        <RenewStateProvider>
                            <RenewGuard />
                        </RenewStateProvider>
                    </TransactionAuth>
                </Route>
                <Route exact path='/subscription/transaction/upsell'>
                    <CenteredPlanSelection
                        destinationFn={(planId, productOptionId) =>
                            `/subscription/transaction/plans/${planId}/productOption/${productOptionId}/modify`
                        }
                    />
                </Route>
                <Redirect
                    from='/subscription/transaction/plans/:planId/upsell'
                    to='/subscription/transaction/plans/:planId/productOption/:productOptionId/modify'
                />

                {state.applicationData.featureConfig.modifyFeatureFlag &&
                    (state.applicationData.featureConfig.useDocmationCheckout ? (
                        <Route
                            exact
                            path='/subscription/transaction/plans/:planId/productOption/:productOptionId/modify/:subscriptionId/:labsQuantity'
                        >
                            <TransactionAuth>
                                <UpsellStateProviderV2>
                                    <ModifyGuardV2 />
                                </UpsellStateProviderV2>
                            </TransactionAuth>
                        </Route>
                    ) : (
                        <Route
                            exact
                            path='/subscription/transaction/plans/:planId/productOption/:productOptionId/modify'
                        >
                            <TransactionAuth>
                                <UpsellStateProvider>
                                    <ModifyGuard />
                                </UpsellStateProvider>
                            </TransactionAuth>
                        </Route>
                    ))}

                <Route exact path='/subscription/transaction/upgrade'>
                    <CenteredPlanSelection
                        destinationFn={(planId) => `/subscription/transaction/upgrade/${planId}`}
                    />
                </Route>
                <Redirect
                    from='/subscription/transaction/plans/:planId/upgrade'
                    to='/subscription/transaction/upgrade/:planId'
                />
                {state.applicationData.featureConfig.upgradeFeatureFlag &&
                    (state.applicationData.featureConfig.useDocmationCheckout ? (
                        <Route path={`/subscription/transaction/upgrade/:planId/:subscriptionId`}>
                            <TransactionAuth>
                                <UpgradeV2 />
                            </TransactionAuth>
                        </Route>
                    ) : (
                        <Route path={`/subscription/transaction/upgrade/:planId`}>
                            <TransactionAuth>
                                <Upgrade />
                            </TransactionAuth>
                        </Route>
                    ))}

                <Route path='*' component={NotFoundErrorPage} />
            </Switch>
        </AnalyticsPageView>
    )
}
