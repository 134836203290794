import React, {ReactNode, useEffect} from 'react'
import {useExpandContext} from './Expand-Context'
import {useParams} from 'react-router'
import {debounce} from '../utils/debounce'
import {useAppContext} from '../useAppState'
import {formatDate} from '../utils/date-utils/format-date'
import {ParamTypes} from '../../../definitions/param-types'

export default function ExpandDataLoader(props: {children: ReactNode}) {
    const {state: appState, actions: appActions} = useAppContext()
    const {state, actions, setters} = useExpandContext()

    let {planId} = useParams<ParamTypes>()

    useEffect(() => {
        const hasPlanId = !!planId
        const hasGuardResponse = !!state.guardResponse
        const planIdMismatch = planId !== state.guardResponse?.planId
        if ((hasPlanId && !hasGuardResponse) || planIdMismatch) {
            actions.expandGuardCheck(planId)
        }
    }, [actions, planId, state.guardResponse])

    useEffect(() => {
        if (
            !!planId &&
            state.currentSubscription.isLoaded &&
            !state.expand.loaded &&
            state.guardResponse?.canExpand
        ) {
            setters.setPreviewLoading()
            let debounced = debounce(actions.expandPreview, 500)
            debounced(planId, {
                currentLicenseCount: state.currentSubscription.currentSubscriptionQuantity,
                licensesToAdd: state.quantityToAdd,
            })
        }
    }, [
        planId,
        actions,
        state.quantityToAdd,
        state.expand.loaded,
        state.currentSubscription.isLoaded,
        state.currentSubscription.currentSubscriptionQuantity,
        state.guardResponse?.canExpand,
        setters,
    ])

    useEffect(() => {
        if (!!planId) {
            appActions.loadBusinessAccount(planId)
        }
    }, [planId, appActions])

    useEffect(() => {
        const account = appState.businessAccountMap[planId]
        const accountLoaded = (account && account.loaded) || false
        if (!!planId && accountLoaded) {
            const inTermProducts = account.data.productState.products.filter(
                (p) => p.productInfo.inTerm
            )
            const product = inTermProducts[0]
            let currentSubscription = {
                isLoaded: true,
                currentSubscriptionQuantity: product?.productInfo.quantity | 0,
                currency: account.data.billingInfo.currency,
                productName: product?.productCatalogInfo.product.name,
                formattedAutoRenewDate: formatDate(product?.productInfo.expiresAt),
            }
            setters.setCurrentSubscription(currentSubscription)
        }
    }, [planId, setters, appState])

    useEffect(() => {
        if (!!planId && !state.paymentMethodsInitialized) {
            actions.loadPaymentMethods(planId)
        }
    }, [planId, state.paymentMethodsInitialized, actions])

    useEffect(() => {
        setters.setPlanId(planId)
        setters.setHandle(appState.applicationData.user.handle)
    }, [planId, setters, appState.applicationData.user.handle])

    return <div>{props.children}</div>
}
