import * as React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import RenewUnBlocked from './Renew-Unblocked/renew-unblocked'
import {useRenewState} from './Renew-Context/renew-context'
import {RenewSetters} from './renew-setters'
import {useAsyncError} from '../useAsyncError'
import ProcessRenew from './Thank-You-Page/process-renew'
import {LinearLoadingDecorator} from '../Loading-Decorator/Linear-Loading-Decorator'
import {useAppContext} from '../useAppState'

export default function Renew() {
    const {state, setter, actions} = useRenewState()
    const {state: appState, actions: appActions} = useAppContext()
    let {planId} = useParams<ParamTypes>()
    const throwError = useAsyncError()
    let {subscriptionId} = useParams<ParamTypes>()

    useEffect(() => {
        if (!!planId) {
            appActions.loadBusinessAccount(planId)
        }
    }, [planId, appActions])

    const account = appState.businessAccountMap[planId]

    const isPilot = account?.data?.productState?.products.filter(
        (p) => p.productCatalogInfo.product.category === 'Base Products'
    )[0].productCatalogInfo?.productOption?.freeTrial

    useEffect(() => {
        if (typeof isPilot !== 'undefined') {
            actions
                .getSubscription(subscriptionId)
                .then((data) => {
                    let subscriptionId = data.subscriptions[0].subscriptionId
                    let SKU = data.subscriptions[0].productSKU
                    let quantity = data.subscriptions[0].quantity
                    let contractId = data.subscriptions[0].contractId
                    let isB2BPilot = isPilot
                    actions
                        .renew(planId, contractId, quantity, SKU, subscriptionId, isB2BPilot)
                        .then((data) => {
                            const renewActivityId = data.activityId
                            actions
                                .activityId(renewActivityId)
                                .then((data: any) => {
                                    if (data.status === 'ERROR') {
                                        setter({type: RenewSetters.setRenewError, data: null})
                                    } else {
                                        setter({type: RenewSetters.initializeRenew, data})
                                    }
                                })
                                .catch((e) => {
                                    throwError(e.message)
                                })
                        })
                })
                .catch((e) => {
                    throwError(e.message)
                })
        }
    }, [setter, actions, throwError, subscriptionId, planId, isPilot])
    return (
        <>
            {!state.renew.isInitialized ? (
                state.renewError ? (
                    <>
                        <ProcessRenew />
                    </>
                ) : (
                    <>
                        <LinearLoadingDecorator centerVertically={true} />
                    </>
                )
            ) : (
                <>{<RenewUnBlocked />}</>
            )}
        </>
    )
}
