import Table from '@pluralsight/ps-design-system-table'
import {PromotionConfig} from '../../../../definitions/promotion-config'
import React from 'react'
import './promotion-table-components.scss'
import {formatDateLocalTime} from './format-date-local-time'

export function PromosHeader() {
    return (
        <Table.Head>
            <Table.Row>
                <Table.Header role='columnheader' scope='col' className='promo-col-id'>Id</Table.Header>
                <Table.Header role='columnheader' scope='col' className='promo-col-cta'>CTA</Table.Header>
                <Table.Header role='columnheader' scope='col'>Promo Landing</Table.Header>
                <Table.Header role='columnheader' scope='col'>Start</Table.Header>
                <Table.Header role='columnheader' scope='col'>End</Table.Header>
            </Table.Row>
        </Table.Head>
    )
}

export function PromoRow(props: {
    promotion: PromotionConfig
    onClick?: () => void
    className?: string
}) {
    const p = props.promotion
    const startTime = formatDateLocalTime(p.startTime)
    const endTime = formatDateLocalTime(p.endTime)
    const id = p.id === -1 ? 'NEW' : p.id
    return (
        <Table.Body>
            <Table.Row key={p.id} onClick={props.onClick} className={props.className}>
                <Table.Cell className='promo-col-id'>{id}</Table.Cell>
                <Table.Cell className='promo-col-cta'>{p.ctaText}</Table.Cell>
                <Table.Cell>{p.promoLanding}</Table.Cell>
                <Table.Cell>{startTime}</Table.Cell>
                <Table.Cell>{endTime}</Table.Cell>
            </Table.Row>
        </Table.Body>
    )
}
