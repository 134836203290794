import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {ReactComponent as SkillsLogoDark} from '../images/skills-logo.svg'
import {ReactComponent as AcgLogoDark} from '../images/acg-logo.svg'
import {ReactComponent as FlowLogoDark} from '../images/flow-logo.svg'
import {ReactComponent as ProfessionalServicesLogoDark} from '../images/proserv-logo.svg'
import Banner from '@pluralsight/ps-design-system-banner'
import {useAppContext} from '../useAppState'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {config} from '../environment-config'
import Accounts from '../Account-Plans/accounts'
import AccSwitcher from '../Account-Switcher/acc-switcher'
// import DisplayFutureState from './Display-Future-State/display-future-state'
import {ProductV2, ProductWithSubscriptionId} from '../../../definitions/product-state'
import {ParamTypes} from '../../../definitions/param-types'
import {isAddon} from '../utils/is-addon'
import {isFutureDate} from '../utils/date-utils/is-future-date'
import {isPastDate} from '../utils/date-utils/is-past-date'
import styles from './admin-plan.module.scss'
import ProductDetailsV2 from './admin-sections/Product-DetailsV2'
import AccountTermsV2 from './admin-sections/Account-TermsV2'
import {PROFESSIONAL_SKU, STARTER_SKU} from '../common/constants'

export function AdminPlanV2() {
    const {state, actions} = useAppContext()
    let {planId} = useParams<ParamTypes>()
    const [productStartDate, setProductStartDate] = useState('')
    const [productExpirationDate, setProductExpirationDate] = useState('')
    const userHandle = state.applicationData.user.handle
    const showModifyExp = state.applicationData.featureConfig.modifyFeatureFlag
    const disableTransactionLinks = state.applicationData.featureConfig.disableTransactionLinks
    const showUpgradeLink = state.applicationData.featureConfig.upgradeFeatureFlag
    useEffect(() => {
        if (!!planId) {
            actions.loadPlanDisplayName([planId])
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            actions.loadBusinessAccount(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            actions.loadSubscription(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        if (!!planId) {
            if (!state.applicationData.featureConfig.useDocmationCheckout) {
                actions.loadInvoices(planId)
            } else {
                actions.loadInvoicesV2(planId)
            }
        }
    }, [planId, state.applicationData.featureConfig.useDocmationCheckout, actions])

    useEffect(() => {
        const accountWrapper = state.businessAccountMap[planId]
        const accountLoaded = accountWrapper && accountWrapper.loaded
        if (
            accountLoaded &&
            planId &&
            !!state.planDisplayNames[planId] &&
            !state.planDisplayNames[planId].pending
        ) {
            const account = accountWrapper.data
            if (account.productState.products && account.productState.products.length > 0) {
                const earliestStartDate = account.productState.products[0].productInfo.startsAt
                const mostRecentProduct = account.productState.products.reduce(
                    (prev: any, cur: any) => {
                        if (!prev.productInfo || !prev.productInfo.expiresAt) {
                            prev = cur
                        } else if (!!prev.productInfo && !!prev.productInfo.expiresAt) {
                            if (
                                new Date(cur.productInfo.expiresAt) >
                                new Date(prev.productInfo.expiresAt)
                            ) {
                                prev = cur
                            }
                        }
                        return prev
                    },
                    {}
                )
                const lastExpirationDate = mostRecentProduct.productInfo.expiresAt
                setProductStartDate(earliestStartDate)
                setProductExpirationDate(lastExpirationDate)
            }
            setShowLoadingDecorator(false)
        }
    }, [planId, state.businessAccountMap, state.planDisplayNames])

    let [showLoadingDecorator, setShowLoadingDecorator] = useState(true)

    function mainContent() {
        const accountWrapper = state.businessAccountMap[planId]
        const sortedSubscriptions = accountWrapper.data?.subscriptionState.subscriptions.sort(
            (a, b) => {
                const timeA = new Date(a.termEndDate).getTime()
                const timeB = new Date(b.termEndDate).getTime()
                return timeB - timeA
            }
        )

        const isMultiYear = sortedSubscriptions[0]?.term > 12
        const isCPQ = sortedSubscriptions[0]?.isCPQ
        const products =
            accountWrapper && accountWrapper.loaded ? accountWrapper.data.productState.products : []
        const hasSubscription = products.length > 0 && products.some((p) => p.productInfo.inTerm)
        const currentTime = Date.now()
        const currentOrFutureProducts = products.filter(isCurrentOrFutureProduct)
        const hasProducts = currentOrFutureProducts.length > 0
        const displayName = !!planId ? state.planDisplayNames[planId].displayName : ''
        const inTermProducts = products.filter((p) => p.productInfo.inTerm)
        const customerSource = inTermProducts[0]?.productCatalogInfo?.productOption?.customerSource
        let subscriptionId: string = ''
        let subscription = inTermProducts.filter(
            (data) =>
                data.productCatalogInfo.product.category === 'Base Products' &&
                data.productCatalogInfo.product.type === 'Skills'
        )

        const sfdcId = subscription[0]?.productCatalogInfo?.productOption?.sfdcProductId
        if (
            state.subscriptionData[planId] &&
            state.subscriptionData[planId].loaded &&
            state.subscriptionData[planId].data.subscriptions.length > 0
        ) {
            const sub = state.subscriptionData[planId].data.subscriptions.filter(
                (subscription) => subscription.productSKU === sfdcId
            )

            // Use latest subscription based on subscriptionStartDate
            sub.sort((a, b) => (a.subscriptionStartDate > b.subscriptionStartDate ? -1 : 1))

            if ('LABS' === sub[0]?.productSKU) {
                let sfdcId = subscription[0]?.productCatalogInfo?.productOption?.sfdcProductId
                const sub = state.subscriptionData[planId].data.subscriptions.filter(
                    (subscription) => subscription.productSKU === sfdcId
                )
                subscriptionId = sub[0]?.subscriptionId
            } else if (sub.length === 0) {
                subscriptionId = ''
            } else {
                subscriptionId = sub[0]?.subscriptionId
            }
        }
        const isPsOneProduct =
            subscription[0] && subscription[0].productCatalogInfo
                ? subscription[0].productCatalogInfo.product.sku.includes('PSONE')
                : false
        const unpaidInvoiceIds = state.invoiceData[planId]?.data?.unpaidInvoices.map(
            (invoice) => invoice.id
        )
        const hasUnpaidInvoices = !!unpaidInvoiceIds && unpaidInvoiceIds.length > 0
        return (
            <div className={styles.adminContainer}>
                {disableTransactionLinks && (
                    <Banner color={Banner.colors.yellow}>
                        {
                            'Some features on this page are temporarily unavailable. Please contact sales@pluralsight.com or check back later'
                        }
                    </Banner>
                )}
                <AccountTermsV2
                    hasSubscription={hasSubscription}
                    displayName={displayName}
                    subscription={sortedSubscriptions[0]}
                    planId={planId}
                    productStartDate={productStartDate}
                    productExpirationDate={productExpirationDate}
                    customerSource={customerSource}
                    billingUrl={config.billing.url}
                    unpaidInvoiceIds={unpaidInvoiceIds}
                    subscribeLink={state.pricingUrls.businessSkills}
                    subscriptionId={subscriptionId}
                    disableTransactionLinks={disableTransactionLinks}
                    isPsOneProduct={isPsOneProduct}
                />
                {hasProducts && (
                    <Products
                        planId={planId}
                        products={currentOrFutureProducts}
                        currentTime={currentTime}
                        showUpgradeLink={showUpgradeLink}
                        showModifyExp={showModifyExp}
                        hasUnpaidInvoices={hasUnpaidInvoices}
                        isMultiYear={isMultiYear}
                        isCPQ={isCPQ}
                        subscriptionId={subscriptionId}
                        disableTransactionLinks={disableTransactionLinks}
                    />
                )}
                <Accounts />
                <AccSwitcher
                    plans={state.applicationData.planInfo.admin}
                    currentPlanId={planId}
                    userHandle={userHandle}
                    path='/subscription/plans'
                />
            </div>
        )
    }

    return (
        <>
            {showLoadingDecorator ? (
                <>
                    <CenteredLoadingDecorator />
                </>
            ) : (
                mainContent()
            )}
        </>
    )
}

function Products(props: {
    products: ProductV2[]
    planId: string
    currentTime: number
    showUpgradeLink: boolean
    showModifyExp: boolean
    hasUnpaidInvoices: boolean
    isMultiYear: boolean
    isCPQ: boolean
    subscriptionId: string
    disableTransactionLinks: boolean
}) {
    const {state, setters, actions} = useAppContext()
    const modifiedProducts: ProductWithSubscriptionId[] = []
    const noModifiedProducts: any = []

    const currentInfo = state.subscriptionData[props.planId]
    if (currentInfo && currentInfo.loaded && currentInfo.data.subscriptions.length > 0) {
        const subscriptionData = currentInfo.data.subscriptions.map((subscription) => {
            return {
                subscriptionId: subscription.subscriptionId,
                productSKU: subscription.productSKU,
            }
        })
        for (const product of props.products) {
            const subscription = subscriptionData.filter(
                (sub) =>
                    sub.productSKU ===
                    (product.productCatalogInfo.productOption.sfdcProductId &&
                        product.productInfo.inTerm)
            )
            if (subscription.length > 0) {
                const newProduct = {...product, subscriptionId: subscription[0].subscriptionId}
                modifiedProducts.push(newProduct)
            } else {
                noModifiedProducts.push('Data')
            }
        }
    }

    useEffect(() => {
        if (!state.subscriptionHardRefreshDone && modifiedProducts.length === 0) {
            setters.setSubscriptionHardRefreshDone(true)
            actions.loadSubscription(props.planId, true)
        }
    }, [
        actions,
        modifiedProducts.length,
        noModifiedProducts.length,
        props.planId,
        setters,
        state.subscriptionHardRefreshDone,
    ])

    const products = groupAndCollapse(
        modifiedProducts.length > 0 ? modifiedProducts : (props.products as any)
    )

    const dismissCPQBanner = () => {
        setters.setGreenProductBanner({
            showBanner: false,
            product: '',
            message: '',
        })
    }
    return (
        <>
            {state.greenProductBanner.showBanner && (
                <Banner color={Banner.colors.green} onClick={dismissCPQBanner}>
                    {state.greenProductBanner.message}
                </Banner>
            )}
            {products.map((p) =>
                product(
                    p,
                    props.planId,
                    props.showUpgradeLink,
                    props.showModifyExp,
                    props.hasUnpaidInvoices,
                    props.isMultiYear,
                    props.isCPQ,
                    props.subscriptionId,
                    props.disableTransactionLinks
                )
            )}
        </>
    )
}

function sortHandler(type: string) {
    switch (type) {
        case 'Skills':
            return 1
        case 'ACG':
            return 2
        case 'ProServ':
            return 3
        case 'Flow':
            return 4
        default:
            return 0
    }
}

function groupAndCollapse(products: ProductWithSubscriptionId[]) {
    const productMap = new Map<string, ProductGroup>()
    products.forEach((p) => {
        const type = p.productCatalogInfo.product.type
        if (productMap.has(type)) {
            // @ts-ignore
            productMap.get(type).products.push(p)
        } else {
            productMap.set(type, {type: type, products: [p], index: sortHandler(type)})
        }
    })
    const groupedProducts = Array.from(productMap.values())
    groupedProducts.sort((a: any, b: any) => (a.index > b.index ? 1 : -1))
    const groupedAndCollapsed = groupedProducts.map((gp) => {
        const type = gp.type
        const list = gp.products
        return {type, products: list}
    })
    return groupedAndCollapsed
}

interface ProductGroup {
    type: string
    products: ProductWithSubscriptionId[]
    index?: number
}

function product(
    productGroup: ProductGroup,
    planId: string,
    showUpgradeLink: boolean,
    showModifyExp: boolean,
    hasUnpaidInvoices: boolean,
    isMultiYear: boolean,
    isCPQ: boolean,
    subscriptionId: string,
    disableTransactionLinks: boolean
) {
    const starterProducts = productGroup.products.filter(
        (p) => !isAddon(p.productCatalogInfo.product.category)
    )
    const currentOrFutureAddons = productGroup.products.filter(
        (p) => isAddon(p.productCatalogInfo.product.category) && isCurrentOrFutureProduct(p)
    )
    const hasSpokes = currentOrFutureAddons.length > 0 && starterProducts.length > 0
    // const futureProducts = starterProducts.filter((p) => isFutureDate(p.productInfo.startsAt))
    const currentProduct =
        starterProducts.filter((p) => p.productInfo.inTerm)[0] ||
        starterProducts[0] ||
        currentOrFutureAddons[0]
    const productIcon = getProductIcon(productGroup.type)
    const assignSubsLink = getAssignSubsLink(productGroup.type, planId)
    // const hasFutureState = futureProducts.length > 0
    const customerSource = currentProduct.productCatalogInfo.productOption.customerSource
    const isPSProduct = customerSource === 'Pluralsight'
    const inTermStarterProduct =
        !!starterProducts && starterProducts.length > 0
            ? starterProducts.filter((p) => p.productInfo.inTerm)
            : null
    const upsellProductOptions = !!inTermStarterProduct
        ? starterProducts[0].productCatalogInfo.upsellProductOptions
        : []
    const hasStarterOrProfessionalSku =
        currentProduct.productCatalogInfo.productOption.sfdcProductId === STARTER_SKU ||
        currentProduct.productCatalogInfo.productOption.sfdcProductId === PROFESSIONAL_SKU
    const checkUpgradeability = showUpgradeLink && hasStarterOrProfessionalSku

    return (
        <div className={`${styles[`${productGroup.type}-border`]}`} key={productGroup.type}>
            <div className={styles.productTypeLabel}>
                {!!productIcon ? productIcon : productGroup.type}
            </div>
            {/* {hasFutureState &&
                futureProducts.map((product: ProductV2, index: number, array: ProductV2[]) => {
                    const previousProduct = index === 0 ? currentProduct : array[index - 1]
                    return (
                        // <div className={styles.lineItemContainer} key={index}>
                        //     <DisplayFutureState
                        //         product={product}
                        //         previousProduct={previousProduct}
                        //         planID={planId}
                        //     />
                        // </div>
                    )
                })} */}
            <ProductDetailsV2
                product={{baseProduct: currentProduct, currentOrFutureAddons, hasSpokes}}
                planId={planId}
                assignSubsLink={assignSubsLink}
                isPSProduct={isPSProduct}
                upsellProductOptions={upsellProductOptions}
                showUpgradeLink={checkUpgradeability}
                showModifyExp={showModifyExp}
                hasUnpaidInvoices={hasUnpaidInvoices}
                isMultiYear={isMultiYear}
                isCPQ={isCPQ}
                subscriptionId={subscriptionId}
                disableTransactionLinks={disableTransactionLinks}
            />
        </div>
    )
}

function getAssignSubsLink(productType: string, planId: string) {
    switch (productType) {
        case 'Skills':
            return `/plans/${planId}/people/org?acta=b2b_uue_click_assign_sub`
        case 'Flow':
            return null //TODO add when we know where to link!
        default:
            return null
    }
}

function getProductIcon(type: string) {
    switch (type) {
        case 'Skills':
            return <SkillsLogoDark />
        case 'ACG':
            return <AcgLogoDark className={styles.acgResize} />
        case 'Flow':
            return <FlowLogoDark />
        case 'ProServ':
            return <ProfessionalServicesLogoDark className={styles.proServeResize} />
        default:
            return null
    }
}

function isCurrentOrFutureProduct(p: ProductV2) {
    return (
        isFutureDate(p.productInfo.startsAt) ||
        (isPastDate(p.productInfo.startsAt) && isFutureDate(p.productInfo.expiresAt))
    )
}
