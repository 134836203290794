import {ReviewConfirm, ReviewConfirmMobile} from '../common/Review-Confirm/Review-Confirm'
import {useExpandContext} from './Expand-Context'
import React from 'react'
import {ExpandTermsAndConditions} from './Expand-Terms-And-Conditions'

export function ExpandReviewConfirm() {
    let {state, actions} = useExpandContext()
    return (
        <ReviewConfirm
            isOpen={state.isReviewConfirmOpen}
            isActive={state.isReviewConfirmOpen}
            isComplete={false}
            stepNumber={3}
            purchase={() => {
                actions.postExpand(state)
            }}
            terms={ExpandTermsAndConditions}
        />
    )
}

export function ExpandReviewConfirmMobile() {
    let {state, actions} = useExpandContext()

    return (
        <ReviewConfirmMobile
            isOpen={state.isReviewConfirmOpen}
            isActive={state.isReviewConfirmOpen}
            isComplete={false}
            stepNumber={3}
            purchase={() => actions.postExpand(state)}
            autoRenewDate={state.currentSubscription.formattedAutoRenewDate}
            productName={state.currentSubscription.productName}
            terms={ExpandTermsAndConditions}
        />
    )
}
