export enum RenewSetters {
    initializeRenew = 'INITIALIZE_RENEW',
    setProcessing = 'SET_PROCESSING',
    setResult = 'SET_RESULT',
    loadPaymentMethods = 'LOAD_PAYMENT_METHODS',
    setPaymentMethodId = 'SET_PAYMENT_METHOD_ID',
    resetPaymentMethods = 'RESET_PAYMENT_METHODS',
    setRenewGuard = 'SET_RENEW_GUARD',
    setRenewContactSales = 'SET_RENEW_CONTACT_SALES',
    setRenewError = 'SET_RENEW_ERROR',
    resetRenew = 'RESET_RENEW',
}
