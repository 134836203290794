import React, {useState} from 'react'
import {AddNewPaymentMethod} from './Add-New-Payment-Method'
import Banner from '@pluralsight/ps-design-system-banner'
import {SubLifeContent} from '../../Sub-Life-Content/SubLifeContent'
import styles from './no-saved-payment.module.scss'

export default function NoSavedPayments() {
    const [showUpmc, toggleUpmc] = useState(false)
    const showHideUpmc = () => {
        toggleUpmc(!showUpmc)
    }
    return (
        <SubLifeContent label={'Saved payment methods'}>
            <div className={styles.noSavedPaymentsBanner}>
                <Banner>
                    This account has no saved payment methods.&nbsp;
                    <button onClick={showHideUpmc} className={styles.addPaymentButton}>
                        {' '}
                        Add a payment method
                    </button>
                </Banner>
            </div>
            {showUpmc && <AddNewPaymentMethod savedPayment={false} />}
        </SubLifeContent>
    )
}
