import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import styles from './thank-you-page.module.scss'
import {config} from '../environment-config'
import {useParams} from 'react-router-dom'
import {ParamTypes} from '../../../definitions/param-types'
import {useExpandContext} from './Expand-Context'
import {ExpandResponse} from '../../../definitions/expand'
import {useAppContext} from '../useAppState'
import {PaymentMethod} from '../../../definitions/payment-methods'

export default function ThankYouPage() {
    const {state} = useExpandContext()
    const {state: appState} = useAppContext()
    const paymentMethodUsed = state.existingPaymentMethods.filter(
        (paymentMethod) => paymentMethod.id === state.paymentMethodId
    )[0]
    const expand = state.expand
    const expandSuccess = state.expand.loaded
    const firstName = appState.applicationData.user.firstName
    const peopleHost = config.people.url
    const {planId} = useParams<ParamTypes>()
    const inviteNewUsersHref = `${peopleHost}/plans/${planId}/people/org`
    const viewInvoiceHref = `${config.billing.url}/billing/history`
    const subHeadingText =
        state.quantityToAdd === 1
            ? `${state.quantityToAdd} subscription has`
            : `${state.quantityToAdd} subscriptions have`
    const productName = state.currentSubscription.productName
    return (
        <>
            {expandSuccess ? (
                <div className={styles.layout}>
                    <div className={styles.heading}>
                        Thank You, <span>{firstName}!</span>
                    </div>
                    <div className={styles.subHeading}>
                        {subHeadingText} been added to your <b>{productName}</b> Plan
                    </div>
                    <div className={styles.buttonRow}>
                        <div className={styles.button}>
                            <Button appearance={Button.appearances.stroke} href={viewInvoiceHref}>
                                View invoice
                            </Button>
                        </div>
                        <div className={styles.button}>
                            <Button href={inviteNewUsersHref}>Invite New Users</Button>
                        </div>
                    </div>
                    <hr className={styles.ruling} />
                    <OrderSummary
                        expand={expand}
                        quantityToAdd={state.quantityToAdd}
                        productName={productName}
                    />
                    <PaymentMethodFormat paymentMethod={paymentMethodUsed} />
                </div>
            ) : (
                <div>Something went wrong</div>
            )}
        </>
    )
}

function PaymentMethodFormat(props: {paymentMethod: PaymentMethod}) {
    if (props.paymentMethod.type === 'PayPal') {
        return (
            <div className={styles.note}>
                Charged to PayPal account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.details.paypalEmail}</span>
            </div>
        )
    } else {
        return (
            <div className={styles.note}>
                Charged To Visa ending in {props.paymentMethod.details.cardNumber.slice(-4)}
            </div>
        )
    }
}

function OrderSummary(props: {expand: ExpandResponse; quantityToAdd: number; productName: string}) {
    return (
        <div className={styles.orderSum}>
            <div style={{marginBottom: '10px'}}> Order summary</div>
            <div className={styles.row}>
                <div>Plan</div>
                <div>Subscriptions</div>
                <div>Price</div>
            </div>
            <div className={styles.row}>
                <p>{props.productName}</p>
                <p className={styles.second}>{props.quantityToAdd}</p>
                <p>
                    {props.expand.amount} {props.expand.currency}
                </p>
            </div>
            <hr className={styles.ruling1} />
            <div className={styles.midrow}>
                <div className={styles.first}>Subtotal</div>
                <div>
                    {props.expand.amountWithoutTax} {props.expand.currency}
                </div>
                <div className={styles.first}>
                    Tax<span className={styles.sym}>✝✝</span>
                </div>
                <div>
                    {props.expand.taxAmount} {props.expand.currency}
                </div>
            </div>
            <hr className={styles.ruling2} />
            <div className={styles.total}>
                <div>Total</div>
                <div>
                    {props.expand.amount} {props.expand.currency}
                </div>
            </div>
        </div>
    )
}
