import React from 'react'
import CAllToAction from './Call-To-Action'
import styles from './get-cta.module.scss'

export function getCTA(paymentMethod: any, planId: string) {
    let isDefault = paymentMethod.default
    return (
        <div>
            {isDefault ? (
                defaultPayment()
            ) : (
                <CAllToAction paymentMethod={paymentMethod} planId={planId} />
            )}
        </div>
    )
}

function defaultPayment() {
    return <div className={styles.defaultTag}>DEFAULT</div>
}
