import React, {useCallback, useEffect, useState} from 'react'
import {PaymentMethodConsumer} from '@pluralsight/upmc-react'
import {api} from '../../api/api'
import UPMCFatalError from './Upmc-Fatal-Error'
import styles from './Upmc-Component.module.scss'
import {createPaypalMethod} from '../Saved-Payment-Methods/Payments-Data/create-paypal-method/create-paypal-method'
import {useAppContext} from '../../useAppState'
import {setDefaultPaymentMethod} from '../Saved-Payment-Methods/Payments-Data/Set-Default-Payment-Method/set-default-payment-method'

function UpmcComponent(props: {
    planId: string
    currency: string
    handle: string
    onSuccess?: (payload: CreditCardSuccess | PaypalSuccess) => void
    handlePaymentMethodId?: (id: string) => any
    cta?: string
    authAmount?: number
    processPayment?: (process: boolean) => any
}) {
    const {state} = useAppContext()
    const [isLoading, setIsLoading] = useState(true)
    const [transactionToken, setTransactionToken] = useState('')
    const [getNewToken, toggleGetNewToken] = useState(0)
    const [iframeUrl, setIframeUrl] = useState('')
    const [currency, setCurrency] = useState(props.currency)
    const [fatalError, setFatalError] = useState('')

    const planId = props.planId

    useEffect(() => {
        if (transactionToken === '') {
            setIsLoading(true)
            api.get(`unified-payment-method/transaction`).then((res) => {
                if (res.status.success) {
                    setCurrency(props.currency)
                    setTransactionToken(res.data.transactionToken)
                    setIframeUrl(res.data.iframeUrl)
                    setIsLoading(false)
                } else {
                    setIsLoading(true)
                }
            })
        }
    }, [getNewToken, props.currency, setCurrency, transactionToken])

    const onError = useCallback((messageType: string, payload: UPMCError) => {
        setFatalError(payload.errorMessage)
        console.log('upmc error', messageType, payload.errorMessage)
    }, [])

    function requestNewToken() {
        transactionToken === '' && toggleGetNewToken(getNewToken ^ 1)
    }

    function resetCurrency(payload: CurrencyChange) {
        setCurrency(payload.newCurrencyValue)
    }

    async function onSuccess(successMessage: CreditCardSuccess | PaypalSuccess) {
        props.processPayment && props.processPayment(true)
        if (successMessage.paymentMethodType === 'PayPal') {
            const body = {
                paypalBaid: successMessage.paypalBaid,
                paypalEmail: successMessage.paypalEmail,
                address: `${successMessage.address.address1} ${successMessage.address.address2}`,
                city: successMessage.address.city,
                state: successMessage.address.state,
                country: successMessage.address.country,
                zipCode: successMessage.address.postal,
            }

            const response = await createPaypalMethod(
                state.applicationData.featureConfig.useMulesoftPaypalCreationFlag,
                planId,
                body
            )

            if (response.status.success) {
                setDefaultPaymentMethod(response.data.id, props.planId)
                !!props.handlePaymentMethodId && props.handlePaymentMethodId(response.data.id)
                props.processPayment && props.processPayment(false)
            } else {
                throw new Error(response.status.errorMessage)
            }
        } else {
            setDefaultPaymentMethod(successMessage.paymentMethodId, props.planId)
            !!props.handlePaymentMethodId &&
                props.handlePaymentMethodId(successMessage.paymentMethodId)
            props.processPayment && props.processPayment(false)
        }
    }

    function messageHandler(
        messageType: string,
        payload: CreditCardSuccess | PaypalSuccess | Error | CurrencyChange
    ) {
        switch (messageType) {
            case 'SUCCESS':
                if (!!props.onSuccess) {
                    props.onSuccess(payload as CreditCardSuccess | PaypalSuccess)
                } else {
                    onSuccess(payload as CreditCardSuccess | PaypalSuccess)
                }
                break
            case 'INFO_ZUORA_FORM_ERROR':
                break
            case 'REQUEST_NEW_TOKEN':
                requestNewToken()
                break
            case 'CURRENCY_CHANGE':
                resetCurrency(payload as CurrencyChange)
                break
            default:
                onError(messageType, (payload as unknown) as UPMCError)
        }
    }

    return (
        <div className={`${isLoading ? styles.loadingDecorator : styles.notLoadingDecorator}`}>
            <UPMCFatalError fatalError={fatalError} />
            {!isLoading && (
                <PaymentMethodConsumer
                    iframeUrl={iframeUrl}
                    token={transactionToken}
                    messageHandler={messageHandler}
                    authAmount={!!props.authAmount ? props.authAmount : 1}
                    currency={currency}
                    cta={props.cta}
                    theme={'app'}
                    handle={props.handle}
                    planId={planId}
                />
            )}
        </div>
    )
}

export default React.memo(UpmcComponent)
