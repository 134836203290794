import React, {useEffect, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useAppContext} from '../useAppState'
import {SubLifeContent} from '../Sub-Life-Content/SubLifeContent'
import Link from '@pluralsight/ps-design-system-link'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import styles from './admin-plan-list.module.scss'

export function AdminPlanList() {
    const {state, actions} = useAppContext()
    const adminPlans = state.applicationData.planInfo.admin.map((planId) => {
        const displayName =
            state.planDisplayNames[planId] && state.planDisplayNames[planId].displayName
        return {planId, displayName}
    })
    let [dataLoading, setDataLoading] = useState(true)
    useEffect(() => {
        const planIds = state.applicationData.planInfo.admin
        const planDisplayNamesLoaded =
            planIds.filter(
                (planId) =>
                    !!state.planDisplayNames[planId] && !state.planDisplayNames[planId].pending
            ).length === planIds.length
        if (planDisplayNamesLoaded) {
            setDataLoading(false)
        } else {
            actions.loadPlanDisplayName(planIds)
        }
    }, [state.applicationData.planInfo.admin, state.planDisplayNames, actions])
    return dataLoading ? (
        <CenteredLoadingDecorator />
    ) : (
        <SubLifeContent label='Group plans'>
            <div>{adminPlans.map(planLink)}</div>
        </SubLifeContent>
    )
}

function planLink(plan: {planId: string; displayName: string}) {
    return (
        <div className={styles.planRow} key={plan.planId}>
            <div>{plan.displayName}</div>
            <div>
                <Link>
                    <RouterLink to={`/subscription/plans/${plan.planId}`}>View plan</RouterLink>
                </Link>
            </div>
        </div>
    )
}
