import React, {useEffect, useState} from 'react'
import {useAppContext} from '../../useAppState'
import Table from '@pluralsight/ps-design-system-table'
import {getPayPalDetails} from './get-paypal-details'
import {getCTA} from './get-cta'
import {getCreditCardDetails} from './get-credit-card-details'
import {api} from '../../api/api'
import {AddNewPaymentMethod} from './Add-New-Payment-Method'
import {useParams} from 'react-router'
import NoSavedPayments from './no-saved-payment'
import {SubLifeContent} from '../../Sub-Life-Content/SubLifeContent'
import {ParamTypes} from '../../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../../Loading-Decorator/Centered-Loading-Decorator'
import styles from './payment-methods-css-layouts.module.scss'

export default function SavedPaymentMethods(prop: any) {
    const {state, setters} = useAppContext()
    const hasPaymentMethods: boolean =
        state.paymentMethods.savedPayments &&
        state.paymentMethods.savedPayments.length > 0 &&
        state.paymentMethods.savedPayments[0] !== undefined
    const paymentMethods = hasPaymentMethods
        ? setDefaultOnTop(state.paymentMethods.savedPayments)
        : []
    const {planId} = useParams<ParamTypes>()
    const [paymentsDataInitialized, setInitialized] = useState(false)
    // eslint-disable-next-line
    useEffect(getPaymentMethods, [state.UpdatingSavedPayments])
    function getPaymentMethods() {
        api.get(`plans/${planId}/payment-methods`).then((res) => {
            if (res.status.success) {
                setters.setBusinessData(res.data.paymentMethods)
                setInitialized(true)
            }
        })
    }
    return (
        <div>
            {paymentsDataInitialized && !state.UpdatingSavedPayments ? (
                hasPaymentMethods ? (
                    listPayments(paymentMethods, planId)
                ) : (
                    <NoSavedPayments />
                )
            ) : (
                <CenteredLoadingDecorator centerVertically={true} />
            )}
        </div>
    )
}

function setDefaultOnTop(paymentMethods: any) {
    try {
        const defaultIndex = paymentMethods.findIndex((method: any) => method.default === true)
        let temp = paymentMethods[0]
        paymentMethods[0] = paymentMethods[defaultIndex]
        paymentMethods[defaultIndex] = temp
    } finally {
        return paymentMethods
    }
}

function listPayments(paymentMethods: any, planId: any) {
    const paymentList = getPaymentList(paymentMethods, planId)
    return (
        <SubLifeContent label={'Saved payment methods'}>
            <div className={styles.content}>
                <Table>{[...paymentList]}</Table>
                <AddNewPaymentMethod savedPayment={true} />
            </div>
        </SubLifeContent>
    )
}

function getPaymentList(paymentMethods: any, planId: string) {
    return paymentMethods.map((method: any) => {
        if (method.type === 'PayPal') {
            return getLinkedPayPals(method, planId)
        } else {
            return getLinkedCreditCards(method, planId)
        }
    })
}

function getLinkedPayPals(paypalMethod: any, planId: string) {
    return (
        <Table.Row key={paypalMethod.id}>
            <Table.Cell>{getPayPalDetails(paypalMethod)}</Table.Cell>
            <Table.Cell align={Table.alignments.right}>{getCTA(paypalMethod, planId)}</Table.Cell>
        </Table.Row>
    )
}

function getLinkedCreditCards(creditCard: any, planId: string) {
    return (
        <Table.Row key={creditCard.id}>
            <Table.Cell>{getCreditCardDetails(creditCard)}</Table.Cell>
            <Table.Cell align={Table.alignments.right}>{getCTA(creditCard, planId)}</Table.Cell>
        </Table.Row>
    )
}
