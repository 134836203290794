import React, {useEffect} from 'react'
import {useAppContext} from '../useAppState'

export default function Prism(props: any) {
    const {state, setters} = useAppContext()
    // eslint-disable-next-line
    useEffect(initPrism, [state.hasPrismData, state.PrismInited])
    const adminPlanId =
        state.applicationData?.planInfo.admin.length > 0 && state.applicationData.planInfo.admin[0]
    const firstPlanId = !!adminPlanId ? adminPlanId : undefined
    function initPrism() {
        try {
            if (!window.Prism.isInited() && state.hasPrismData && !state.PrismInited) {
                window.Prism.init({
                    currentPlanId: firstPlanId,
                    userHandle: state.applicationData.user.handle,
                    launchDarklyClientId: state.applicationData.config.launchDarklyClientId,
                    userName: `${state.applicationData.user.firstName} ${state.applicationData.user.lastName}`,
                    userEmail: state.applicationData.user.email,
                    currentNavLinkId: 'admin-tools-account',
                }).then(() => {
                    setters.prismInited(true)
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div id='ps-container'>
            <div id='ps-chrome' />
            <main id='ps-main' tabIndex={-1}>
                {props.children}
            </main>
        </div>
    )
}
