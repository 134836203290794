import React from 'react'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'
import Dialog from '@pluralsight/ps-design-system-dialog'
import Button from '@pluralsight/ps-design-system-button'
import {config} from '../../environment-config'
import './pricing.scss'

const Pricing = (props: {
    subscription?: number
    isOpen?: boolean
    previousProduct?: string
    errorMessage?: string
    requestType?: string
}) => {
    const checkoutUrl = `${config.www.url}/buy?sku=`
    const pricingUrl = `${config.www.url}/pricing/skills`
    const headingText: any = {
        Noproductsfound: ' Upgrade your plan',
        expired: 'Your subscription has ended. Re-subscribe to keep your team up-to-date.',
        expiredproductcancheckout:
            'Your subscription has ended. Resubscribe to keep your team up-to-date.',
        pilotsubscription:
            "Your team is currently in a trial. After you purchase a team plan, you'll be able to purchase the add-on you're looking for.",
        Nointermbaseproduct:
            'Your subscription has ended. Please resubscribe before purchasing add-ons.',
        expiredsubscription:
            'Your subscription has ended. Please resubscribe before purchasing add-ons.',
        FreebaseProduct:
            "Your team is currently on a limited plan. After you purchase a team plan, you'll be able to purchase the add-on you're looking for.",
        productprice0: 'Upgrade your plan',
    }
    const text = props.errorMessage && headingText[props.errorMessage.replace(/ /g, '')]
    const handleRedirect = (redirect: string) => {
        switch (redirect) {
            case 'starter':
                return (window.location.href = `${checkoutUrl}STARTER-A&quantity=${props.subscription}`)
            case 'professional':
                return (window.location.href = `${checkoutUrl}PROFESSIONAL-SG&quantity=${props.subscription}`)
            case 'enterprise':
                return (window.location.href = `${checkoutUrl}ENTERPRISE-NO-MENTORING-SG&quantity=${props.subscription}`)
        }
    }
    let product = ''
    if (
        !(props.requestType === 'upsell' && props.errorMessage === 'pilot subscription') &&
        props.errorMessage !== 'No products found' &&
        props.errorMessage !== 'product price 0' &&
        props.errorMessage !== 'Free base Product'
    ) {
        if (props.previousProduct === 'Business Starter') {
            product = 'starter'
        } else if (props.previousProduct === 'Business Professional') {
            product = 'professional'
        } else if (props.previousProduct === 'Business Enterprise') {
            product = 'enterprise'
        } else if (props.previousProduct === 'Enterprise GCP (Limited Library)') {
            product = 'enterprise'
        }
    }
    let getStartedButtonText =
        props.errorMessage === 'No products found' ||
        props.errorMessage === 'product price 0' ||
        props.errorMessage === 'pilot subscription' ||
        props.errorMessage === 'Free base Product'
    return (
        <div>
            {props.requestType === 'upsell' ? <PluralSightHeader className='mainPage' /> : null}
            <Dialog modal>
                <div className={text && text.length > 30 ? 'plan_head1' : 'plan_head2'}>
                    <p className='plan_title'>
                        {props.errorMessage && props.requestType === 'upgrade' ? (
                            props.errorMessage === 'pilot subscription' ? (
                                <p className='plan_head3'>Upgrade your plan</p>
                            ) : props.errorMessage === 'expired subscription' ? (
                                <p className='plan_head1'>
                                    Your subscription has ended. Re-subscribe to keep your team
                                    up-to-date.
                                </p>
                            ) : (
                                text
                            )
                        ) : (
                            text
                        )}
                    </p>
                </div>
                <div className='pricing_flex-container'>
                    <div className='pricing_section'>
                        <div className='previous_plan_text'>
                            {product === 'starter' && (
                                <div className='previous_plan'>PREVIOUS PLAN</div>
                            )}
                        </div>
                        <h2 className='pricing_title'>Starter</h2>
                        <div className='pricing_wrapper'>
                            <span className='pricing_curr'>$</span>
                            <span className='pricing_value'>399</span>
                        </div>
                        <div className='pricing_year'>per user/year</div>
                        <div className='pricing_subs'>
                            Upskill your team with our core <br /> library.
                        </div>
                        <div className='btn_view'>
                            <Button onClick={() => handleRedirect('starter')}>
                                {product === 'starter' ? 'Continue' : 'Get Started'}
                            </Button>
                            <a href={`${pricingUrl}`} className='anchor_tag'>
                                Learn more
                            </a>
                        </div>
                    </div>
                    <div className='pricing_section'>
                        <div className='previous_plan_text'>
                            {product === 'professional' && (
                                <div className='previous_plan'>PREVIOUS PLAN</div>
                            )}
                        </div>
                        <h2 className='pricing_title'>Professional</h2>
                        <div className='pricing_wrapper'>
                            <span className='pricing_curr'>$</span>
                            <span className='pricing_value'>579</span>
                        </div>
                        <div className='pricing_year'>per user/year</div>
                        <div className='pricing_subs'>
                            Basic reporting and user <br /> analytics for teams.
                        </div>
                        <div className='btn_view'>
                            <Button onClick={() => handleRedirect('professional')}>
                                {getStartedButtonText
                                    ? 'Get Started'
                                    : product === 'professional'
                                    ? 'Continue'
                                    : 'Upgrade'}
                            </Button>
                            <a href={`${pricingUrl}`} className='anchor_tag'>
                                Learn more
                            </a>
                        </div>
                    </div>
                    <div className='pricing_section'>
                        <div className='previous_plan_text'>
                            {product === 'enterprise' && (
                                <div className='previous_plan'>PREVIOUS PLAN</div>
                            )}
                        </div>
                        <h2 className='pricing_title'>Enterprise</h2>
                        <div className='pricing_wrapper'>
                            <span className='pricing_curr'>$</span>
                            <span className='pricing_value'>779</span>
                        </div>
                        <p className='pricing_year'>per user/year</p>
                        <div className='pricing_subs1'>
                            Flexibility and advanced <br /> analytics for the enterprise.
                        </div>
                        <div className='btn_view'>
                            <Button onClick={() => handleRedirect('enterprise')}>
                                {getStartedButtonText
                                    ? 'Get Started'
                                    : product === 'enterprise'
                                    ? 'Continue'
                                    : 'Upgrade'}
                            </Button>
                            <a href={`${pricingUrl}`} className='anchor_tag'>
                                Learn more
                            </a>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Pricing
