import React from 'react'
import Collapsible from '@pluralsight/ps-design-system-collapsible'
import styles from './drawer.module.scss'

interface Props {
    isOpen: boolean
    children: any
    base: any
}

export default function Drawer(props: Props) {
    let open = props.isOpen
    return (
        <div className={styles.drawerContainer}>
            {props.base}
            <Collapsible isOpen={open}>{props.children}</Collapsible>
        </div>
    )
}
