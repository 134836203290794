import * as React from 'react'
import {useRenewState} from '../Renew-Context/renew-context'
import '../styles/renew-order-summary.scss'
import {useState, useEffect} from 'react'
import {formatPrice} from '../../common/utils/formatPrice'
export default function RenewOrderSummary() {
    const {state} = useRenewState()
    const [maxWidth] = useState(760)
    const [collapsed, setCollapsed] = useState(window.innerWidth < maxWidth ? true : false)
    const [mobileView, setMobileView] = useState(window.innerWidth < maxWidth ? true : false)
    const currency = state.renew.data.currency
    const lineItems = state.renew.data.lineItems
    const toggleColllapse = (e: any) => {
        setCollapsed(!collapsed)
    }
    useEffect(() => {
        function handleResize() {
            setMobileView(window.innerWidth < maxWidth ? true : false)
            setCollapsed(window.innerWidth < maxWidth ? true : false)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })
    return (
        <div className='orderSummary'>
            {mobileView ? (
                <h4 className='mobileHeader' onClick={toggleColllapse}>
                    Order Summary <span className={`arrow-${collapsed ? 'up' : 'down'}`}></span>
                    <span className='amount'>
                        {formatPrice(state.renew.data.amount)} {currency}
                    </span>
                    <hr className={`${collapsed ? 'hide-line' : ''}`}></hr>
                </h4>
            ) : (
                <h4 className='header'>Order summary</h4>
            )}
            {!collapsed || !mobileView ? (
                <>
                    <div className='summary'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Plan</th>
                                    <th>Subscriptions</th>
                                    <th style={{textAlign: 'right'}}>
                                        Price<sup> ✝</sup>
                                    </th>
                                </tr>
                                {lineItems.map((lineItem) => {
                                    return formatLineItemProduct(lineItem, currency)
                                })}
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className='subTotal'>
                        <div className='row spacer'>
                            <div className='label'>Subtotal</div>
                            <div className='values'>
                                {formatPrice(state.renew.data.amount - state.renew.data.taxAmount)}{' '}
                                {currency}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='label'>
                                Estimated Tax<sup> ✝✝</sup>
                            </div>
                            <div className='values'>
                                {formatPrice(state.renew.data.taxAmount)} {currency}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='total'>
                        <div className='amountLabel'>Total due today</div>
                        <div className='amount'>
                            {formatPrice(state.renew.data.amount)} {currency}
                        </div>
                    </div>
                </>
            ) : (
                <div></div>
            )}
        </div>
    )
}

function formatLineItemProduct(lineItem: any, currency: string) {
    return (
        <tr key={lineItem.productRatePlanId}>
            <td>{lineItem.productName}</td>
            <td>{lineItem.quantity}</td>
            <td style={{textAlign: 'right'}}>
                {formatPrice(lineItem.unitPrice)} {currency}
            </td>
        </tr>
    )
}
