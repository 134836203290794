import React from 'react'
import ExpiredPlan from './Expired-Plan'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'
import GenericExpandError from './Generic-Expand-Error'
import CpqExpand from '../../Cpq-Expand/Cpq-Expand'
import {ExpandErrorData, ExpandErrorMessage} from '../../../../definitions/expand'
import UnpaidInvoice from '../../common/Unpaid-Invoice/Unpaid-Invoice'
import {useExpandContext} from '../Expand-Context'
import styles from './Blocked-Expand-Routes.module.scss'

export default function BlockedExpandRoutes(props: {
    errorMessage: ExpandErrorMessage
    planId: string
    guardData: ExpandErrorData
}) {
    return (
        <div>
            <div className={styles.logo}>
                <PluralSightHeader />
            </div>
            <div className={styles.expandErrorContent}>
                <ExpandError
                    errorMessage={props.errorMessage}
                    planId={props.planId}
                    guardData={props.guardData}
                />
            </div>
        </div>
    )

    function ExpandError(props: {
        errorMessage: ExpandErrorMessage
        planId: string
        guardData: ExpandErrorData
    }) {
        const {setters} = useExpandContext()
        const redirectSalesForm = () => {
            setters.setHasError('sales contact form redirect')
        }
        switch (props.errorMessage) {
            case 'expired product can checkout':
                return <ExpiredPlan />
            case 'quantity too high':
            case 'expired product cannot checkout':
            case 'cancelled subscription':
            case 'non-annual subscription terms':
            case 'product option digitalExpand set to false':
            case 'sfdc allowExpansion set to false':
            case 'unable to retrieve transaction permissions':
            case 'has future products':
            case 'multiple in term products':
            case 'multiple in term subscriptions':
            case 'sales contact form redirect':
            case 'product price 0 or less':
                return <CpqExpand />
            case 'transaction will put quantity above limit':
                return <CpqExpand cancelTo={`/subscription/transaction/expand/${props.planId}`} />
            case 'unpaid invoice':
                return (
                    <UnpaidInvoice
                        invoiceIds={props.guardData}
                        message={'adding subscriptions to your plan.'}
                        redirectSalesForm={redirectSalesForm}
                    />
                )
            case 'external customer source':
                return <GenericExpandError /> //TODO explanation + link to account page?
            case 'No products found':
            default:
                return <GenericExpandError />
        }
    }
}
