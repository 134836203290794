import React from 'react'
import {useAppContext} from '../useAppState'
import B2bStatic from './B2b-static'
import B2bDataCapture from './B2b-data-capture'

export default function B2bUpgradeLanding() {
    const {state} = useAppContext()
    const b2bDataCapture = state.applicationData.featureConfig.b2bDataCapture
    return b2bDataCapture ? <B2bDataCapture /> : <B2bStatic />
}
