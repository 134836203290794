import React from 'react'
import {NavLink as RouterLink, useParams} from 'react-router-dom'
import styles from './B2b-Nav-Bar.module.scss'
import {ParamTypes} from '../../../definitions/param-types'

export default function B2bNavBar() {
    const {planId} = useParams<ParamTypes>()
    return (
        <div>
            <div className={styles.TabLayout}>
                <RouterLink
                    exact
                    to={`/subscription/plans/${planId}`}
                    className={styles.navTabs}
                    activeClassName={styles.activeTab}
                >
                    Plan
                </RouterLink>
                <RouterLink
                    exact
                    to={`/subscription/plans/${planId}/integrations`}
                    className={styles.navTabs}
                    activeClassName={styles.activeTab}
                >
                    Integrations
                </RouterLink>
                <RouterLink
                    exact
                    to={`/subscription/plans/${planId}/billing`}
                    className={styles.navTabs}
                    activeClassName={styles.activeTab}
                >
                    Invoices
                </RouterLink>
            </div>
        </div>
    )
}
