import React from 'react'
import styles from './Expand-Order-Summary.module.scss'
import {useExpandContext} from '../Expand-Context'
import Drawer from '../../common/Custom-Drawer/drawer'
import {formatPrice} from '../../common/utils/formatPrice'
import SmallLoadingDecorator from '../../common/Small-Loading-Decorator/small-loading-decorator'

export function ExpandOrderSummary() {
    const {state} = useExpandContext()

    return (
        <div
            className={
                state.discountAmount === 0
                    ? styles.orderSummaryBase
                    : styles.orderSummaryBaseWithDiscount
            }
        >
            <div>
                <h4 className={styles.orderSummaryHeader}>Order summary</h4>
            </div>
            <div>
                <ExpandOrderSummaryBody />
            </div>
        </div>
    )
}

export function ExpandSummaryFooter() {
    const {setters, state} = useExpandContext()

    function toggleDrawer() {
        setters.setIsMobileOrderSummaryOpen(!state.isMobileOrderSummaryOpen)
    }

    function TopRow(props: {price: number; currency: string}) {
        return (
            <div className={styles.topRow} onClick={() => toggleDrawer()}>
                <h3>
                    Order summary{' '}
                    <span
                        className={`${styles.chevron} ${
                            state.isMobileOrderSummaryOpen ? styles.openCaret : styles.closedCaret
                        }`}
                    />
                </h3>
                <div>{props.price === 0 ? '--' : `${props.price + ' ' + props.currency}`}</div>
            </div>
        )
    }

    return (
        <div className={styles.expandFooter}>
            <Drawer
                isOpen={state.isMobileOrderSummaryOpen}
                base={<TopRow price={state.amount} currency={state.currency} />}
                children={
                    <div className={styles.footerTable}>
                        <hr
                            className={`${styles.expandFooterHrMargin} ${styles.horizontalRuleColor}`}
                        />
                        <ExpandOrderSummaryBody />
                    </div>
                }
            />
        </div>
    )
}

function ExpandOrderSummaryBody() {
    const {state} = useExpandContext()

    return (
        <OrderSummaryBody
            numberOfSubscriptions={state.quantityToAdd}
            pricePerLicence={
                state.subTotal ? state.subTotal / state.quantityToAdd : state.pricePerLicense
            }
            subtotal={state.subTotal}
            taxAmount={state.taxAmount}
            totalPrice={state.amount}
            currency={state.currentSubscription.currency}
            productName={state.currentSubscription.productName}
            discountAmount={state.discountAmount}
            proratedPriceLoaded={state.proratedPriceLoaded}
        />
    )
}

function OrderSummaryBody(props: {
    numberOfSubscriptions: number
    pricePerLicence?: number
    totalPrice?: number
    taxAmount?: number
    subtotal?: number
    currency: string
    productName: string
    discountAmount: number
    proratedPriceLoaded: boolean
}) {
    return (
        <>
            <div className={`${styles.lowOnDarkText} ${styles.amountPickerRow}`}>
                <div>
                    <div id={'product'} className={styles.upperCase}>
                        Product
                    </div>
                    <div className={styles.productName} aria-labelledby={'product'}>
                        {props.productName}
                    </div>
                </div>
                <div>
                    <div className={`${styles.textAlignCenter} ${styles.upperCase}`}>
                        Subscriptions
                    </div>
                    <div>{props.numberOfSubscriptions}</div>
                </div>
                <div className={styles.textAlignRight}>
                    <div id={'price'} className={styles.upperCase}>
                        Price<span className={styles.daggers}>✝</span>
                    </div>

                    <div className={styles.productName}>
                        <span aria-labelledby={'price'}>
                            {props.pricePerLicence && formatPrice(props.pricePerLicence)}{' '}
                            {props.currency}
                        </span>
                    </div>
                </div>
            </div>
            <hr className={`${styles.firstHrMargin} ${styles.horizontalRuleColor}`} />
            {props.discountAmount ? (
                <div className={styles.row}>
                    <div id={'discounts'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                        Discounts
                    </div>
                    <div className={styles.highOnDarkText}>
                        <span aria-labelledby={'discounts'}>
                            {props.discountAmount === 0
                                ? '--'
                                : `${props.discountAmount} ${props.currency}`}
                        </span>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className={styles.row}>
                <div id={'subtotal'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                    Subtotal
                </div>
                <div className={styles.highOnDarkText}>
                    {props.proratedPriceLoaded ? (
                        <SmallLoadingDecorator />
                    ) : (
                        <span aria-labelledby={'subtotal'}>
                            {props.subtotal === 0 ? '--' : `${props.subtotal} ${props.currency}`}
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.row}>
                <div id={'tax'} className={`${styles.lowOnDarkText} ${styles.upperCase}`}>
                    Estimated tax<span className={styles.daggers}>✝✝</span>
                </div>
                <div className={styles.highOnDarkText}>
                    <span aria-labelledby={'tax'}>
                        {props.taxAmount === 0 ? '--' : `${props.taxAmount} ${props.currency}`}
                    </span>
                </div>
            </div>
            <hr className={`${styles.secondHrMargin} ${styles.horizontalRuleColor}`} />
            <div className={styles.totalRow}>
                <div id={'total'}>Total due today</div>
                <div className={styles.textAlignRight}>
                    <span aria-labelledby={'total'}>
                        {props.totalPrice === 0 ? '--' : `${props.totalPrice} ${props.currency}`}
                    </span>
                </div>
            </div>
        </>
    )
}
