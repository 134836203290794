import React, {useState} from 'react'
import {setDefaultPaymentMethod} from './Payments-Data/Set-Default-Payment-Method/set-default-payment-method'
import Dialog from '@pluralsight/ps-design-system-dialog'
import {deletePaymentMethod} from './Payments-Data/Delete-Payment/delete-payment-method'
import Table from '@pluralsight/ps-design-system-table'
import {getPayPalDetails} from './get-paypal-details'
import {getCreditCardDetails} from './get-credit-card-details'
import {useAppContext} from '../../useAppState'
import Button from '@pluralsight/ps-design-system-button'
import styles from './Call-To-Action.module.scss'
import paymentStyles from './payment-methods-css-layouts.module.scss'

export default function CAllToAction(prop: any) {
    const [isOpen, toggle] = useState(false)
    const {setters} = useAppContext()
    const setDefault = () => {
        setters.setUpdatePaymentsFlag(true)
        setDefaultPaymentMethod(prop.paymentMethod.id, prop.planId).then((res: any) => {
            if (res.status.success) {
                setters.setUpdatePaymentsFlag(false)
            }
        })
    }
    const deletePayment = () => {
        toggle(!isOpen)
        setters.setUpdatePaymentsFlag(true)
        deletePaymentMethod(prop.paymentMethod.id, prop.planId).then((res: any) => {
            if (res.success) {
                setters.setUpdatePaymentsFlag(false)
            }
        })
    }
    function toggleModal() {
        toggle(!isOpen)
    }
    return (
        <div className={paymentStyles.ctaBlock}>
            <div>
                <button onClick={setDefault} className={paymentStyles.ctaButton}>
                    Set as default
                </button>
            </div>
            <div aria-hidden={isOpen}>
                <button onClick={toggleModal} className={paymentStyles.ctaButton}>
                    Delete
                </button>
            </div>
            {isOpen && (
                <Dialog
                    modal
                    onClose={toggleModal}
                    aria-label={'Delete Payment Method'}
                    className={styles.deleteModal}
                >
                    <div className={styles.modalContainer}>
                        <div>Delete payment method</div>
                        {paymentMethodToDelete(prop.paymentMethod)}
                        <div className={styles.modalButtons}>
                            <Button onClick={toggleModal} appearance={Button.appearances.secondary}>
                                Cancel
                            </Button>
                            <span></span>
                            <Button onClick={deletePayment}>Confirm Delete</Button>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    )
}
function paymentMethodToDelete(paymentMethod: any) {
    return (
        <Table>
            <Table.Row>
                <Table.Cell>
                    {paymentMethod.type === 'PayPal'
                        ? getPayPalDetails(paymentMethod)
                        : getCreditCardDetails(paymentMethod)}
                </Table.Cell>
            </Table.Row>
        </Table>
    )
}
