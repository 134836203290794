import {QuantitySelection} from '../../common/Quantity-Selection/Quantity-Selection'
import {useExpandContext} from '../Expand-Context'
import React from 'react'
import {ExpandAnalyticsEvents} from '../expand-analytics-events'

export function ExpandQuantitySelector() {
    let {state, setters, actions} = useExpandContext()

    function submitQuantity(quantity: number) {
        setters.setQuantity(quantity)
        setters.setIsQuantityOpen(false)
        setters.setIsPaymentOpen(true)
        fireAnalytics()
    }

    function fireAnalytics() {
        actions.trackAnalyticsEvent(state, ExpandAnalyticsEvents.quantity)
    }

    function handleQuantityChange(event: any) {
        const quantity = Number(event.target.value)
        if (quantity >= 0) {
            setters.setQuantity(quantity)
        }
    }

    function onCheck(event: any, checked: boolean, value: string, name: string) {
        setters.setHasAgreedToTerms(checked)
    }

    function contactSales() {
        setters.setHasError('transaction will put quantity above limit')
    }

    return (
        <QuantitySelection
            continueEnabled={true}
            quantityToAdd={state.quantityToAdd}
            currentSubscriptionCount={state.currentSubscription.currentSubscriptionQuantity}
            updateQuantity={submitQuantity}
            isOpen={state.isQuantityOpen}
            onCheck={onCheck}
            handleQuantityChange={handleQuantityChange}
            hasAgreedToTerms={state.hasAgreedToTerms}
            resetToQuantitySelection={actions.resetToQuantitySelection}
            contactSales={() => contactSales()}
        />
    )
}
