import React from 'react'
import {config} from '../../environment-config'
import Button from '@pluralsight/ps-design-system-button'
import styles from './csta-cta.module.scss'

export function CstaCta() {
    const upgradeHref = `${config.www.url}/pricing/redirect-to-checkout?sku=IND-Y-PREM-CSTA`
    return (
        <div className={styles.cstaBorder}>
            <div className={styles.titleCss}>Upgrade to Premium for only $99</div>
            <div className={styles.container}>
                <div className={styles.textContent}>
                    As a member of Computer Science Teachers Association, we'd like to offer you
                    over 60% off Premium access to thousands of expert-led courses across hundreds
                    of topics.
                </div>
                <div className={styles.button}>
                    <Button appearance={Button.appearances.primary} href={upgradeHref}>
                        Upgrade now
                    </Button>
                </div>
            </div>
        </div>
    )
}
