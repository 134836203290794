export const config = {
    www: {
        url: process.env.REACT_APP_WWW_URL,
        goCode: process.env.REACT_APP_WWW_GO_CODE,
    },
    billing: {
        url: process.env.REACT_APP_BILLING_URL,
    },
    plans: {
        url: process.env.REACT_APP_PLANS_URL,
    },
    plansApiHost: {
        url: process.env.REACT_APP_PLANS_API_HOST,
    },
    people: {
        url: process.env.REACT_APP_PEOPLE_HOST,
    },
    upsellProductOptionId: {
        productOptionId: process.env.REACT_APP_UPSELL_PRODUCT_OPTION_ID,
    },
}
