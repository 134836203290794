import * as React from 'react'
import {createContext, useContext, useReducer} from 'react'
import {PaymentMethod, SavedPaymentMethods} from '../../../../definitions/payment-methods'
import {
    ModifyGuardResponse,
    ModifyPayload,
    Modify,
    ModifyErrorMessage,
} from '../../../../definitions/modify'
import {api} from '../../api/api'
import {ModifySetters} from '../modify-setters'
import TransactionErrorBoundary from '../../common/Transaction-Error-Boundary/transaction-error-boundary'

const ModifyContext = createContext({})

export default function ModifyStateProvider(props: any) {
    const initialState: ModifyState = {
        preview: {
            data: {} as Modify,
            isInitialized: false,
        },
        payment: {
            paymentMethodId: null,
            savedPayments: [],
            paymentMethodsInitialized: false,
        },
        processingModify: false,
        modifyGuard: {
            canModify: null,
            reason: '' as ModifyErrorMessage,
            loadingGuard: true,
            data: null,
            subscriptionNumber: null,
            planId: '',
            maxQuantity: 1,
        },
        result: {
            processed: false,
            data: {} as Modify,
        },
        modifyError: false,
        subscriptionsToAdd: 1,
    }
    const ModifyStateReducer = (state: ModifyState, action: {type: string; data: any}) => {
        const {
            initializeModify,
            setProcessing,
            setResult,
            loadPaymentMethods,
            setPaymentMethodId,
            resetPaymentMethods,
            setModifyGuard,
            setModifyError,
            resetModify,
            updateSubscriptionCount,
        } = ModifySetters
        switch (action.type) {
            case resetModify:
                return {
                    ...state,
                    ...initialState,
                }
            case initializeModify:
                return {
                    ...state,
                    preview: {
                        data: action.data,
                        isInitialized: true,
                    },
                } as any
            case setProcessing:
                return {
                    ...state,
                    processingModify: true,
                }
            case setResult:
                return {
                    ...state,
                    result: {
                        processed: true,
                        data: action.data,
                    },
                }
            case loadPaymentMethods:
                return {
                    ...state,
                    payment: {
                        savedPayments: action.data.paymentMethods.savedPayments,
                        paymentMethodsInitialized: true,
                    },
                }
            case setPaymentMethodId:
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentMethodId: action.data,
                    },
                }
            case resetPaymentMethods:
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentMethodId: null,
                    },
                }
            case setModifyGuard:
                return {
                    ...state,
                    modifyGuard: {
                        canModify: action.data.canModify,
                        planId: action.data.planId,
                        reason: action.data.message,
                        loadingGuard: false,
                        data: action.data.data,
                        subscriptionNumber: action.data.subscriptionNumber,
                        maxQuantity: action.data.maxQuantity,
                    },
                }
            case setModifyError:
                return {
                    ...state,
                    modifyError: true,
                    result: {
                        processed: true,
                    },
                }
            case updateSubscriptionCount:
                return {
                    ...state,
                    subscriptionsToAdd: action.data,
                }
        }
    }

    const getModifyActions: any = React.useMemo(() => {
        return {
            loadPaymentMethods: (planId: string) => {
                return api.get(`plans/${planId}/payment-methods`).then(validateResponse)
            },
            isModifyAllowed: async (planId: string, productOptionId: string) => {
                return api
                    .get(`plans/${planId}/product/${productOptionId}/modify-guard`)
                    .then(validateResponse)
            },
            modify: (planId: string, body: ModifyPayload) => {
                return api.post(`plans/${planId}/modify`, body).then(validateResponse)
            },
        }
    }, [])

    function validateResponse(response: any) {
        if (response.status.success) {
            return response.data
        } else {
            throw new Error(response.status.errorMessage)
        }
    }

    const context = {
        reducer: useReducer(ModifyStateReducer, initialState),
        actions: getModifyActions,
    }
    return (
        <TransactionErrorBoundary>
            <ModifyContext.Provider value={context}>{props.children}</ModifyContext.Provider>
        </TransactionErrorBoundary>
    )
}

export function useModifyState(): {state: ModifyState; setter: any; actions: ModifyActions} {
    const {reducer, actions} = useContext(ModifyContext) as any
    const [state, setter] = reducer
    return {state, setter, actions}
}

interface ModifyState {
    preview: {
        data: Modify
        isInitialized: boolean
    }
    processingModify: boolean
    payment: {
        paymentMethodId: string | null
        savedPayments: PaymentMethod[]
        paymentMethodsInitialized: boolean
    }
    modifyGuard: {
        canModify: boolean | null
        reason: ModifyErrorMessage
        data: any
        loadingGuard: boolean
        subscriptionNumber: string | null
        planId: string
        maxQuantity: number
    }
    result: {
        processed: boolean
        data: Modify
    }
    subscriptionsToAdd: number
    modifyError: boolean
}

interface ModifyActions {
    modify: (planId: string, body: ModifyPayload) => Promise<Modify>
    loadPaymentMethods: (planId: string) => Promise<SavedPaymentMethods>
    isModifyAllowed: (planId: string, productOptionId: string) => Promise<ModifyGuardResponse>
}
