export function identify(handle: string) {
    const {segment, facade} = getAnalytics()
    try {
        facade && facade.identify(handle, {})
        segment && segment.identify && segment.identify(handle, {})
    } catch (e) {
        console.log('identify call failed', e)
    }
}

export function pageView() {
    const {segment, facade} = getAnalytics()
    try {
        facade && facade.page()
        segment && segment.page && segment.page({})
    } catch (e) {
        console.log('pageView call failed', e)
    }
}

export function track(eventName: string, data: {}) {
    const {facade} = getAnalytics()
    try {
        facade && facade.track(eventName, data)
    } catch (e) {
        console.log('track call failed', e)
    }
}

function getAnalytics() {
    const segment = (window as any).analytics
    const facade = (window as any).analyticsFacade
    return {segment, facade}
}
