import {Integrations} from '../Integrations/Integrations'
import Billing from '../Billing/Billing'
import React, {useEffect} from 'react'
import {Route, Switch, useParams, useRouteMatch} from 'react-router-dom'
import B2bNavBar from '../B2b-Nav-Bar/B2b-Nav-Bar'
import './B2b-Container.scss'
import {ParamTypes} from '../../../definitions/param-types'
import {useAppContext} from '../useAppState'
import {AdminPlanV2} from '../Plan/Admin-PlanV2'

export default function B2bRoutesV2() {
    let match = useRouteMatch()
    let {planId} = useParams<ParamTypes>()
    const {state} = useAppContext()

    useEffect(() => {
        if (state.PrismInited) {
            window.Prism.update({
                currentPlanId: planId,
            })
        }
    }, [state.PrismInited, planId])

    return (
        <div className='b2bContainer'>
            <div style={{marginTop: '40px'}}>
                <B2bNavBar />
            </div>
            <div>
                <Switch>
                    <Route exact path={`${match.path}`}>
                        <AdminPlanV2 />
                    </Route>
                    <Route exact path={`${match.path}/integrations`}>
                        <Integrations />
                    </Route>
                    <Route exact path={`${match.path}/billing`}>
                        <Billing />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}
