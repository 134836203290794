import * as React from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import RenewUnBlocked from './Renew-Unblocked/renew-unblocked'
import {useRenewState} from './Renew-Context/renew-context'
import {RenewSetters} from './renew-setters'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {useAsyncError} from '../useAsyncError'

export default function Renew() {
    const {state, setter, actions} = useRenewState()
    let {planId} = useParams<ParamTypes>()
    const throwError = useAsyncError()
    useEffect(() => {
        actions
            .getPreviewData(planId)
            .then((data) => {
                setter({type: RenewSetters.initializeRenew, data})
            })
            .catch((e) => {
                throwError(e.message)
            })
    }, [setter, planId, actions, throwError])
    return (
        <>
            {!state.renew.isInitialized ? (
                <CenteredLoadingDecorator centerVertically={true} />
            ) : (
                <>{<RenewUnBlocked />}</>
            )}
        </>
    )
}
