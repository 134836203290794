import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import {ReactComponent as Envelope} from '../../images/envelope.svg'
import {Link} from 'react-router-dom'
import {ReactComponent as PluralSightHeader} from '../../images/pluralsight-logo-horizontal-white-outline.svg'
import styles from './generic-error-page.module.scss'

export default function GenericErrorPage() {
    function BackToPlanButton() {
        return (
            <Link to={'/subscription/plans'}>
                <Button className={styles.mainCTAButton}>Back to Plan</Button>
            </Link>
        )
    }

    function ContactSupport() {
        return (
            <div className={styles.contactSupportContent}>
                <Envelope />
                <div className={styles.supportInfo}>
                    <div className={styles.email}>support@pluralsight.com</div>
                    <div className={styles.availability}>AVAILABLE 24/7</div>
                </div>
            </div>
        )
    }

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <div style={{margin: '15px'}}>
                <PluralSightHeader />
            </div>
            <div className={styles.errorPageLayout}>
                <div className={styles.errorMessage}>
                    Oh no! It looks like something went wrong.
                </div>
                <div className={styles.subErrorMessage}>
                    Please try to{' '}
                    <button className={styles.refresh} onClick={() => window.location.reload()}>
                        refresh
                    </button>{' '}
                    this page.If that doesn’t work, reach out to our support team for help.
                </div>
                <ContactSupport />
                <BackToPlanButton />
            </div>
        </div>
    )
}
