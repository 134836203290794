import React, {createContext, useContext, useMemo, useState} from 'react'

import {config} from './environment-config'
import {ApiContext, ApplicationData, DisplayNameContext} from '../../definitions/api'
import {api} from './api/api'
import {ProductState} from '../../definitions/ind-product-state'
import {Account} from '../../definitions/account'
import {PsContact} from '../../definitions/ps-contact'
import {useAsyncError} from './useAsyncError'
import {InvoiceData} from '../../definitions/invoice'
import {CurrentPromotionConfig} from '../../definitions/promotion-config'
import {SalesforceSubscriptionData} from '../../definitions/v2/salesforce-subscription-data'

const AppState = (props: any) => {
    const activateErrorBoundary = useAsyncError()
    const useAppState = () => {
        const initialState = {
            derivedData: {
                isMember: false,
                hasSubscription: false,
                noSubscription: false,
            },
            pricingUrls: getPricingUrls(),
            appHasError: false,
            hasPrismData: false,
            hideCTAs: false,
            UpdatingSavedPayments: false,
            PrismInited: false,
            reRenderSupportTooling: false,
            supportToolingError: null,
            applicationData: {
                user: {
                    initialized: false,
                    firstName: '',
                    lastName: '',
                    handle: '',
                    email: '',
                    username: '',
                },
                subscriptionAccount: {
                    subscriptions: [],
                },
                planInfo: {admin: [], member: null},
                config: {launchDarklyClientId: ''},
                featureConfig: {
                    designSystemFeatureFlags: {},
                    expandFeatureFlag: {},
                    modifyFeatureFlag: false,
                    b2bDataCapture: false,
                    upgradeFeatureFlag: false,
                    profitwellFeatureFlag: false,
                    useMulesoftPaypalCreationFlag: false,
                    useDocmationCheckout: false,
                    disableTransactionLinks: false,
                },
            },
            paymentMethods: {
                savedPayments: [],
            },
            planSubscriptionData: {
                account: {
                    handle: '',
                },
            },
            businessAccountMap: {},
            planDisplayNames: {},
            subscriptionHardRefreshDone: false,
            subscriptionData: {},
            expandData: {
                success: false,
                expand: {
                    amount: 0,
                    taxAmount: 0,
                    amountWithoutTax: 0,
                    currency: '',
                    paymentMethod: '',
                    licensesAdded: 0,
                },
            },
            indProductState: {
                pending: false,
                loaded: false,
                data: null,
            },
            greenProductBanner: {
                showBanner: false,
                message: '',
            },
            pluralsightContactMap: {},
            invoiceData: {},
            ctaText: {
                loaded: false,
                pending: false,
                data: 'Upgrade now',
            },
        }

        // Manage the state using React.useState()
        const [state, setState] = useState(initialState)

        // Build our actions. We'll use useMemo() as an optimization,
        // so this will only ever be called once.
        const setters = useMemo<Setters>(() => getSetters(setState), [setState])

        const actions = useMemo<Actions>(() => getActions(state, setState), [state, setState])

        return {state, actions, setters}
    }

    // Define your actions as functions that call setState().
    // It's a bit like Redux's dispatch(), but as individual
    // functions.
    const getSetters: (setState: any) => Setters = (setState: any) => ({
        setApplicationData: (applicationData: ApplicationData) => {
            const derivedData = calculateData(applicationData)
            setState((state: any) => ({
                ...state,
                hasPrismData: true,
                applicationData,
                derivedData,
            }))
        },
        setBusinessData: (paymentMethods: any) => {
            setState((state: any) => ({
                ...state,
                paymentMethods: paymentMethods,
            }))
        },
        setUpdatePaymentsFlag: (setFlag: boolean) => {
            setState((state: any) => ({
                ...state,
                UpdatingSavedPayments: setFlag,
            }))
        },
        prismInited: (inited: boolean) => {
            setState((state: any) => ({
                ...state,
                PrismInited: inited,
            }))
        },
        setSupportReRendering: (setFlag: boolean) => {
            setState((state: any) => ({
                ...state,
                reRenderSupportTooling: setFlag,
            }))
        },
        setSupportToolingError: (setError: string | null) => {
            setState((state: any) => ({
                ...state,
                supportToolingError: setError,
            }))
        },
        setAppHasError: (appHasError: boolean) => {
            setState((state: any) => ({
                ...state,
                appHasError,
            }))
        },
        setInvoiceData: (invoiceData: any) => {
            setState((state: any) => ({
                ...state,
                invoiceData: invoiceData,
            }))
        },
        hideCTAsForSupport: (hideCTAs: boolean) => {
            setState((state: any) => ({
                ...state,
                hideCTAs,
            }))
        },
        setPlanSubscriptionData: (planSubscriptionData: any) => {
            setState((state: any) => ({
                ...state,
                planSubscriptionData,
            }))
        },
        setSubscriptionHardRefreshDone: (subscriptionHardRefreshDone: boolean) => {
            setState((state: any) => ({
                ...state,
                subscriptionHardRefreshDone,
            }))
        },
        setBusinessPlanData: (businessPlanData: any) => {
            setState((state: any) => ({
                ...state,
                businessPlanData,
            }))
        },
        setExpandData: (expand: any) => {
            setState((state: any) => ({
                ...state,
                expand,
            }))
        },
        setGreenProductBanner: (greenProductBanner: ProductBanner) => {
            setState((state: any) => ({
                ...state,
                greenProductBanner,
            }))
        },
    })

    const getActions: (state: State, setState: any) => Actions = (state: State, setState: any) => {
        return {
            loadPlanDisplayName: (planIds: string[], hardRefresh: boolean = false) => {
                const notCached = planIds.filter((id) => {
                    const exists = !!state.planDisplayNames[id]
                    return hardRefresh || !exists
                })
                if (notCached.length > 0) {
                    setState((state: State) => {
                        const updates = getPendingUpdates(notCached)
                        const planDisplayNames = {...state.planDisplayNames, ...updates}
                        return {
                            ...state,
                            planDisplayNames,
                        }
                    })
                    api.post(`plans/display-name`, notCached).then(
                        (res: ApiContext<DisplayNameContext[]>) => {
                            if (res.status.success) {
                                setState((state: State) => {
                                    const updates = getDisplayNameUpdates(res.data)
                                    const planDisplayNames = {...state.planDisplayNames, ...updates}
                                    return {
                                        ...state,
                                        planDisplayNames,
                                    }
                                })
                            } else {
                                throw new Error(res.status.errorMessage)
                            }
                        }
                    )
                }
            },
            loadIndProductState: (hardRefresh: boolean = false) => {
                const shouldGetData =
                    hardRefresh || (!state.indProductState.pending && !state.indProductState.loaded)
                if (shouldGetData) {
                    setState((state: State) => {
                        return {
                            ...state,
                            indProductState: {
                                pending: true,
                                loaded: false,
                            },
                        }
                    })
                    api.get(`individual/product-state`).then(
                        (res: ApiContext<{individualProductState: ProductState}>) => {
                            if (res.status.success) {
                                setState((state: State) => {
                                    return {
                                        ...state,
                                        indProductState: {
                                            pending: false,
                                            loaded: true,
                                            data: res.data.individualProductState,
                                        },
                                    }
                                })
                            } else {
                                throw new Error(res.status.errorMessage)
                            }
                        }
                    )
                }
            },
            loadBusinessAccount(planId: string, hardRefresh = false) {
                const businessAccountSource = () => {
                    return api.get(`plans/${planId}/account`).then((res: ApiContext<Account>) => {
                        if (res.status.success) {
                            return res.data
                        } else {
                            throw new Error(res.status.errorMessage)
                        }
                    })
                }
                loadBusinessData(
                    state,
                    'businessAccountMap',
                    planId,
                    businessAccountSource,
                    hardRefresh
                )
            },
            loadPluralsightContact(planId: string, hardRefresh = false) {
                const pluralsightContactSource = () => {
                    return api.get(`accounts/owner/${planId}`).then((res) => {
                        if (res.status.success) {
                            return res.data.ownerData as PsContact
                        } else {
                            throw new Error(res.status.errorMessage)
                        }
                    })
                }
                loadBusinessData(
                    state,
                    'pluralsightContactMap',
                    planId,
                    pluralsightContactSource,
                    hardRefresh
                )
            },
            loadInvoices(planId: string, hardRefresh = false) {
                const invoiceSource = () => {
                    return api.get(`${planId}/invoices`).then((res) => {
                        if (res.status.success) {
                            return res.data as InvoiceData
                        } else {
                            throw new Error(res.status.errorMessage)
                        }
                    })
                }
                loadBusinessData(state, 'invoiceData', planId, invoiceSource, hardRefresh)
            },
            loadInvoicesV2(planId: string, hardRefresh = false) {
                const invoiceSource = () => {
                    return api.get(`${planId}/invoices-v2`).then((res) => {
                        if (res.status.success) {
                            return res.data as InvoiceData
                        } else {
                            throw new Error(res.status.errorMessage)
                        }
                    })
                }
                loadBusinessData(state, 'invoiceData', planId, invoiceSource, hardRefresh)
            },
            loadCtaConfig() {
                const shouldGetData = !state.ctaText.pending && !state.ctaText.loaded
                if (shouldGetData) {
                    setState((state: State) => {
                        const data = state.ctaText.data
                        return {
                            ...state,
                            ctaText: {
                                pending: true,
                                loaded: false,
                                data,
                            },
                        }
                    })
                    api.get(`cta-config`).then((res: ApiContext<CurrentPromotionConfig>) => {
                        if (res.status.success) {
                            setState((state: State) => {
                                const defaultCtaText = state.ctaText.data
                                const {active, ctaText} = res.data
                                const finalText = active && !!ctaText ? ctaText : defaultCtaText
                                return {
                                    ...state,
                                    ctaText: {
                                        pending: false,
                                        loaded: true,
                                        data: finalText,
                                    },
                                }
                            })
                        } else {
                            setState((state: State) => {
                                const data = state.ctaText.data
                                return {
                                    ...state,
                                    ctaText: {
                                        pending: false,
                                        loaded: true,
                                        data,
                                    },
                                }
                            })
                        }
                    })
                }
            },
            loadSubscription(planId: string, hardRefresh = false) {
                const subscriptionSource = () => {
                    return api
                        .get(`plans/subscription/${planId}?hardRefresh=${hardRefresh}`)
                        .then((res) => {
                            if (res.status.success) {
                                return res.data as SalesforceSubscriptionData
                            } else {
                                throw new Error(res.status.errorMessage)
                            }
                        })
                }
                loadBusinessData(state, 'subscriptionData', planId, subscriptionSource, hardRefresh)
            },
        }

        function loadBusinessData<T>(
            state: State,
            location:
                | 'businessAccountMap'
                | 'pluralsightContactMap'
                | 'invoiceData'
                | 'subscriptionData',
            planId: string,
            source: () => Promise<T>,
            hardRefresh = false
        ) {
            const wrapper = state[location][planId]
            const wrapperExists = !!wrapper
            const pending = (wrapper && wrapper.pending) || false
            const loaded = (wrapper && wrapper.loaded) || false
            const shouldGetData = hardRefresh || !wrapperExists || (!pending && !loaded)
            if (shouldGetData) {
                setState((state: State) => {
                    return {
                        ...state,
                        [location]: {
                            ...state[location],
                            [planId]: {
                                pending: true,
                                loaded: false,
                            },
                        },
                    }
                })
                source()
                    .then((data: T) => {
                        setState((state: State) => {
                            return {
                                ...state,
                                [location]: {
                                    ...state[location],
                                    [planId]: {
                                        pending: false,
                                        loaded: true,
                                        data,
                                    },
                                },
                            }
                        })
                    })
                    .catch((e) => {
                        activateErrorBoundary(e.message)
                    })
            }
        }
    }

    function getPendingUpdates(planIds: string[]): DisplayNameMap {
        return planIds.reduce((displayNames, id) => {
            return {...displayNames, [id]: {pending: true, displayName: ''}}
        }, {})
    }

    function getDisplayNameUpdates(displayNames: DisplayNameContext[]): DisplayNameMap {
        return displayNames.reduce((displayNames, displayNameContext) => {
            const {planId, displayName} = displayNameContext

            return {...displayNames, [planId]: {pending: false, displayName}}
        }, {})
    }

    const calculateData = (applicationData: any) => {
        const isMember = !!applicationData.planInfo.member
        const hasSubscription =
            applicationData.subscriptionAccount.subscriptions.filter(
                (s: any) => s.fullLibrarySubscription
            ).length > 0
        const noSubscription = !isMember && !hasSubscription

        return {
            isMember,
            hasSubscription,
            noSubscription,
        }
    }

    const getPricingUrls = () => {
        const businessSkills = `${config.www.url}/pricing/skills?type=business`
        const individualSkills = `${config.www.url}/pricing/skills?type=individual`
        const pricingPage = `${config.www.url}/pricing`
        const goCode = `${config.www.goCode}`
        return {
            businessSkills,
            individualSkills,
            pricingPage,
            goCode,
        }
    }

    return <AppContext.Provider value={useAppState()}>{props.children}</AppContext.Provider>
}

const AppContext = createContext({} as any)

const useAppContext = () => {
    return useContext<{state: State; setters: Setters; actions: Actions}>(AppContext)
}

interface Setters {
    setApplicationData: (applicationData: ApplicationData) => void
    setInvoiceData: (invoiceData: any) => void
    setAppHasError: (hasError: boolean) => void
    hideCTAsForSupport: (hideCTAs: boolean) => void
    setBusinessData: (paymentMethods: any) => void
    setUpdatePaymentsFlag: (setFlag: boolean) => void
    setSupportReRendering: (setFlag: boolean) => void
    setSupportToolingError: (setError: string | null) => void
    setPlanSubscriptionData: (planSubscriptionData: any) => void
    setSubscriptionHardRefreshDone: (hardRefreshDone: boolean) => void
    prismInited: (inited: boolean) => void
    setBusinessPlanData: (businessPlanData: any) => void
    setExpandData: (expandData: any) => void
    setGreenProductBanner: (greenProductBanner: ProductBanner) => void
}

interface Actions {
    loadPlanDisplayName: (planIds: string[], hardRefresh?: boolean) => void
    loadIndProductState: (hardRefresh?: boolean) => void
    loadBusinessAccount: (planId: string, hardRefresh?: boolean) => void
    loadPluralsightContact: (planId: string, hardRefresh?: boolean) => void
    loadInvoices: (planId: string, hardRefresh?: boolean) => void
    loadInvoicesV2: (planId: string, hardRefresh?: boolean) => void
    loadCtaConfig: () => void
    loadSubscription: (planId: string, hardRefresh?: boolean) => void
}

interface ProductBanner {
    showBanner: boolean
    product?: string
    message?: string
}

export {AppState, useAppContext}

export interface State {
    derivedData: {
        isMember: boolean
        hasSubscription: boolean
        noSubscription: boolean
    }
    pricingUrls: PricingUrls
    appHasError: boolean
    hasPrismData: boolean
    hideCTAs: boolean
    UpdatingSavedPayments: boolean
    PrismInited: boolean
    reRenderSupportTooling: boolean
    supportToolingError: string | null
    applicationData: ApplicationData
    paymentMethods: {
        savedPayments: any[]
    }
    subscriptionHardRefreshDone: boolean
    subscriptionData: {[planId: string]: DataWrapper<SalesforceSubscriptionData>}
    invoiceData: {[planId: string]: DataWrapper<InvoiceData>}

    planSubscriptionData: {
        account: {
            handle: string
        }
    }
    businessAccountMap: {[planId: string]: DataWrapper<Account>}
    planDisplayNames: DisplayNameMap
    indProductState: IndProductStateWrapper
    greenProductBanner: ProductBanner
    pluralsightContactMap: {[planId: string]: DataWrapper<PsContact>}
    ctaText: DataWrapper<string>
}

interface DataWrapper<T> {
    loaded: boolean
    pending: boolean
    data: T
}

export interface DisplayNameMap {
    [planId: string]: DisplayName
}

interface DisplayName {
    pending: boolean
    displayName: string
}

interface IndProductStateWrapper {
    pending: boolean
    loaded: boolean
    data: ProductState | null
}

type PricingUrls = {
    [key in PricingTargets]: string
}

export type PricingTargets = 'businessSkills' | 'individualSkills' | 'pricingPage' | 'goCode'
