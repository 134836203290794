import React, {useEffect, useState} from 'react'
import {useUpgradeContext} from './Upgrade-Context'
import CircularProgress from '@pluralsight/ps-design-system-circularprogress'
import Drawer from '../common/Custom-Drawer/drawer'
import styles from './Upgrade-Order-Summary.module.scss'
import {upgradeLineItemUnitPrice} from './upgrade-line-items'

export function UpgradeOrderSummary() {
    return (
        <div className={styles.orderSummaryContainer}>
            <h4 className={styles.header}>Order Summary</h4>
            <div>
                <UpgradeOrderSummaryBody />
            </div>
        </div>
    )
}

export function UpgradeSummaryFooter() {
    const {state, setters} = useUpgradeContext()

    function toggleOrderSummaryDrawer() {
        setters.setIsMobileOrderSummaryOpen(!state.isMobileOrderSummaryOpen)
    }

    function TopRow(props: {price: number; currency: string; isLoading: boolean}) {
        return (
            <div className={styles.topRow} onClick={() => toggleOrderSummaryDrawer()}>
                <h3>
                    Order Summary
                    <span
                        className={`${styles.chevron} ${
                            state.isMobileOrderSummaryOpen ? styles.openCaret : ''
                        }`}
                    />
                </h3>
                <div>
                    {props.isLoading ? (
                        <SmallLoadingDecorator />
                    ) : (
                        `${props.price} ${props.currency}`
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            <Drawer
                isOpen={state.isMobileOrderSummaryOpen}
                base={
                    <TopRow
                        price={state.upgradePreview.amount}
                        isLoading={state.upgradePreview.isLoading}
                        currency={state.upgradePreview.currency}
                    />
                }
                children={
                    <div>
                        <UpgradeOrderSummaryBody />
                    </div>
                }
            />
        </div>
    )
}

function UpgradeOrderSummaryBody() {
    const {state, actions} = useUpgradeContext()
    const {amount, currency, taxAmount, lineItems, isLoading, loaded} = state.upgradePreview
    const [upgradeUnitPrice, setUpgradeUnitPrice] = useState(0)
    const [loading, setLoading] = useState(true)
    const {currentSubscriptionQuantity, productName} = state.currentSubscription
    const upgradeProductName = upgradeProduct(productName)

    useEffect(() => {
        if (!isLoading && loaded) {
            setUpgradeUnitPrice(upgradeLineItemUnitPrice(lineItems))
            setLoading(false)
        }
    }, [loaded, isLoading, lineItems, actions])

    return (
        <>
            <div className={styles.orderSummaryBody}>
                <div>
                    <div id={'plan'} className={`${styles.upperCase} ${styles.subHeader}`}>
                        Plan
                    </div>
                    <div aria-labelledby={'plan'} className={styles.planDetail}>
                        {upgradeProductName}
                    </div>
                </div>
                <div>
                    <div id={'subscriptions'} className={`${styles.upperCase} ${styles.subHeader}`}>
                        Subscriptions
                    </div>
                    <div aria-labelledby={'subscriptions'} className={styles.planDetail}>
                        {currentSubscriptionQuantity}
                    </div>
                </div>
                <div>
                    <div id={'price'} className={`${styles.upperCase} ${styles.subHeader}`}>
                        Price <span className={styles.daggers}>✝</span>
                    </div>
                    <div>
                        {loading ? (
                            <SmallLoadingDecorator />
                        ) : (
                            <span aria-labelledby={'price'} className={styles.planDetail}>
                                {formatMoney(upgradeUnitPrice)} {currency}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <hr className={styles.horizontalRule} />
            <div className={styles.subtotalContainer}>
                <div className={styles.subtotalBody}>
                    <div className={`${styles.upperCase} ${styles.subtotalHeader}`}>Subtotal</div>
                    <div>
                        {isLoading ? (
                            <SmallLoadingDecorator />
                        ) : (
                            <span className={styles.planDetail}>
                                {formatMoney(amount)} {currency}
                            </span>
                        )}
                    </div>
                </div>
                <div className={styles.subtotalBody}>
                    <div className={`${styles.upperCase} ${styles.subtotalHeader}`}>
                        Estimated tax<span className={styles.daggers}>✝✝</span>
                    </div>
                    <div>
                        {isLoading ? (
                            <SmallLoadingDecorator />
                        ) : (
                            <span className={styles.planDetail}>
                                {formatMoney(taxAmount)} {currency}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <hr className={styles.horizontalRule} />
            <div className={styles.totalContainer}>
                <div>Total due today</div>
                <div>
                    {isLoading ? (
                        <SmallLoadingDecorator />
                    ) : (
                        <span>
                            {formatMoney(amount)} {currency}
                        </span>
                    )}
                </div>
            </div>
        </>
    )
}

function SmallLoadingDecorator() {
    return <CircularProgress size={CircularProgress.sizes.small} aria-label={'loading decorator'} />
}

function formatMoney(amount: number) {
    return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function upgradeProduct(productName: string) {
    if (productName === 'Business Starter') {
        return 'Business Professional'
    } else if (productName === 'Business Professional') {
        return 'Business Enterprise'
    } else {
        return 'Business'
    }
}
