import React, {useEffect} from 'react'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {useAsyncError} from '../useAsyncError'
import ModifyCheckout from './modify-checkout'
import {useModifyState} from './Modify-Context/modify-context'
import {ModifySetters} from './modify-setters'
import ProcessModify from './Process-Modify/process-modify'

export default function ModifyLandingPage() {
    const {state, actions, setter} = useModifyState()
    let {planId, productOptionId} = useParams<ParamTypes>()
    const subscriptionNumber = !!state.modifyGuard.subscriptionNumber
        ? state.modifyGuard.subscriptionNumber
        : ''
    const throwError = useAsyncError()
    useEffect(() => {
        actions
            .modify(planId, {
                preview: true,
                subscriptionNumber,
                modifications: [
                    {
                        type: 'UPSELL',
                        productOptionId: productOptionId,
                        quantity: state.subscriptionsToAdd,
                    },
                ],
            })
            .then((data) => setter({type: ModifySetters.initializeModify, data}))
            .catch((e) => throwError(e.message))
    }, [
        actions,
        planId,
        setter,
        subscriptionNumber,
        throwError,
        productOptionId,
        state.subscriptionsToAdd,
    ])
    return (
        <>
            {!state.preview.isInitialized ? (
                <CenteredLoadingDecorator centerVertically={true} />
            ) : (
                <>{state.processingModify ? <ProcessModify /> : <ModifyCheckout />}</>
            )}
        </>
    )
}
