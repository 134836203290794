import React from 'react'
import {useAppContext} from '../useAppState'
import {InternalServerErrorPage} from '@pluralsight/ps-design-system-errors'

export default function AppError(props: any) {
    const {state} = useAppContext()

    return (
        <>
            {state.appHasError ? (
                <InternalServerErrorPage size={InternalServerErrorPage.sizes.large} />
            ) : (
                props.children
            )}
        </>
    )
}
