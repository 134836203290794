import React, {useState} from 'react'
import {useUpgradeContext} from './Upgrade-Context'
import {useParams} from 'react-router'
import {ParamTypes} from '../../../definitions/param-types'
import {ExistingPaymentMethodsSelector} from '../common/Existing-Payment-Method-Selecttor/Existing-Payment-Method-Selector'
import UpmcComponent from '../Billing/upmc/Upmc-Component'
import Drawer from '../common/Custom-Drawer/drawer'
import {RowHeader} from '../common/Row-Header/Row-Header'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {useAppContext} from '../useAppState'
import {createPaypalMethod} from '../Billing/Saved-Payment-Methods/Payments-Data/create-paypal-method/create-paypal-method'

export function UpgradePayment() {
    const {state: appState} = useAppContext()
    const {state, setters, actions} = useUpgradeContext()
    const {planId} = useParams<ParamTypes>()
    const [createPaypalMethodFlag, setCreatePaypalMethodFlag] = useState(false)

    function hasExistingPaymentMethods() {
        return state.existingPaymentMethods.length > 0
    }

    function submitPayment() {
        setters.setIsPaymentOpen(false)
        setters.setIsPaymentComplete(true)
        setters.setIsReviewConfirmOpen(true)
    }

    function addPaypalMethod(successMessage: PaypalSuccess) {
        const body = {
            paypalBaid: successMessage.paypalBaid,
            paypalEmail: successMessage.paypalEmail,
            address: `${successMessage.address.address1} ${successMessage.address.address2}`,
            city: successMessage.address.city,
            state: successMessage.address.state,
            country: successMessage.address.country,
            zipCode: successMessage.address.postal,
        }

        return createPaypalMethod(
            appState.applicationData.featureConfig.useMulesoftPaypalCreationFlag,
            planId,
            body
        ).then((res: any) => res.data.id)
    }

    function onSuccess(successMessage: CreditCardSuccess | PaypalSuccess) {
        actions.loadPaymentMethods(planId)
        if (successMessage.paymentMethodType === 'PayPal') {
            setCreatePaypalMethodFlag(true)
            addPaypalMethod(successMessage).then((paymentMethodId) => {
                setters.setPaymentMethodsInitialized(false)
                setters.setPaymentMethodId(paymentMethodId)
                setCreatePaypalMethodFlag(false)
                submitPayment()
            })
        } else {
            setters.setPaymentMethodId(successMessage.paymentMethodId)
            submitPayment()
        }
    }

    function UpgradeExistingPaymentMethods() {
        return (
            <div>
                {state.paymentMethodsInitialized ? (
                    hasExistingPaymentMethods() ? (
                        <ExistingPaymentMethodsSelector
                            selectedPaymentId={state.paymentMethodId}
                            existingPaymentMethods={state.existingPaymentMethods}
                            selectFunction={(paymentMethodId1) => {
                                setters.setPaymentMethodId(paymentMethodId1)
                                submitPayment()
                            }}
                        />
                    ) : (
                        <div />
                    )
                ) : (
                    <CenteredLoadingDecorator />
                )}
            </div>
        )
    }

    function PaymentDropDown(props: {isOpen: boolean}) {
        const umpcHeader = hasExistingPaymentMethods()
            ? 'Or add a new payment method '
            : 'Add a new payment method'
        const currentPlanId = !!planId ? planId : ''
        return (
            <div>
                <UpgradeExistingPaymentMethods />
                <h4>{umpcHeader}</h4>
                {props.isOpen && !state.upgradePreview.isLoading && (
                    <UpmcComponent
                        handle={state.handle}
                        planId={currentPlanId}
                        currency={state.currentSubscription.currency}
                        onSuccess={onSuccess}
                        authAmount={state.upgradePreview.amount}
                    />
                )}
            </div>
        )
    }

    return (
        <div>
            <Drawer
                isOpen={state.isPaymentOpen}
                base={
                    <RowHeader
                        headerText={'Payment'}
                        active={state.isPaymentOpen}
                        complete={state.isPaymentComplete}
                        stepNumber={1}
                        resetToStep={actions.resetToPaymentSelection}
                    />
                }
                children={
                    !createPaypalMethodFlag ? (
                        <PaymentDropDown isOpen={state.isPaymentOpen} />
                    ) : (
                        <CenteredLoadingDecorator />
                    )
                }
            />
        </div>
    )
}
