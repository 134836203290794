import React from 'react'
import {useUpgradeContext} from './Upgrade-Context'
import {ReviewConfirm, ReviewConfirmMobile} from '../common/Review-Confirm/Review-Confirm'
import {UpgradeTermsAndConditions} from './Upgrade-Terms-And-Conditions'

export function UpgradeReviewConfirm() {
    let {state, actions} = useUpgradeContext()

    return (
        <>
            <ReviewConfirm
                isOpen={state.isReviewConfirmOpen}
                isActive={state.isReviewConfirmOpen}
                isComplete={false}
                stepNumber={2}
                purchase={() => {
                    actions.postUpgrade(state)
                }}
                terms={UpgradeTermsAndConditions}
            />
        </>
    )
}

export function UpgradeReviewConfirmMobile() {
    let {state, actions} = useUpgradeContext()

    return (
        <ReviewConfirmMobile
            isOpen={state.isReviewConfirmOpen}
            isActive={state.isReviewConfirmOpen}
            isComplete={false}
            stepNumber={2}
            purchase={() => actions.postUpgrade(state)}
            productName={'Business Professional'}
            autoRenewDate={state.currentSubscription.formattedAutoRenewDate}
            terms={UpgradeTermsAndConditions}
        />
    )
}
