import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {pageView} from '../analytics'

export function AnalyticsPageView(props: {children: any}) {
    let location = useLocation()
    useEffect(pageView, [location])

    return props.children
}
