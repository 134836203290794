import React from 'react'
import Link from '@pluralsight/ps-design-system-link'
import './styles/terms-layout.scss'
import {shortFormatDate} from '../../utils/date-utils/format-date'
import UnpaidInvoiceModal from '../../common/Unpaid-Invoice-Modal/Unpaid-Invoice-Modal'
import BusinessCancelModal from '../../common/Business-Cancel-Modal/business-cancel-modal'

export default function AccountTerms(props: {
    hasSubscription: boolean
    subscription: any
    displayName: string
    planId: string
    productStartDate: string
    productExpirationDate: string
    customerSource: string
    billingUrl: string | undefined
    unpaidInvoiceIds: string[]
    subscribeLink: string
}) {
    const billingUrl = !!props.billingUrl ? props.billingUrl : ''
    const displayName = props.displayName
    const autoConvert = props.hasSubscription && props.subscription.autoConvert
    const expiration = props.hasSubscription ? shortFormatDate(props.subscription.termEndDate) : ''
    const billingHref = `${props.billingUrl}/?planId=${props.planId}`
    const isResellerOrThirdParty = props.customerSource && props.customerSource !== 'Pluralsight'
    const thirdPartyHref = thirdPartySubscriptionHref(
        props.customerSource,
        props.planId,
        billingUrl
    )
    const managePlanHref = isResellerOrThirdParty ? thirdPartyHref : billingHref
    const renewLink = `/subscription/transaction/renew/${props.planId}`
    const hasUnpaidInvoice = !!props.unpaidInvoiceIds && props.unpaidInvoiceIds.length > 0
    const unpaidInvoiceIds = hasUnpaidInvoice ? props.unpaidInvoiceIds : []
    const subscribeText = `There is not currently an active plan for this account.`
    const autoRenewText =
        props.hasSubscription && props.subscription.autoRenew ? 'AUTO-RENEWS ON' : 'EXPIRES ON'
    const thirdPartyBillingInfo = isResellerOrThirdParty
        ? `Billed by ${props.customerSource}`
        : null
    const hasFutureStartDate = new Date(props.productStartDate).getTime() > Date.now()
    const outOfTermExpirationText = hasFutureStartDate ? 'EXPIRES ON' : 'EXPIRED ON'
    return (
        <>
            <div className='accountDetails'>
                {termDetails(
                    'ACCOUNT NAME',
                    managePlanHref,
                    isResellerOrThirdParty
                        ? `Manage plan via ${props.customerSource}`
                        : 'Manage plan',
                    displayName,
                    {
                        thirdPartyBillingInfo,
                    },
                    isResellerOrThirdParty
                )}
                {props.hasSubscription ? (
                    <div className='termsLayout'>
                        {props.productStartDate &&
                            termDetails(
                                'START DATE',
                                '',
                                'Cancel',
                                shortFormatDate(props.productStartDate)
                            )}
                        {autoConvert
                            ? termDetails('AUTO CONVERTS ON', '', '', shortFormatDate(expiration))
                            : termDetails(
                                  autoRenewText,
                                  isResellerOrThirdParty ? '' : renewLink,
                                  isResellerOrThirdParty ? '' : 'Renew now',
                                  shortFormatDate(expiration),
                                  {hasUnpaidInvoice, unpaidInvoiceIds, planId: props.planId}
                              )}
                    </div>
                ) : (
                    <>
                        <div className='termsLayout'>
                            {props.productStartDate &&
                                termDetails(
                                    'START DATE',
                                    '',
                                    '',
                                    shortFormatDate(props.productStartDate)
                                )}
                            {props.productExpirationDate &&
                                termDetails(
                                    outOfTermExpirationText,
                                    '',
                                    '',
                                    shortFormatDate(props.productExpirationDate)
                                )}
                        </div>
                        {!hasFutureStartDate &&
                            termDetails('PLAN', props.subscribeLink, 'Subscribe', subscribeText)}
                    </>
                )}
            </div>
        </>
    )
}

function thirdPartySubscriptionHref(customerSource: string, planId: string, billingUrl: string) {
    switch (customerSource) {
        case 'Amazon':
            return 'https://www.amazon.com/yourmembershipsandsubscriptions'
        case 'AWS Marketplace':
            return 'https://console.aws.amazon.com/marketplace/home?region=us-east-1#/subscriptions'
        case 'Apple':
            return 'https://support.apple.com/en-us/HT202039'
        default:
            return `${billingUrl}/?planId=${planId}`
    }
}

function termDetails(
    header: string,
    link: string,
    linkText: string,
    data: string,
    {thirdPartyBillingInfo, hasUnpaidInvoice, unpaidInvoiceIds, planId}: OptionalTerms = {
        thirdPartyBillingInfo: null,
        hasUnpaidInvoice: false,
    },
    isResellerOrThirdParty?: boolean | string
) {
    const subscribeText = linkText === 'Subscribe'
    return (
        <div className='termDetails'>
            <div className='text'>{header}</div>
            <div className='link'>
                {hasUnpaidInvoice ? (
                    <UnpaidInvoiceModal
                        linkText={'Renew now'}
                        planId={!!planId ? planId : ''}
                        invoiceIds={!!unpaidInvoiceIds ? unpaidInvoiceIds : []}
                        hideLink={false}
                    />
                ) : linkText === 'Cancel' ? (
                    <BusinessCancelModal heading='Cancel your plan' hideLink={false} />
                ) : (
                    <Link>
                        <a
                            href={link}
                            target={isResellerOrThirdParty ? '_blank' : ''}
                            rel='noreferrer'
                        >
                            {linkText}
                        </a>
                    </Link>
                )}
            </div>
            <div className={subscribeText ? 'subscribeText' : 'termData'}>{data}</div>
            {!!thirdPartyBillingInfo && <div className='resellerInfo'>{thirdPartyBillingInfo}</div>}
        </div>
    )
}

interface OptionalTerms {
    thirdPartyBillingInfo?: string | null
    hasUnpaidInvoice?: boolean
    unpaidInvoiceIds?: string[]
    planId?: string
    isResellerOrThirdParty?: boolean | string
}
