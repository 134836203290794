import Drawer from '../Custom-Drawer/drawer'
import {RowHeaderRightIcon} from '../Row-Header/Row-Header'
import {CodeIcon, LockIcon} from '@pluralsight/ps-design-system-icon'
import React, {ReactNode} from 'react'
import Button from '@pluralsight/ps-design-system-button'
import Link from '@pluralsight/ps-design-system-link'
import Banner from '@pluralsight/ps-design-system-banner'
import {UpgradeTermsAndConditions} from '../../Upgrade/Upgrade-Terms-And-Conditions'
import {useUpgradeContext} from '../../Upgrade/Upgrade-Context'
import styles from './Review-Confirm.module.scss'

export function ReviewConfirm(props: {
    isOpen: boolean
    isActive: boolean
    isComplete: boolean
    stepNumber: number
    purchase: () => void
    terms: any
    isDisabled?: boolean
}) {
    function Dropdown() {
        return (
            <div>
                <Button
                    data-testid='purchase-button'
                    className={styles.button}
                    loading={props.isDisabled}
                    appearance={Button.appearances.primary}
                    onClick={() => props.purchase()}
                >
                    Purchase
                </Button>
            </div>
        )
    }

    return (
        <ReviewConfirmDrawer
            isDisabled={props.isDisabled}
            accept={props.purchase}
            children={<Dropdown />}
            isActive={props.isActive}
            isComplete={props.isComplete}
            isOpen={props.isOpen}
            stepNumber={props.stepNumber}
            terms={props.terms}
        />
    )
}

export function ReviewConfirmMobile(props: {
    isOpen: boolean
    isActive: boolean
    isComplete: boolean
    stepNumber: number
    purchase: () => void
    productName: string
    autoRenewDate: string
    terms: any
    isDisabled?: boolean
}) {
    function Dropdown() {
        return (
            <div>
                {props.terms}
                <Button
                    className={styles.button}
                    disabled={props.isDisabled}
                    appearance={Button.appearances.primary}
                    onClick={() => props.purchase()}
                >
                    Purchase
                </Button>
            </div>
        )
    }

    return (
        <ReviewConfirmDrawer
            accept={props.purchase}
            children={<Dropdown />}
            isActive={props.isActive}
            isComplete={props.isComplete}
            isOpen={props.isOpen}
            stepNumber={props.stepNumber}
            terms={props.terms}
        />
    )
}

function ReviewConfirmDrawer(props: {
    children: ReactNode
    isOpen: boolean
    isActive: boolean
    isComplete: boolean
    stepNumber: number
    accept: () => void
    terms: any
    isDisabled?: boolean
}) {
    let {state} = useUpgradeContext()

    return (
        <div>
            <Drawer
                isOpen={props.isOpen}
                base={
                    <RowHeaderRightIcon
                        headerText={'Review and confirm'}
                        active={props.isActive}
                        complete={props.isComplete}
                        stepNumber={props.stepNumber}
                        alternateText={'Secure checkout'}
                        icon={<LockIcon size={CodeIcon.sizes.medium} />}
                    />
                }
                children={
                    <div>
                        {props.terms === UpgradeTermsAndConditions && (
                            <Banner>
                                You will be charged a pro-rated amount of{' '}
                                {state.upgradePreview.amount} {state.upgradePreview.currency} today
                                and full price at your next renewal -{' '}
                                {state.currentSubscription.formattedAutoRenewDate}.
                            </Banner>
                        )}
                        <div className={styles.reviewText}>
                            Your selected plan will automatically renew unless you cancel 30 days
                            prior to your renewal date. For further details regarding automatic
                            renewal, cancellations, and our refund policy, please review our{' '}
                            <Link appearance={Link.appearances.subtle}>
                                <a
                                    href={'https://www.pluralsight.com/terms'}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Terms of Use
                                </a>
                            </Link>
                            .
                        </div>
                        {props.children}
                    </div>
                }
            />
        </div>
    )
}
