import * as React from 'react'
import Conditions from '../Conditions/conditions'
import Payment from '../Payment/payment'
import RenewOrderSummary from '../Renew-Order-Summary/renew-order-summary'
import ReviewAndConfirm from '../Review-And-confirm/review-and-confirm'
import styles from '../styles/renew.module.scss'

export default function RenewMain() {
    return (
        <div className={styles.rowLayout}>
            <div className={styles.cell1}>
                <Payment />
                <br />
                <ReviewAndConfirm />
            </div>
            <div className={styles.cell2}>
                <RenewOrderSummary />
                <Conditions />
            </div>
        </div>
    )
}
