import React from 'react'
import TransactionMainPage from '../common/Transaction/transaction-main-page'
import ModifyConditions from './Modify-Conditions/modify-conditions'
import {useModifyState} from './Modify-Context/modify-context'
import ModifyOrderSummary from './Modify-Order-Summary/modify-order-summary'
import ModifyPayment from './Modify-Payment/modify-payment'
import {ModifySetters} from './modify-setters'
import ModifyReviewAndConfirm from './Review-And-Confirm/modify-review-and-confirm'
export default function ModifyCheckout() {
    const {state, setter} = useModifyState()
    function resetModifyState() {
        setter({type: ModifySetters.resetModify})
    }
    return (
        <TransactionMainPage
            title={'Purchase Add On'}
            resetState={resetModifyState}
            paymentBox={
                <ModifyPayment
                    planId={state.modifyGuard.planId}
                    currency={state.preview.data.currency}
                />
            }
            reviewAndConfirm={<ModifyReviewAndConfirm planId={state.modifyGuard.planId} />}
            orderSummary={<ModifyOrderSummary />}
            conditions={<ModifyConditions />}
        />
    )
}
