import React, {useState} from 'react'
import {api} from '../api/api'
import TextInput from '@pluralsight/ps-design-system-textinput'
import Button from '@pluralsight/ps-design-system-button'
import Banner from '@pluralsight/ps-design-system-banner'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'

export default function PhoenixProduct() {
    const [productDetails, setProductDetails] = useState(null)
    const [sku, setSku] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleChange = (event: any) => {
        setSku(event.target.value)
    }

    const getSkuDetails = async () => {
        setLoading(true)
        setProductDetails(null)
        setError('')
        await api.get(`internal-api/phx/getProduct/${sku}`).then((result) => {
            if (result.status.success === true) {
                setProductDetails(result.data)
            } else {
                setError(result.status.errorMessage)
            }
            setLoading(false)
        })
    }

    return (
        <div>
            <div
                style={{
                    marginBottom: 50,
                }}
            >
                <h2>Get Product Details</h2>
                <TextInput name='sku' onChange={handleChange} style={{marginRight: 50}} />
                <Button
                    disabled={!sku || loading}
                    appearance={Button.appearances.primary}
                    onClick={getSkuDetails}
                >
                    Get sku details
                </Button>
            </div>

            <div>
                <h3>Product Details:</h3>
                {loading ? (
                    <CenteredLoadingDecorator />
                ) : (
                    <div
                        style={{
                            paddingLeft: 25,
                        }}
                    >
                        {!!error ? (
                            <Banner color={Banner.colors.red}>
                                Error retrieving product: {error}
                            </Banner>
                        ) : (
                            !!productDetails && printObject(productDetails)
                        )}
                    </div>
                )}
            </div>
        </div>
    )

    function printObject(obj: any) {
        const keys = Object.keys(obj)
        return keys
            .filter((k: string) => obj.hasOwnProperty(k))
            .map((k: string) => (
                <div key={k}>
                    {k}: {obj[k]}
                </div>
            ))
    }
}
