import CopyToClipboard from 'react-copy-to-clipboard'
import Button from '@pluralsight/ps-design-system-button'
import {CopyIcon} from '@pluralsight/ps-design-system-icon'
import React from 'react'

export function CopyToClipboardButton(props: {textToCopy: string}) {
    return (
        <CopyToClipboard text={props.textToCopy} aria-label='copy text to clipboard'>
            <Button
                icon={<CopyIcon size={CopyIcon.sizes.xSmall} color={CopyIcon.colors.blue} />}
                title={'copy text to clipboard'}
                appearance={Button.appearances.flat}
                style={{width: 20, height: 20}}
            />
        </CopyToClipboard>
    )
}
