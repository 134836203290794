import * as React from 'react'
import {useEffect} from 'react'
import GenericTransactionError from '../../common/Transaction-Error-Boundary/generic-transaction-error'
import {useModifyState} from '../Modify-Context/modify-context'
import ThankYouModify from './Thank-You-Modify'
import {ProcessTransactionDecorator} from '../../common/Transaction/process-transaction-decorator'

export default function ProcessModify() {
    const {state} = useModifyState()
    const processed = state.result.processed
    useEffect(() => {}, [state.result.processed])
    return <>{!processed ? <ProcessTransactionDecorator /> : processResult(state.modifyError)}</>
}

function processResult(error: boolean) {
    if (error) {
        return <GenericTransactionError />
    }
    return <ThankYouModify />
}
