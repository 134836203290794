import AccSwitcher from '../Account-Switcher/acc-switcher'
import React, {useEffect, useState} from 'react'
import {useAppContext} from '../useAppState'
import {useParams} from 'react-router'
import {IntegrationsContent} from './IntegrationsContent'
import Link from '@pluralsight/ps-design-system-link'
import {ReactComponent as Caret} from '../images/caret-right.svg'
import {ReactComponent as DataExportImage} from '../images/data-export.svg'
import {ReactComponent as LicenseApiImage} from '../images/license-api.svg'
import {ReactComponent as ReportingApiImage} from '../images/reporting-api.svg'
import {ReactComponent as SsoImage} from '../images/sso.svg'
import Banner from '@pluralsight/ps-design-system-banner'
import {config} from '../environment-config'
import {ParamTypes} from '../../../definitions/param-types'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import styles from './Integrations.module.scss'

export function Integrations() {
    const {state, actions} = useAppContext()
    let {planId} = useParams<ParamTypes>()
    const [showLoadingDecorator, setShowLoadingDecorator] = useState(true)
    const userHandle = state.applicationData.user.handle

    useEffect(() => {
        if (!!planId) {
            actions.loadPluralsightContact(planId)
        }
    }, [planId, actions])

    useEffect(() => {
        const contactWrapper = state.pluralsightContactMap[planId]
        const contactLoaded = contactWrapper && contactWrapper.loaded
        if (contactLoaded && planId) {
            setShowLoadingDecorator(false)
        }
    }, [planId, state.pluralsightContactMap])

    const contactEmail = state.pluralsightContactMap[planId]?.data?.email
    const email = !!contactEmail ? contactEmail : 'sales@pluralsight.com'
    return showLoadingDecorator ? (
        <CenteredLoadingDecorator centerVertically={true} />
    ) : (
        <div>
            <IntegrationsHeader />
            <SingleSignOn planId={planId} contactEmail={email} />
            <LicenseManagementApi planId={planId} contactEmail={email} />
            <ReportsAPI planId={planId} contactEmail={email} />
            <DataExport contactEmail={email} />
            <AccSwitcher
                plans={state.applicationData.planInfo.admin}
                currentPlanId={planId}
                userHandle={userHandle}
                path='/subscription/plans/integrations'
            />
        </div>
    )
}

function IntegrationsHeader() {
    const [showBanner, setShowBanner] = useState(true)
    function onClose() {
        setShowBanner(false)
    }
    const gettingStartedLink =
        'https://help.pluralsight.com/help/how-to-migrate-from-rest-to-graphql-apis'
    const developerPortalLink = 'https://developer.pluralsight.com/'
    return (
        <>
            {showBanner && (
                <div className={styles.bannerContainer}>
                    <Banner color={Banner.colors.yellow} onClick={onClose}>
                        <div className={styles.integrationsBanner}>
                            <span>It's time to migrate to new APIs. </span>
                            <Link appearance={Link.appearances.subtle}>
                                <a
                                    href={gettingStartedLink}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Get Started
                                </a>
                            </Link>
                        </div>
                        Don't use any of our APIs? Visit the{' '}
                        <Link appearance={Link.appearances.subtle}>
                            <a href={developerPortalLink} target='_blank' rel='noopener noreferrer'>
                                Pluralsight Developer Portal
                            </a>
                        </Link>{' '}
                        to learn what they could do for your plan!
                    </Banner>
                </div>
            )}
            <div className={styles.sectionContainer}>
                <div className={styles.iconContainer}></div>
                <div className={styles.integrationsHeaderContainer}>
                    <div className={styles.integrationsHeader}>
                        Pluralsight integrations give administrators options for automated account
                        management.
                    </div>
                    <Link>
                        <a
                            href='https://www.pluralsight.com/product/integrations'
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            More information <Caret />
                        </a>
                    </Link>
                </div>
            </div>
        </>
    )
}

function SingleSignOn(props: {contactEmail: string; planId: string}) {
    const email = props.contactEmail
    const planId = props.planId

    return (
        <div className={styles.sectionContainer}>
            <div className={styles.iconContainer}>
                <SsoImage />
            </div>
            <IntegrationsContent label={'Single sign-on'}>
                <div className={styles.integrationsContainer}>
                    <p>
                        Integrate Pluralsight with your company’s identity provider to make
                        provisioning Pluralsight subscriptions and authenticating quicker and
                        easier. You will find Pluralsight in all of the top IdP app catalogs,
                        including{' '}
                        <Link>
                            <a
                                href='https://docs.microsoft.com/en-us/azure/active-directory/saas-apps/pluralsight-tutorial'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Azure
                            </a>
                        </Link>
                        , PingOne,{' '}
                        <Link>
                            <a
                                href='https://www.onelogin.com/connector/pluralsight-single-sign-on'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                OneLogin
                            </a>
                        </Link>{' '}
                        and{' '}
                        <Link>
                            <a
                                href='https://www.okta.com/resources/find-your-apps/?keywords=pluralsight'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Okta
                            </a>
                        </Link>
                        . Getting started with single sign-on typically involves a discussion with
                        your IT or security team, and we are happy to help in the process.
                    </p>
                    <div className={`${styles.boxItemsContainer} ${styles.lineItem}`}>
                        <div className={styles.boxItem}>
                            <Link>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href='https://prod-pf.pluralsight.com/pf/federation_metadata.ping?PartnerSpId=www.pluralsight.com'
                                >
                                    Download SSO metadata <Caret />
                                </a>
                            </Link>
                        </div>
                        <div className={styles.boxItem}>
                            <span>Plan ID: </span>
                            <span className={styles.boxItemValue}>{planId}</span>
                        </div>
                    </div>

                    <div className={styles.lineItem}>
                        <div>
                            <p>Contact us to setup Single sign-on:</p>
                            <PsContactEmailLink email={email} />
                        </div>
                    </div>
                </div>
            </IntegrationsContent>
        </div>
    )
}

function LicenseManagementApi(props: {planId: string; contactEmail: string}) {
    const {planId, contactEmail} = props
    const licenseManagementDocumentationLink = `${config.plans.url}/api/license/docs?planId=${planId}`
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.iconContainer}>
                <LicenseApiImage />
            </div>
            <IntegrationsContent label={'License management API'}>
                <div className={styles.integrationsContainer}>
                    <p>
                        With the Pluralsight license management API, you can manage Pluralsight
                        access from your learning management system. This means you don’t need to
                        manage your licenses separately, saving you time and the confusion of
                        tracking user access across multiple systems.
                    </p>
                    <div className={`${styles.boxItemsContainer} ${styles.lineItem}`}>
                        <div className={styles.boxItem}>
                            <Link>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={licenseManagementDocumentationLink}
                                >
                                    View Documentation <Caret />
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.lineItem}>
                        <p>Contact your sales rep to gain access to the License management API:</p>
                        <PsContactEmailLink email={contactEmail} />
                    </div>
                </div>
            </IntegrationsContent>
        </div>
    )
}

function ReportsAPI(props: {planId: string; contactEmail: string}) {
    const {planId, contactEmail} = props
    const documentationLink = `${config.plans.url}/api/reports/docs?planId=${planId}`
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.iconContainer}>
                <ReportingApiImage />
            </div>
            <IntegrationsContent label={'Reports API'}>
                <div className={styles.integrationsContainer}>
                    <p>
                        With Pluralsight reporting APIs, you can pull your Pluralsight reports into
                        third-party applications. No more manual exports and merging reports.
                        Analyze data centrally, for added convenience and better analysis across
                        systems.
                    </p>
                    <div className={`${styles.boxItemsContainer} ${styles.lineItem}`}>
                        <div className={styles.boxItem}>
                            <Link>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={documentationLink}
                                >
                                    View Documentation <Caret />
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.lineItem}>
                        <p>Contact us for questions about the Reporting API:</p>
                        <PsContactEmailLink email={contactEmail} />
                    </div>
                </div>
            </IntegrationsContent>
        </div>
    )
}

function DataExport(props: {contactEmail: string}) {
    const {contactEmail} = props
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.iconContainer}>
                <DataExportImage />
            </div>
            <IntegrationsContent label={'Data export'}>
                <div className={styles.integrationsContainer}>
                    <p>
                        Our Professional Services Team can create flexible reoccuring reports of
                        Pluralsight usage based on your specific needs.
                    </p>
                    <div className={styles.lineItem}>
                        <p>Contact us to setup your custom Data Export:</p>
                        <PsContactEmailLink email={contactEmail} />
                    </div>
                </div>
            </IntegrationsContent>
        </div>
    )
}

function PsContactEmailLink({email}: {email: string}) {
    return (
        <Link>
            <a href={`mailto:${email}`} className={styles.colorBlue}>
                {email} <Caret />
            </a>
        </Link>
    )
}
