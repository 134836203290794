import React, {useState} from 'react'
import {api} from '../api/api'
import {CenteredLoadingDecorator} from '../Loading-Decorator/Centered-Loading-Decorator'
import {RequestCounts} from '../../../definitions/upgrade-landing-data-capture'
import Table from '@pluralsight/ps-design-system-table'
import Button from '@pluralsight/ps-design-system-button'
import Checkbox from '@pluralsight/ps-design-system-checkbox'

export function B2bUpgradeRequests() {
    const [loading, setLoading] = useState(false)
    const [canSendEmails, setCanSendEmails] = useState(false)
    const [canStartContentBackFill, setCanStartContentBackFill] = useState(false)
    const [upgradeData, setUpgradeData] = useState<RequestCounts[]>([])
    const [errorMessage, setErrorMessage] = useState('')

    const getData = async () => {
        setLoading(true)
        const response = await api.get(`internal-api/upgrade-landing-data`)
        if (response.status.success === true) {
            setUpgradeData(response.data)
        } else {
            setErrorMessage(response.status.errorMessage)
        }
        setLoading(false)
    }

    const sendEmails = async () => {
        await api.post(`internal-api/email-upgrade-landing-data`)
    }

    const startContentBackFill = async () => {
        await api.post(`internal-api/content-request-back-fill`)
    }

    return (
        <div>
            <div
                style={{
                    marginBottom: 50,
                }}
            >
                <h2>B2B Upgrade Requests</h2>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <Button disabled={loading} type='button' onClick={getData}>
                        Fetch Last 7 Days
                    </Button>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '30px',
                        }}
                    >
                        <Button
                            disabled={loading || !canSendEmails}
                            type='button'
                            onClick={sendEmails}
                        >
                            Send Emails for last 7 days
                        </Button>
                        <Checkbox
                            onCheck={() => setCanSendEmails(!canSendEmails)}
                            checked={canSendEmails}
                            label={'can send emails'}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '30px',
                        }}
                    >
                        <Button
                            disabled={loading || !canStartContentBackFill}
                            type='button'
                            onClick={startContentBackFill}
                        >
                            Start Content BackFill
                        </Button>
                        <Checkbox
                            onCheck={() => setCanStartContentBackFill(!canStartContentBackFill)}
                            checked={canStartContentBackFill}
                            label={'can start content backfill'}
                        />
                    </div>
                </div>
            </div>
            <div>{loading ? <CenteredLoadingDecorator /> : <HasError />}</div>
        </div>
    )

    function HasError() {
        const hasError = !!errorMessage
        return <div>{hasError ? <div>{errorMessage}</div> : <UpgradeTable />}</div>
    }

    function UpgradeTable() {
        return (
            <div>
                <Table>
                    <Table.Row>
                        <Table.Header role='columnheader' scope='col'>Plan Id</Table.Header>
                        <Table.Header role='columnheader' scope='col'>Count</Table.Header>
                        <Table.Header role='columnheader' scope='col'>Unique Count</Table.Header>
                    </Table.Row>
                    {upgradeData.length > 0 ? upgradeData.map(CountsRow) : <NoData />}
                </Table>
            </div>
        )
    }

    function CountsRow(counts: RequestCounts) {
        return (
            <Table.Row key={counts.planId}>
                <Table.Cell>{counts.planId}</Table.Cell>
                <Table.Cell>{counts.count}</Table.Cell>
                <Table.Cell>{counts.uniqueCount}</Table.Cell>
            </Table.Row>
        )
    }

    function NoData() {
        return (
            <Table.Row>
                <Table.Cell
                    style={{
                        marginTop: '25px',
                        fontSize: '20px',
                    }}
                >
                    No Data.
                </Table.Cell>
            </Table.Row>
        )
    }
}
