import React from 'react'
import Button from '@pluralsight/ps-design-system-button'
import styles from './thank-you-page.module.scss'
import {config} from '../environment-config'
import {useParams} from 'react-router-dom'
import {ParamTypes} from '../../../definitions/param-types'
import {useExpandContext} from './Expand-Context'
import {ExpandResponseV2} from '../../../definitions/expand'
import {useAppContext} from '../useAppState'
import {PaymentMethod} from '../../../definitions/payment-methods'

export default function ThankYouPage() {
    const {state} = useExpandContext()
    const {state: appState} = useAppContext()
    const paymentMethodUsed = state.existingPaymentMethods.filter((paymentMethod) => {
        return paymentMethod.id === state.paymentData.paymentMethodId
    })[0]
    const expand = state.expand
    const expandSuccess = state.expand.loaded
    const firstName = appState.applicationData.user.firstName
    const peopleHost = config.people.url
    const {planId} = useParams<ParamTypes>()
    const inviteNewUsersHref = `${peopleHost}/plans/${planId}/people/org`
    const viewInvoiceHref = `${config.billing.url}/billing/history`
    const subHeadingText =
        state.quantityToAdd === 1
            ? `${state.quantityToAdd} subscription has`
            : `${state.quantityToAdd} subscriptions have`
    const productName = state.currentSubscription.productName

    return (
        <>
            {expandSuccess ? (
                <div className={styles.layout}>
                    <div className={styles.heading}>
                        Thank You, <span>{firstName}!</span>
                    </div>
                    <div className={styles.subHeading}>
                        {subHeadingText} been added to your <b>{productName}</b> Plan
                    </div>
                    <div className={styles.buttonRow}>
                        <div className={styles.button}>
                            <Button appearance={Button.appearances.stroke} href={viewInvoiceHref}>
                                View invoice
                            </Button>
                        </div>
                        <div className={styles.button}>
                            <Button href={inviteNewUsersHref}>Invite New Users</Button>
                        </div>
                    </div>
                    <hr className={styles.ruling} />
                    <OrderSummary
                        expand={expand}
                        quantityToAdd={state.quantityToAdd}
                        tax={state.taxAmount}
                        productName={productName}
                    />
                    <PaymentMethodFormat paymentMethod={paymentMethodUsed} />
                </div>
            ) : (
                <div>Something went wrong</div>
            )}
        </>
    )
}

function PaymentMethodFormat(props: {paymentMethod: PaymentMethod}) {
    if (props.paymentMethod.type === 'PayPal') {
        return (
            <div className={styles.note}>
                Charged to PayPal account:{' '}
                <span style={{display: 'block'}}>{props.paymentMethod.details.paypalEmail}</span>
            </div>
        )
    } else {
        return (
            <div className={styles.note}>
                Charged To Visa ending in {props.paymentMethod.details.cardNumber.slice(-4)}
            </div>
        )
    }
}

function OrderSummary(props: {
    expand: ExpandResponseV2
    quantityToAdd: number
    productName: string
    tax: number
}) {
    return (
        <div className={styles.orderSum}>
            <div style={{marginBottom: '10px'}}> Order summary</div>
            <div className={styles.row}>
                <div>Plan</div>
                <div>Subscriptions</div>
                <div>Price</div>
            </div>
            <div className={styles.row}>
                <p>{props.productName}</p>
                <p className={styles.second}>{props.quantityToAdd}</p>
                <p>
                    {formatMoney(props.expand?.orderDetails.totalAmount)}{' '}
                    {props.expand.orderDetails.currencyISOCode}
                </p>
            </div>
            <hr className={styles.ruling1} />
            <div className={styles.midrow}>
                <div className={styles.first}>Subtotal</div>
                <div>
                    {formatMoney(
                        props.expand?.orderDetails.totalAmount -
                            props.expand?.orderDetails.taxAmount
                    )}{' '}
                    {props.expand.orderDetails.currencyISOCode}
                </div>
                <div className={styles.first}>
                    Tax<span className={styles.sym}>✝✝</span>
                </div>
                <div>
                    {formatMoney(props.tax)} {props.expand?.orderDetails.currencyISOCode}
                </div>
            </div>
            <hr className={styles.ruling2} />
            <div className={styles.total}>
                <div>Total</div>
                <div>
                    {formatMoney(props.expand?.orderDetails.totalAmount + props.tax)}{' '}
                    {props.expand.orderDetails.currencyISOCode}
                </div>
            </div>
        </div>
    )
}

function formatMoney(amount: number) {
    return amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
