import LinearProgress from '@pluralsight/ps-design-system-linearprogress'
import React, {useEffect} from 'react'
import styles from './Linear-Loading-Decorator.module.scss'
import './Linear-Loading-Helper.scss'
export function LinearLoadingDecorator(props: {centerVertically?: boolean}) {
    const useRandom = () => {
        const [value, setValue] = React.useState(0)
        useEffect(() => {
            const interval = setInterval(() => {
                const percentValue = value + 1
                setValue(percentValue > 95 ? 95 : percentValue)
            }, 2500)
            return () => clearInterval(interval)
        })
        return value
    }
    const value = useRandom()
    return !!props.centerVertically ? (
        <div className={styles.centerVertical}>
            <div className={styles.loaderBox}>
                <div className={styles.loaderText}>
                    Please hang tight,
                    <br /> this might take a few...
                </div>
                <div className={styles.loaderProgress}>
                    <LinearProgress aria-label={'Loading...'} value={value} />
                </div>
            </div>
        </div>
    ) : (
        <div>
            <div className={styles.loaderProgress}>
                <LinearProgress aria-label={'Loading...'} value={value} />
            </div>
        </div>
    )
}
