import CircularProgress from '@pluralsight/ps-design-system-circularprogress'
import React from 'react'

export default function SmallLoadingDecorator() {
    return (
        <CircularProgress
            size={CircularProgress.sizes.small}
            style={{marginLeft: 'auto'}}
            aria-label={'loading decorator'}
        />
    )
}
