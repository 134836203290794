import React from 'react'
import Banner from '@pluralsight/ps-design-system-banner'
import {config} from '../../environment-config'
import {ProductV2} from '../../../../definitions/product-state'
import styles from './display-future-state.module.scss'

export default function DisplayFutureState(prop: {
    product: ProductV2
    previousProduct: ProductV2
    planID: string
}) {
    const quantity = prop.product.productInfo.quantity
    const uom = prop.product.productCatalogInfo.productOption.uom
    const {sku, type, name} = prop.product.productCatalogInfo.product
    const difference = quantity - prop.previousProduct.productInfo.quantity
    const quantityChange = difference !== 0
    const previousProductSku = prop.previousProduct.productCatalogInfo.product.sku
    const previousProductName = prop.previousProduct.productCatalogInfo.product.name
    const isSkillsProduct = prop.product.productCatalogInfo.product.type === 'Skills'
    const skuChange = sku !== previousProductSku
    const startDate = dateFormatter(prop.product.productInfo.startsAt)
    const manageUsersUrl = `${config.people.url}/plans/${prop.planID}/people/org`
    const isDecrease = difference < 0
    const bannerColor = isDecrease ? Banner.colors.yellow : Banner.colors.blue
    const activity = isDecrease ? 'removed from' : 'added to'
    const absDifference = Math.abs(difference)
    const quantityChangeMessage = `${getQuantityString(
        absDifference,
        type,
        uom
    )} will be ${activity} your account.`
    const totalMessage = `You will have a total of ${getQuantityString(quantity, type, uom)}.`
    const planSwitchMessage = `Your plan  will switch from ${previousProductName} to ${name}.`

    const hasChanges = quantityChange || skuChange

    if (hasChanges) {
        return (
            <div>
                <Banner color={bannerColor}>
                    <div className={styles.banner}>
                        <p className={styles.date}>{startDate}</p>
                        {skuChange && <p>{planSwitchMessage}</p>}
                        {quantityChange && (
                            <p>
                                {quantityChangeMessage} {totalMessage}
                            </p>
                        )}
                        {isSkillsProduct && <a href={manageUsersUrl}>Manage users</a>}
                    </div>
                </Banner>
            </div>
        )
    } else {
        return <></>
    }
}

function getQuantityString(quantity: number, type: string, uom: string) {
    return `${quantity} ${type} ${uom.toLowerCase()}${quantity > 1 ? 's' : ''}`
}

const dateFormatter = (date: string) => {
    const d = new Date(date)
    const formatter = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        weekday: 'long',
    })
    const [
        {value: weekDay},
        ,
        {value: month},
        ,
        {value: day},
        ,
        {value: year},
    ] = formatter.formatToParts(d)

    return `${weekDay}, ${month} ${day}, ${year}`
}
