import {AccountSwitcher} from '@pluralsight/plans-account-switcher'
import React, {useEffect, useState} from 'react'
import {config} from '../environment-config'
import ReactDom from 'react-dom'
import {useAppContext} from '../useAppState'

export default function AccSwitcher(prop: {
    plans: string[]
    currentPlanId: any
    userHandle: string
    path: string
}) {
    const {state} = useAppContext()
    const plansApiHost = config.plansApiHost.url
    const plans = prop.plans
    const [isInited, setIsInited] = useState(window.Prism.isInited())
    function getRedirectUrl(path: string, planId: string) {
        return `${path}/${planId}`
    }
    useEffect(() => {
        if (state.PrismInited) {
            setIsInited(true)
            window.Prism.update({
                currentPlanId: prop.currentPlanId,
            })
        }
    }, [state.PrismInited, prop.currentPlanId])

    return (
        <>
            {isInited &&
                ReactDom.createPortal(
                    <AccountSwitcher
                        plans={plans}
                        userHandle={prop.userHandle}
                        currentPlanId={prop.currentPlanId}
                        plansApiHost={plansApiHost}
                        onPlanChange={(planId: string) => {
                            window.location.href = getRedirectUrl(prop.path, planId)
                        }}
                    />,
                    document.querySelector('#ps-account-switcher') as any
                )}
        </>
    )
}
